import React, { FC } from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import { useNavigate } from 'react-router-dom';
import { useTimer } from '../../../hooks/useTimer';
import { useLastSessionLink } from '../../../hooks/useLastSessionLink';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsLive,
  getIsNextSession,
  getIsStartSession,
} from '@bus/aiConversation/selectors';
import { profileActions } from '@bus/profile/actions';
import AIAIDark from '@assets/AIAIDark.png';
import AIAILight from '@assets/AIAILight.png';
import FeatureConversation from '@components/icons/FeatureConversation';
import Clock from '@components/icons/Clock';
import Live from '@components/icons/Live';

type TimeLeftToConversationProps = {
  targetDate: string;
  closeModal: () => void;
};

export const TimeLeftToConversation: FC<TimeLeftToConversationProps> = ({
  targetDate,
  closeModal,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isLive = useSelector(getIsLive);
  const isStartSession = useSelector(getIsStartSession);
  const isNextSession = useSelector(getIsNextSession);
  const { remainingTime } = useTimer(targetDate);
  const { link } = useLastSessionLink();
  const dispatch = useDispatch();

  const handleOnClick = () => {
    closeModal();
    const project = {
      id: 1,
      name: 'AI-2-AI',
      chats: [],
      description: '',
      icon: FeatureConversation,
      guidances: [],
      icon_dark: AIAIDark,
      icon_light: AIAILight,
      years: [],
    };
    dispatch(profileActions.setCurrentDataset(project));
    navigate(link);
  };

  const renderContent = () => {
    if (isNextSession && isStartSession && targetDate) {
      return (
        <Box sx={styles.beta}>
          <Clock
            sx={{ fontSize: '12px' }}
            htmlColor={theme.palette.common.surface['surface 46']}
          />
          <Typography variant={'caption'} color={'common.surface.surface 4'}>
            The next session
          </Typography>
          <Typography variant={'caption'} color={'text.primary'}>
            {`${remainingTime.days}d ${remainingTime.hours}h ${remainingTime.minutes}min ${remainingTime.seconds}s`}
          </Typography>
        </Box>
      );
    }

    if (isLive && !isStartSession) {
      return (
        <Box sx={styles.live}>
          <Live
            sx={{ fontSize: '12px' }}
            htmlColor={theme.palette.error.main}
          />
          <Typography variant={'caption'} color={'error.main'}>
            Live
          </Typography>
        </Box>
      );
    }

    return (
      <Typography
        variant={'caption'}
        color={'text.secondary'}
        sx={styles.description}>
        Strong metaphysical AI converse and visualize deep knowledge.
      </Typography>
    );
  };

  return (
    <Box
      sx={styles.timeLeftToConversation}
      onClick={handleOnClick}
      data-intercom-target={'AI-2-AI'}>
      <Box display={'flex'} alignItems={'center'} gap={'8px'} flexShrink={0}>
        <FeatureConversation
          sx={{ fontSize: '20px' }}
          htmlColor={theme.palette.common.surface['surface 46']}
        />
        <Typography variant={'captionBold'} color={'common.brand'}>
          AI-2-AI
        </Typography>
      </Box>
      {renderContent()}
    </Box>
  );
};

export default TimeLeftToConversation;
