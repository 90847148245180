import React, { ChangeEvent, useRef, useState, DragEvent } from 'react';

import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { styles } from '../styles';
import { getFieldError } from '@core/FinalForm/getFieldError';
import { getValidToken, removeQuotes } from '@REST/api';
import { handleErrors } from '@helpers/handleErrors';
import { useDispatch, useSelector } from 'react-redux';
import { getCreatedSwap } from '@bus/createdImages/selectors';
import { createdImagesActions } from '@bus/createdImages/actions';
import { updateSwapImageImage } from '@helpers/updateSwapImageImage';
import FromFace from '@components/icons/FromFace';
import { useField } from 'react-final-form';
import DeleteOutline from '@components/icons/DeleteOutline';

export const RenderFaceField = () => {
  const {
    input: { value, onChange },
    meta,
  } = useField('wrap_face');

  const createdSwap = useSelector(getCreatedSwap);
  const [image, setImage] = useState('');
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const theme = useTheme();

  const save = async (files: File[]) => {
    if (files.length > 0) {
      const tokenAccess = await getValidToken();
      const formData = new FormData();
      formData.append('image', files[0], files[0].name);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/image-studio/face-upload/`,
          {
            method: 'POST',
            body: formData,
            headers: {
              Authorization: 'Bearer ' + removeQuotes(tokenAccess!),
            },
          },
        );

        if (response.ok) {
          const data = await response.json();
          dispatch(createdImagesActions.updateImageStudioFaces(data.results));
        }
      } catch (error) {
        handleErrors(error);
      }
    }
  };

  const handleImageChange = (
    e: ChangeEvent<HTMLInputElement> | DragEvent<HTMLElement>,
  ) => {
    let selectedFiles: File[] = [];
    if ('files' in e.target && e.target.files) {
      selectedFiles = Array.from(e.target.files);
    } else if ('dataTransfer' in e && e.dataTransfer.files) {
      selectedFiles = Array.from(e.dataTransfer.files);
    }

    const isCorrectFormat = selectedFiles.some(
      (file) => file.type === 'image/jpeg' || file.type === 'image/png',
    );

    if (!isCorrectFormat) {
      return;
    }

    if (selectedFiles.length > 0 && createdSwap?.id) {
      save(selectedFiles).then(() => {
        updateSwapImageImage(
          dispatch,
          selectedFiles?.[0],
          createdSwap.id,
          'swap_image',
        ).then((data: any) => {
          onChange(data.swap_image);
          setImage(data.swap_image);
          dispatch(createdImagesActions.updateFirstFaces(data.swap_image));
        });
      });

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleRemove = (event: any) => {
    event.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    if (createdSwap?.id) {
      updateSwapImageImage(
        dispatch,
        'remove',
        createdSwap.id,
        'swap_image',
      ).then(() => {
        onChange('');
        setImage('');
      });
    }
  };

  const handleDragOver = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleImageChange(e);
  };

  return (
    <Box flex={1}>
      <Typography
        variant={'body1'}
        color={'text.disabled'}
        sx={styles.imageTitle}>
        Change Headshot
      </Typography>
      <input
        ref={fileInputRef}
        accept={'.jpeg, .jpg, .jpe, .jfif, .png'}
        id={'upload-wrap-photo'}
        type={'file'}
        style={{ display: 'none' }}
        onChange={handleImageChange}
        data-testid={'change-user-information-photo'}
      />
      <label htmlFor={'upload-wrap-photo'}>
        <Box
          sx={[styles.swapWrapper, getFieldError(meta) ? styles.error : {}]}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onDragLeave={handleDragLeave}>
          {value ? (
            <Box>
              <Box component={'img'} src={image || value} sx={styles.image} />
            </Box>
          ) : (
            <Box sx={styles.titleWrapper}>
              <FromFace
                sx={{ fontSize: '40px' }}
                htmlColor={theme.palette.common.surface['surface 46']}
              />
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}>
                <Typography variant={'body1'} color={'text.secondary'}>
                  Upload a Headshot
                </Typography>
              </Box>
            </Box>
          )}
          {value && (
            <Box sx={styles.deleteWrapper} className={'deleteWrapper'}>
              <IconButton
                sx={styles.deleteButton}
                color={'default'}
                onClick={handleRemove}>
                <DeleteOutline
                  sx={{ fontSize: '16px' }}
                  htmlColor={theme.palette.text.secondary}
                />
              </IconButton>
              <Box sx={[styles.titleWrapper, styles.overlayText]}>
                <FromFace
                  sx={{ fontSize: '40px' }}
                  htmlColor={theme.palette.common.surface['surface 46']}
                />
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  justifyContent={'center'}>
                  <Typography variant={'body1'} color={'text.secondary'}>
                    Upload a Headshot
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </label>
    </Box>
  );
};
