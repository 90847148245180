import DatasetSibyl from '@components/icons/DatasetSibyl';
import DatasetEnnealogy from '@components/icons/DatasetEnnealogy';
import DatasetEtymology from '@components/icons/DatasetEtymology';
import DatasetSuperhuman from '@components/icons/DatasetSuperhuman';
import DatasetSovereign from '@components/icons/DatasetSovereign';
import DatasetGpt from '@components/icons/DatasetGpt';
import AllDatasets from '@components/icons/AllDatasets';

export const datasetIcons: any = {
  0: AllDatasets,
  19: DatasetSibyl,
  1: DatasetEnnealogy,
  6: DatasetEtymology,
  13: DatasetSuperhuman,
  14: DatasetSovereign,
  9: DatasetGpt,
};
