import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Copy: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 1.25H11.694C9.856 1.25 8.4 1.25 7.261 1.403C6.089 1.561 5.14 1.893 4.391 2.641C3.643 3.39 3.311 4.339 3.153 5.511C3 6.651 3 8.106 3 9.944V16C2.99982 16.8935 3.31866 17.7577 3.8991 18.4369C4.47954 19.1162 5.28343 19.5659 6.166 19.705C6.303 20.469 6.568 21.121 7.098 21.652C7.7 22.254 8.458 22.512 9.358 22.634C10.225 22.75 11.328 22.75 12.695 22.75H15.805C17.172 22.75 18.275 22.75 19.142 22.634C20.042 22.512 20.8 22.254 21.402 21.652C22.004 21.05 22.262 20.292 22.384 19.392C22.5 18.525 22.5 17.422 22.5 16.055V10.945C22.5 9.578 22.5 8.475 22.384 7.608C22.262 6.708 22.004 5.95 21.402 5.348C20.871 4.818 20.219 4.553 19.455 4.416C19.3159 3.53343 18.8662 2.72954 18.1869 2.1491C17.5077 1.56866 16.6435 1.24982 15.75 1.25ZM17.88 4.271C17.7279 3.827 17.4409 3.44166 17.0589 3.16893C16.677 2.89619 16.2193 2.74971 15.75 2.75H11.75C9.843 2.75 8.489 2.752 7.46 2.89C6.455 3.025 5.875 3.279 5.452 3.702C5.029 4.125 4.775 4.705 4.64 5.71C4.502 6.739 4.5 8.093 4.5 10V16C4.49971 16.4693 4.64619 16.927 4.91892 17.3089C5.19166 17.6908 5.577 17.9779 6.021 18.13C6 17.52 6 16.83 6 16.055V10.945C6 9.578 6 8.475 6.117 7.608C6.237 6.708 6.497 5.95 7.098 5.348C7.7 4.746 8.458 4.488 9.358 4.367C10.225 4.25 11.328 4.25 12.695 4.25H15.805C16.58 4.25 17.27 4.25 17.88 4.271ZM8.158 6.41C8.435 6.133 8.823 5.953 9.558 5.854C10.312 5.753 11.314 5.751 12.749 5.751H15.749C17.184 5.751 18.185 5.753 18.941 5.854C19.675 5.953 20.063 6.134 20.34 6.41C20.617 6.687 20.797 7.075 20.896 7.81C20.997 8.564 20.999 9.566 20.999 11.001V16.001C20.999 17.436 20.997 18.437 20.896 19.193C20.797 19.927 20.616 20.315 20.34 20.592C20.063 20.869 19.675 21.049 18.94 21.148C18.185 21.249 17.184 21.251 15.749 21.251H12.749C11.314 21.251 10.312 21.249 9.557 21.148C8.823 21.049 8.435 20.868 8.158 20.592C7.881 20.315 7.701 19.927 7.602 19.192C7.501 18.437 7.499 17.436 7.499 16.001V11.001C7.499 9.566 7.501 8.564 7.602 7.809C7.701 7.075 7.882 6.687 8.158 6.41Z"
      />
    </SvgIcon>
  );
};

export default Copy;
