import { ModalTypes } from '@core/Modal/types';
import { ConfirmOrCancel } from './ConfirmOrCancel';
import { Confirm } from './Confirm';
import { ServerError } from './ServerError';
import { EditChat } from './EditChat';
import { VisualAdjustments } from './VisualAdjustments';
import { CropperWindow } from './CropperWindow';
import { Subscribe } from './Subscribe';
import { EditStoryChat } from './EditStoryChat';
import { OrderTokens } from './OrderTokens';
import { LimitExhausted } from './LimitExhausted';
import { TrialInformationWindow } from './TrialInformationWindow';
import { AmbassadorInformationWindow } from './AmbassadorInformationWindow';
import { ImageWrapStudio } from './ImageWrapStudio';
import { ImageGenerationStudio } from './ImageGenerationStudio';
import { StorytellerSettings } from './StorytellerSettings';
import { MasterMenu } from './MasterMenu';
import { SearchChat } from './SearchChat';
// IMPORTS
export const registeredModals: ModalTypes.RegisteredModals = {
  ConfirmOrCancel,
  Confirm,
  LimitExhausted,
  ServerError,
  EditChat,
  VisualAdjustments,
  CropperWindow,
  Subscribe,
  EditStoryChat,
  OrderTokens,
  TrialInformationWindow,
  AmbassadorInformationWindow,
  ImageWrapStudio,
  ImageGenerationStudio,
  StorytellerSettings,
  MasterMenu,
  SearchChat,
  // INJECT
};
