import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Box, useTheme } from '@mui/material';

import { styles } from './styles';
import AppBar from '@components/common/AppBar';
import { Modals } from '@core/Modal';
import { registeredModals } from '@components/modals/registeredModals';
import SuspenseLoading from '@components/common/SuspenseLoading';
import { useCurrentDataset } from '../../hooks/useCurrentDataset';
import Filter from '@components/icons/Filter';
import GalleryDrawer from '@components/drawers/GalleryDrawer';

export type ImageGalleryLayoutProps = {};

const ImageGalleryLayout: React.FC<ImageGalleryLayoutProps> = () => {
  const theme = useTheme();
  useCurrentDataset();

  return (
    <Box sx={styles.root}>
      <AppBar
        buttonProps={{
          icon: (
            <Filter
              sx={{ fontSize: '20px' }}
              htmlColor={theme.palette.common.surface['surface 47']}
            />
          ),
          onClick: () => {},
          label: 'Filter',
        }}
        drawerContent={GalleryDrawer}
      />
      <Modals registeredModals={registeredModals} />
      <Suspense fallback={<SuspenseLoading />}>
        <Box sx={styles.wrapper}>
          <Outlet />
        </Box>
      </Suspense>
    </Box>
  );
};

export default ImageGalleryLayout;
