import React, { FC } from 'react';

import { Box, Button, ModalProps, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import DrawerHeader from '@components/common/DrawerHeader';
import { useSelector } from 'react-redux';
import { book } from '@routes/book';
import { useNavigate, useParams } from 'react-router-dom';
import Filter from '@components/icons/Filter';
import Done from '@components/icons/Done';
import { getSavedFilters } from '@bus/savedMessages/selectors';
import { datasetIcons } from '@helpers/datasetIcons';

type SavedMessagesDrawerProps = {
  onClose?: ModalProps['onClose'];
};

export const SavedMessagesDrawer: FC<SavedMessagesDrawerProps> = ({
  onClose,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const savedFilters = useSelector(getSavedFilters);
  const { projectId } = useParams();

  const handleClick = (
    projectId: number,
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    navigate(`${book.savedMessages}/${projectId}`);
    onClose?.(event, 'backdropClick');
  };

  return (
    <Box sx={styles.galleryDrawer}>
      <DrawerHeader
        onClose={onClose}
        name={'Filter'}
        icon={
          <Filter
            sx={{ fontSize: '20px' }}
            htmlColor={theme.palette.common.surface['surface 47']}
          />
        }
      />
      <Box sx={styles.list}>
        {savedFilters.map((item) => (
          <Button
            key={item.id}
            variant={'drawerFilter'}
            disabled={projectId === item.id.toString()}
            onClick={(event) => handleClick(item.id, event)}>
            <Box display={'flex'} alignItems={'center'} gap={'16px'}>
              {React.createElement(datasetIcons[item.id] || (() => <></>), {
                fontSize: 'small',
                htmlColor: theme.palette.common.surface['surface 46'],
              })}
              <Typography variant={'body1'}>{item.name}</Typography>
            </Box>
            {projectId === item.id.toString() && (
              <Done
                sx={{ fontSize: '16px' }}
                htmlColor={theme.palette.text.primary}
              />
            )}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default SavedMessagesDrawer;
