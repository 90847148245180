import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

export const styles = {
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 20px 0',
  },
  button: {
    width: '24px',
    height: '24px',
    borderRadius: '4px',
  },
  innerWrapper: {
    position: 'absolute',
  },
  secondary: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100% !important',
    margin: '0 !important',
    border: 'none !important',
    borderRadius: '0 !important',
    '& .titleSection': (theme: Theme) => ({
      position: 'relative',
      padding: '0  !important',
      borderBottom: 'none !important',
      '& .MuiIconButton-root': {
        position: 'absolute',
        top: '30px',
        right: 'calc((100vw - 1180px) / 2)',
        [theme.breakpoints.down('xl')]: {
          top: '15px',
          right: '15px',
        },
      },
    }),
  },
  top: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100% !important',
    maxHeight: 'calc(100% - 64px + 80px)',
    margin: '0 !important',
    border: 'none !important',
    borderRadius: '0 !important',
    '& .MuiBackdrop-root': {
      display: 'none',
    },
    '& .titleSection': (theme: Theme) => ({
      position: 'relative',
      padding: '0  !important',
      borderBottom: 'none !important',
      '& .MuiIconButton-root': {
        position: 'absolute',
        top: '30px',
        right: 'calc((100vw - 1180px) / 2)',
        [theme.breakpoints.down('xl')]: {
          top: '15px',
          right: '15px',
        },
      },
    }),
  },
  fullMobile: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      m: '0!important',
      height: '100%',
      maxHeight: '100%!important',
    },
  }),
  topBackdrop: {
    display: 'none',
  },
};
export const rootStyles = {
  root: (customStyles: SxProps, background: string) => ({
    zIndex: 10005,
    '& .MuiDialog-paper': {
      overflow: 'unset',
      borderRadius: '16px',
    },
    '& .MuiPaper-root': {
      overflowX: 'hidden',
      margin: '11px',
      maxWidth: 'initial',
      backgroundImage: 'none',
      background,
    },
    '&': {
      ...customStyles,
    },
  }),
};
