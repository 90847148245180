export const styles = {
  root: {
    overflow: 'hidden',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 'calc(100svh)',
  },
};
