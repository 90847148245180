import React, { FC } from 'react';

import { Box, Button, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions } from '@bus/chat/actions';
import { getIsShowProfileTooltip } from '@bus/profile/selectors';
import { profileActions } from '@bus/profile/actions';
import Visibility1 from '@components/icons/Visibility1';

type GotItTooltipProps = {
  text?: string;
  isButton?: boolean;
  icon?: React.ReactNode;
  buttonText?: string;
  onClick?: () => void;
};

export const GotItTooltip: FC<GotItTooltipProps> = ({
  text = 'You can restore the ability to edit the prompt in your settings',
  isButton = true,
  icon,
  buttonText = 'Got it',
  onClick,
}) => {
  const dispatch = useDispatch();
  const isShowProfileTooltip = useSelector(getIsShowProfileTooltip);
  const theme = useTheme();
  const handleClick = () => {
    isShowProfileTooltip && dispatch(profileActions.closeProfileTooltip());
    dispatch(chatActions.setTooltipStatus(null));
    onClick?.();
  };

  return (
    <Box sx={styles.gotItTooltip}>
      <Box sx={styles.iconWrapper}>
        {icon ?? (
          <Visibility1
            fontSize={'small'}
            htmlColor={theme.palette.text.primary}
          />
        )}
      </Box>
      <Typography variant={'body2'} color={'text.primary'}>
        {text}
      </Typography>
      {isButton && (
        <Button
          onClick={handleClick}
          sx={styles.button}
          variant={'primary'}
          size={'40'}>
          <Typography variant={'body1'}>{buttonText}</Typography>
        </Button>
      )}
    </Box>
  );
};

export default GotItTooltip;
