import React, { FC, useEffect, useState } from 'react';

import { Box, Skeleton, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentDataset } from '@bus/profile/selectors';
import ArrowDropDown from '@components/icons/ArrowDropDown';
import { modalActions } from '@core/Modal/state/actions';
import { getModalData } from '@core/Modal/state/selectors';

type AppBarActionsProps = {
  isHaveButtons: boolean;
};

export const AppBarActions: FC<AppBarActionsProps> = ({ isHaveButtons }) => {
  const currentDataset = useSelector(getCurrentDataset);
  const [isOpened, setIsOpened] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const modalData = useSelector(getModalData);
  const handleDatasets = () => {
    if (isOpened) {
      dispatch(modalActions.closeModal('MasterMenu'));
    } else {
      dispatch(
        modalActions.modal({
          component: 'MasterMenu',
          variant: 'top',
          customStyles: styles.modal,
        }),
      );
    }
  };

  useEffect(() => {
    setIsOpened(!!modalData['MasterMenu']);
  }, [modalData]);

  return (
    <Box
      sx={(theme) => styles.button(theme, isHaveButtons)}
      onClick={handleDatasets}>
      <Box>
        {currentDataset ? (
          React.createElement(currentDataset.icon || (() => <></>), {
            sx: { fontSize: '20px' },
            htmlColor: theme.palette.common.surface['surface 46'],
          })
        ) : (
          <Skeleton variant={'rounded'} width={'20px'} height={'20px'} />
        )}
        <Typography ml={'16px'} color={'text.secondary'} variant={'body1'}>
          {currentDataset?.name ? currentDataset.name : 'Explore Worlds'}
        </Typography>
      </Box>
      <ArrowDropDown
        sx={[styles.icon, isOpened && styles.isOpen]}
        fontSize={'small'}
        htmlColor={theme.palette.text.secondary}
      />
    </Box>
  );
};

export default AppBarActions;
