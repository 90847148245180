// @ts-nocheck
import { handleError } from '@bus/ui/saga/workers/handleError';
import { call } from 'redux-saga/effects';
import { getNowDateTimeIso } from '@helpers/getDateTime';
import * as effects from 'redux-saga/effects';
import { chatActions } from '@bus/chat/actions';
import { projectsActions } from '@bus/projects/actions';
import { wsActions } from '@bus/ws/actions';
import { IResponseChatData } from '@bus/ws/typedefs';
import { IProjects } from '@bus/projects/typedefs';
import { getProject } from '@bus/projects/selectors';

export function* handleMessageChatError(payload: IResponseChatData) {
  try {
    yield effects.put(wsActions.setSocketsStatus('MESSAGE'));
    const projectMatched: IProjects = yield effects.select(
      getProject(payload.chat_project_id),
    );

    const date = yield call(getNowDateTimeIso);
    const message = {
      id: payload.message_id ? payload.message_id : 'mock',
      text: 'Unfortunately, something went wrong.<br />Please try to regenerate response.',
      author: 'ai',
      created_at: date,
      message_rate: null,
      chatId: payload.chat_id,
      projectId: payload.chat_project_id,
      project: {
        id: projectMatched.id,
        name: projectMatched.name,
        icon_dark: projectMatched?.icon_dark,
        icon_light: projectMatched?.icon_light,
      },
      type: 'error',
      continueStatus: false,
      nextToken: false,
      tokenIndex: 0,
      humanMessageId: 'mockHuman',
      images: [],
    };

    yield effects.put(chatActions.setMessageId(payload.message_id));
    yield effects.put(projectsActions.addMessage(message));
    yield effects.put(chatActions.debounceChat(false));
  } catch (e) {
    yield handleError(e);
  }
}
