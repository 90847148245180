import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Box, useTheme } from '@mui/material';

import { styles } from './styles';
import AppBar from '@components/common/AppBar';
import { registeredModals } from '@components/modals/registeredModals';
import { Modals } from '@core/Modal';
import SuspenseLoading from '@components/common/SuspenseLoading';
import { useSelector } from 'react-redux';
import { getIsAuthenticate } from '@bus/auth/selectors';
import History from '@components/icons/History';
import ChatHistoryDrawer from '@components/drawers/ChatHistoryDrawer';
import { useCurrentDataset } from '../../hooks/useCurrentDataset';
import CreateNewChat from '@components/common/CreateNewChat';

export type AppLayoutProps = {};

const AppLayout: React.FC<AppLayoutProps> = () => {
  const theme = useTheme();
  const isAuth = useSelector(getIsAuthenticate);
  useCurrentDataset();

  return (
    <Box sx={styles.root}>
      <AppBar
        authorized={isAuth}
        buttonProps={{
          icon: (
            <History
              sx={{ fontSize: '20px' }}
              htmlColor={theme.palette.common.surface['surface 47']}
            />
          ),
          onClick: () => {},
          label: 'History',
        }}
        drawerContent={ChatHistoryDrawer}
        rightAdditionalContent={CreateNewChat}
      />
      <Modals registeredModals={registeredModals} />
      <Suspense fallback={<SuspenseLoading />}>
        <Box sx={styles.wrapper}>
          <Outlet />
        </Box>
      </Suspense>
    </Box>
  );
};

export default AppLayout;
