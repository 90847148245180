import React, { FC } from 'react';

import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions } from '@bus/chat/actions';
import { getIsShowProfileTooltip } from '@bus/profile/selectors';
import { profileActions } from '@bus/profile/actions';
import Close from '@components/icons/Close';
import { useNavigate } from 'react-router-dom';
import { book } from '@routes/book';
import { shortTokensAmount } from '@helpers/shortTokensAmount';

type TotalCreateProps = {
  tokens?: number;
};

export const TotalCreate: FC<TotalCreateProps> = ({ tokens }) => {
  const dispatch = useDispatch();
  const isShowProfileTooltip = useSelector(getIsShowProfileTooltip);
  const theme = useTheme();
  const navigate = useNavigate();
  const handleClose = () => {
    isShowProfileTooltip && dispatch(profileActions.closeProfileTooltip());
    dispatch(chatActions.setTooltipStatus(null));
  };
  const handleClick = () => {
    handleClose();
    navigate(book.payments, { state: { scrollTo: 'token' } });
  };

  return (
    <Box sx={styles.gotItTooltip}>
      <IconButton sx={styles.close} onClick={handleClose}>
        <Close
          fontSize={'small'}
          htmlColor={theme.palette.common.surface['surface 47']}
        />
      </IconButton>
      <Typography variant={'body1'} color={'common.brand'}>
        {`You have ${shortTokensAmount(tokens ?? 0, true, false)} CREATE`}
      </Typography>
      <Button
        onClick={handleClick}
        sx={styles.button}
        variant={'primary'}
        fullWidth>
        <Typography variant={'body1'}>Buy more CREATE</Typography>
      </Button>
    </Box>
  );
};

export default TotalCreate;
