import * as effects from 'redux-saga/effects';

import { guidanceActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchGuidance,
} from './workers';

// IMPORTS
function* watchFetchGuidance() {
  yield effects.takeEvery(guidanceActions.fetchGuidance.type, fetchGuidance);
}
// WATCHERS
export function* watchGuidance() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchFetchGuidance),
    // INJECT
  ]);
}
