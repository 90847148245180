// @ts-nocheck
import { handleError } from '@bus/ui/saga/workers/handleError';
import { call } from 'redux-saga/effects';
import { getNowDateTimeIso } from '@helpers/getDateTime';
import * as effects from 'redux-saga/effects';
import { chatActions } from '@bus/chat/actions';
import { projectsActions } from '@bus/projects/actions';
import { getProject } from '@bus/projects/selectors';
import { IProjects } from '@bus/projects/typedefs';
import { wsActions } from '@bus/ws/actions';
import { IResponseChatData } from '@bus/ws/typedefs';

export function* handleMessageContinue(payload: IResponseChatData) {
  try {
    yield effects.put(wsActions.setSocketsStatus('MESSAGE'));

    projectsActions.clearSuggestingQuestions({
      chatId: payload.chat_id,
      id: payload.message_id,
    });

    const projectMatched: IProjects = yield effects.select(
      getProject(payload.message_project_id),
    );

    const date = yield call(getNowDateTimeIso);
    const message = {
      id: payload.message_id,
      text: payload.token,
      author: 'ai',
      created_at: date,
      message_rate: null,
      chatId: payload.chat_id,
      projectId: payload.chat_project_id,
      project: {
        id: payload.message_project_id,
        name: payload.message_project_name,
        icon_dark: projectMatched?.icon_dark,
        icon_light: projectMatched?.icon_light,
      },
      type: 'message',
      continueStatus: payload.continue_status,
      nextToken: payload.next_token,
      tokenIndex: payload.token_index,
      humanMessageId: payload.human_message_id,
      images: [],
      files: [],
    };

    yield effects.put(chatActions.setMessageId(payload.message_id));
    yield effects.put(projectsActions.updateMessage(message));

    yield effects.put(chatActions.debounceChat(payload.next_token));

    if (!payload.next_token) {
      yield effects.put(
        projectsActions.prepareSuggestingQuestions({ chatId: payload.chat_id }),
      );
      yield effects.put(
        projectsActions.fetchSuggestingQuestions({ chatId: payload.chat_id }),
      );
    }
  } catch (e) {
    yield handleError(e);
  }
}
