import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProjects } from '@bus/projects/selectors';
import { getCurrentDataset } from '@bus/profile/selectors';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { book } from '@routes/book';
import StoryTellerDark from '@assets/StoryTellerDark.png';
import StoryTellerLight from '@assets/StoryTellerLight.png';
import { profileActions } from '@bus/profile/actions';
import MetametricsDark from '@assets/MetametricsDark.png';
import MetametricsLight from '@assets/MetametricsLight.png';
import AIAIDark from '@assets/AIAIDark.png';
import AIAILight from '@assets/AIAILight.png';
import FeatureConversation from '@components/icons/FeatureConversation';
import FeatureMetametrics from '@components/icons/FeatureMetametrics';
import FeatureStoryteller from '@components/icons/FeatureStoryteller';

export const useCurrentDataset = () => {
  const { projectName } = useParams();
  const projects = useSelector(getProjects);
  const dispatch = useDispatch();
  const currentDataset = useSelector(getCurrentDataset);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes(book.storyTellerHome)) {
      const project = {
        id: 1,
        name: 'Storyteller',
        chats: [],
        description: '',
        icon: FeatureStoryteller,
        guidances: [],
        icon_dark: StoryTellerDark,
        icon_light: StoryTellerLight,
        years: [],
      };
      dispatch(profileActions.setCurrentDataset(project));

      return;
    }
    if (location.pathname.includes(book.metametrics)) {
      const project = {
        id: 1,
        name: 'Metametrics',
        chats: [],
        description: '',
        icon: FeatureMetametrics,
        guidances: [],
        icon_dark: MetametricsDark,
        icon_light: MetametricsLight,
        years: [],
      };
      dispatch(profileActions.setCurrentDataset(project));

      return;
    }
    if (location.pathname.includes(book.conversation)) {
      const project = {
        id: 1,
        name: 'AI-2-AI',
        chats: [],
        description: '',
        icon: FeatureConversation,
        guidances: [],
        icon_dark: AIAIDark,
        icon_light: AIAILight,
        years: [],
      };
      dispatch(profileActions.setCurrentDataset(project));

      return;
    }
    if (projectName) {
      const index = projects.findIndex((i) => i.name === projectName);
      dispatch(
        profileActions.setCurrentDataset(projects[index === -1 ? 0 : index]),
      );
    }

    if (
      projects.length &&
      !projectName &&
      (currentDataset === null ||
        currentDataset.name === 'Storyteller' ||
        currentDataset.name === 'Metametrics' ||
        currentDataset.name === 'AI-2-AI')
    ) {
      const index = projects.findIndex((i) => i.name === projectName);
      dispatch(
        profileActions.setCurrentDataset(projects[index === -1 ? 0 : index]),
      );
    }
  }, [projects.length, projectName, location.pathname]);

  return { currentDataset };
};
