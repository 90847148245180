import { Theme } from '@mui/material/styles';

export const styles = {
  root: (theme: Theme) => ({
    width: '100px',
    height: '100px',
    borderRadius: '24px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      '&:hover': {
        '& .removeIcon': {
          opacity: 1,
          visibility: 'visible',
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      width: '80px',
      height: '80px',
    },
  }),
  image: {
    borderRadius: '16px',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  empty: (theme: Theme) => ({
    borderRadius: '16px',
    width: '100%',
    height: '100%',
    background: theme.palette.common.surface['surface 6'],
  }),
  wrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  overlay: {
    borderRadius: '16px',
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  icon: {
    position: 'absolute',
    bottom: '4px',
    left: '4px',
    zIndex: 1,
  },
  deleteIcon: (theme: Theme) => ({
    position: 'absolute',
    zIndex: 1,
    top: '4px',
    right: '4px',
    transition: 'all 0.3s ease-in-out',
    visibility: 'hidden',
    opacity: 0,
    [theme.breakpoints.down('lg')]: {
      visibility: 'visible',
      opacity: 1,
    },
  }),
};
