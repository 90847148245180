import { Theme } from '@mui/material';

export const styles = {
  root: {
    padding: '20px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'auto',
  },
  list: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
      height: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
    },
  },
  button: (theme: Theme) => ({
    height: 'unset',
    minWidth: 'unset',
    padding: 0,
    '& span, p': {
      transition: 'all 0.3s ease-in-out',
    },
    '&:hover': {
      '& span, p': {
        color: theme.palette.text.secondary,
      },
    },
  }),
  button2: (theme: Theme) => ({
    height: 'unset',
    minWidth: 'unset',
    padding: '7.5px 2.5px',
    '& span, p': {
      transition: 'all 0.3s ease-in-out',
    },
    '&:hover': {
      '& span, p': {
        color: theme.palette.text.secondary,
      },
    },
  }),
  active: (theme: Theme) => ({
    '& span, p': {
      color: theme.palette.text.primary,
    },
  }),
  month: (theme: Theme) => ({
    px: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '40px',
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&': {
      [theme.breakpoints.down('md')]: {
        overflowX: 'visible',
        flexDirection: 'column',
        gap: '16px',
        pb: '20px',
      },
    },
  }),
};
