import React, { ChangeEvent, DragEvent, useRef } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { styles } from '@components/forms/GenerateImageWrapStudio/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getCreatedSwap } from '@bus/createdImages/selectors';
import { updateSwapImageImage } from '@helpers/updateSwapImageImage';
import { getFieldError } from '@core/FinalForm/getFieldError';
import ImageFaceSwap from '@components/icons/ImageFaceSwap';
import DeleteOutline from '@components/icons/DeleteOutline';

export const RenderFromPhotoField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  const dispatch = useDispatch();
  const createdSwap = useSelector(getCreatedSwap);
  const theme = useTheme();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (
    e: ChangeEvent<HTMLInputElement> | DragEvent<HTMLElement>,
  ) => {
    let selectedFiles: File[] = [];
    if ('files' in e.target && e.target.files) {
      selectedFiles = Array.from(e.target.files);
    } else if ('dataTransfer' in e && e.dataTransfer.files) {
      selectedFiles = Array.from(e.dataTransfer.files);
    }

    const isCorrectFormat = selectedFiles.some(
      (file) => file.type === 'image/jpeg' || file.type === 'image/png',
    );

    if (!isCorrectFormat) {
      return;
    }

    if (selectedFiles.length > 0 && createdSwap?.id) {
      updateSwapImageImage(
        dispatch,
        selectedFiles?.[0],
        createdSwap.id,
        'target_image',
      ).then((data: any) => {
        input.onChange(data.target_image);
      });

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleRemove = (event: any) => {
    event.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    if (createdSwap?.id) {
      updateSwapImageImage(
        dispatch,
        'remove',
        createdSwap.id,
        'target_image',
      ).then(() => {
        input.onChange('');
      });
    }
  };

  const handleDragOver = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleImageChange(e);
  };

  return (
    <Box flex={1}>
      <Typography
        variant={'body1'}
        color={'text.disabled'}
        sx={styles.imageTitle}>
        Main image
      </Typography>
      <input
        ref={fileInputRef}
        accept={'.jpeg, .jpg, .jpe, .jfif, .png'}
        id={'upload-from-photo'}
        type={'file'}
        style={{ display: 'none' }}
        onChange={handleImageChange}
        data-testid={'change-user-information-photo'}
      />
      <label htmlFor={'upload-from-photo'}>
        <Box
          sx={[styles.wrapper, getFieldError(meta) ? styles.error : {}]}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onDragLeave={handleDragLeave}>
          {input.value ? (
            <Box>
              <Box component={'img'} src={input.value} sx={styles.image} />
            </Box>
          ) : (
            <Box sx={styles.titleWrapper}>
              <ImageFaceSwap
                sx={{ fontSize: '40px' }}
                htmlColor={theme.palette.common.surface['surface 46']}
              />
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}>
                <Typography variant={'body1'} color={'text.secondary'}>
                  Upload an Image
                </Typography>
              </Box>
            </Box>
          )}
          {input.value && (
            <Box sx={styles.deleteWrapper} className={'deleteWrapper'}>
              <IconButton
                sx={styles.deleteButton}
                color={'default'}
                onClick={handleRemove}>
                <DeleteOutline
                  sx={{ fontSize: '16px' }}
                  htmlColor={theme.palette.text.secondary}
                />
              </IconButton>
              <Box sx={[styles.titleWrapper, styles.overlayText]}>
                <ImageFaceSwap
                  sx={{ fontSize: '40px' }}
                  htmlColor={theme.palette.common.surface['surface 46']}
                />
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  justifyContent={'center'}>
                  <Typography variant={'body1'} color={'text.secondary'}>
                    Upload an Image
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </label>
    </Box>
  );
};
