import { useDispatch, useSelector } from 'react-redux';
import { getText } from '@bus/profile/selectors';
import { modalActions } from '@core/Modal/state/actions';
import { LimitExhaustedContent } from '@components/modals/LimitExhausted';

type TextType =
  | 'text_line_1'
  | 'text_line_2'
  | 'text_line_3'
  | 'text_line_4'
  | 'text_line_5';

export const useLimits = (
  showUpdatePlanToUseTokensModal: boolean,
  showBuyTokensModal?: boolean | null,
  textLine?: TextType | null,
) => {
  const dispatch = useDispatch();
  const text: TextType = textLine ?? 'text_line_1';
  const defaultText = useSelector(getText(text));

  const handleLimitExceeded = () => {
    if (showBuyTokensModal) {
      dispatch(
        modalActions.modal({
          component: 'LimitExhausted',
          forceClose: true,
          ...LimitExhaustedContent.buyTokens[text],
          limitText: defaultText,
          variant: 'secondary',
        }),
      );

      return;
    }

    if (showUpdatePlanToUseTokensModal) {
      dispatch(
        modalActions.modal({
          component: 'LimitExhausted',
          forceClose: true,
          ...LimitExhaustedContent.updateSubscription,
          limitText: defaultText,
          variant: 'secondary',
        }),
      );
    }
  };

  return { handleLimitExceeded };
};
