import { createSelector } from 'reselect';

import { RootState } from '@setup/typedefs';

const savedMessagesSelector = (state: RootState) => state.savedMessages;

export const getIsSavedMessagesFetching = createSelector(
  [savedMessagesSelector],
  (result) => {
    return result.isFetching;
  },
);

export const getSavedMessagesProjects = createSelector(
  [savedMessagesSelector],
  (result) => {
    return result.savedProjects;
  },
);

export const getScrollToMessageId = createSelector(
  [savedMessagesSelector],
  (result) => {
    return result.scrollToMessageId;
  },
);

export const getSavedMessages = (id?: string) =>
  createSelector([savedMessagesSelector], (result) => {
    if (!id) {
      return null;
    }

    return result.savedProjects.find((project) => project.id === +id);
  });

export const getNextSavedMessagesUrl = (id?: string) =>
  createSelector([savedMessagesSelector], ({ savedProjects }) => {
    if (id && savedProjects.length) {
      return (
        savedProjects.find((project) => project.id === +id)?.messages?.next ??
        ''
      );
    }

    return null;
  });

export const getSavedFilters = createSelector(
  [savedMessagesSelector],
  ({ savedFilters }) => {
    return savedFilters;
  },
);
