export const downloadImage = (url: string) => {
  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      return response.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'image'; // Укажите имя файла
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url); // Освобождаем память
      document.body.removeChild(a);
    })
    .catch((error) => console.error('Ошибка при загрузке изображения:', error));
};
