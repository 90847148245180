import { createAction } from '@reduxjs/toolkit';

import chatSlice from './slice';
import prepareActions from '@helpers/prepareActions';

export const chatActions = {
  ...chatSlice.actions,
  fetchMessages: createAction('chat/fetchMessages', (chatId: number) => ({
    payload: { chatId },
  })),
  loadMoreMessages: createAction(
    'chat/loadMoreMessages',
    ({ url, chatId, isNext, isPrev, isCustom }) => ({
      payload: { url, chatId, isNext, isPrev, isCustom },
    }),
  ),
  debounceChat: createAction('chat/debounceChat', (payload) => ({
    payload,
  })),
  loadPrevMessages: createAction(
    'chat/loadPrevMessages',
    prepareActions.movePromiseToMeta,
  ),
  searchChat: createAction('chat/searchChat', prepareActions.movePromiseToMeta),
  fetchRecentChats: createAction('chat/fetchRecentChats'),
  // INJECT
};
