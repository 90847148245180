import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const ImageFaceSwap: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox={'0 0 42 42'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6139 11.9263C14.3358 12.0981 13.5647 12.4248 12.9942 12.9944C12.4249 13.5643 12.0981 14.3352 11.9263 15.6135C11.7516 16.9136 11.75 18.6218 11.75 21C11.75 23.3782 11.7516 25.0867 11.9263 26.3867C12.0982 27.6653 12.425 28.4357 12.9941 29.0045C13.5641 29.5745 14.3352 29.9016 15.6136 30.0736C16.9136 30.2484 18.6218 30.25 21 30.25C23.3782 30.25 25.0866 30.2484 26.3867 30.0736C27.6651 29.9016 28.4355 29.5746 29.0043 29.005C29.5741 28.4356 29.9016 27.6646 30.0736 26.3863C30.2484 25.0864 30.25 23.3782 30.25 21C30.25 18.6218 30.2484 16.9139 30.0736 15.614C29.9016 14.3358 29.5747 13.5647 29.0045 12.9941C28.4351 12.4249 27.6646 12.0981 26.3863 11.9263C25.0864 11.7516 23.3782 11.75 21 11.75C18.6218 11.75 16.9138 11.7516 15.6139 11.9263ZM20.9426 10.25H21.0574C23.3657 10.25 25.1748 10.25 26.5862 10.4397C28.0308 10.6339 29.1707 11.0391 30.0653 11.9337C30.9602 12.8291 31.3659 13.9692 31.5602 15.414C31.75 16.8255 31.75 18.6342 31.75 20.9426V21.0574C31.75 23.3658 31.75 25.1748 31.5602 26.5862C31.3659 28.0306 30.9605 29.1705 30.0657 30.065C29.1715 30.9604 28.0314 31.3659 26.5866 31.5602C25.175 31.75 23.3658 31.75 21.0574 31.75H20.9426C18.6342 31.75 16.8252 31.75 15.4137 31.5602C13.9688 31.3659 12.8287 30.9604 11.9337 30.0653C11.039 29.1712 10.6338 28.0311 10.4397 26.5865C10.25 25.175 10.25 23.3657 10.25 21.0574V20.9426C10.25 18.6343 10.25 16.8252 10.4397 15.4137C10.6339 13.969 11.039 12.8289 11.9335 11.9339C12.829 11.0396 13.9694 10.6339 15.4141 10.4397C16.8255 10.25 18.6343 10.25 20.9426 10.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 16.125C24.5168 16.125 24.125 16.5168 24.125 17C24.125 17.4832 24.5168 17.875 25 17.875C25.4832 17.875 25.875 17.4832 25.875 17C25.875 16.5168 25.4832 16.125 25 16.125ZM21.875 17C21.875 15.2741 23.2741 13.875 25 13.875C26.7259 13.875 28.125 15.2741 28.125 17C28.125 18.7259 26.7259 20.125 25 20.125C23.2741 20.125 21.875 18.7259 21.875 17Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8224 21.3406L13.215 22.4511C12.7859 22.7475 12.1337 22.7132 11.7582 22.3744C11.3828 22.0356 11.4263 21.5207 11.8554 21.2243L13.4631 20.1135C14.0632 19.6993 14.8405 19.4804 15.6371 19.5014C16.4337 19.5224 17.1905 19.7817 17.7542 20.2266L21.6909 23.3349C21.6909 23.3349 21.6909 23.3349 21.6909 23.3349C21.8246 23.4403 22.0011 23.5053 22.1894 23.5182C22.3776 23.531 22.5656 23.491 22.7203 23.4052L22.9949 23.253C23.6959 22.8642 24.5432 22.6747 25.3983 22.7154C26.2534 22.7562 27.0657 23.0247 27.7025 23.4771L30.661 25.579C31.0848 25.8801 31.1192 26.3955 30.7379 26.7301C30.3565 27.0647 29.7037 27.0919 29.2799 26.7908L26.3214 24.6889C26.032 24.4833 25.6627 24.3612 25.274 24.3427C24.8853 24.3242 24.5002 24.4103 24.1816 24.587L23.9074 24.739C23.9073 24.7391 23.9075 24.739 23.9074 24.739C23.3552 25.0454 22.6836 25.1882 22.0113 25.1423C21.3388 25.0963 20.7084 24.8645 20.2311 24.4877L16.2944 21.3795C16.2944 21.3795 16.2944 21.3795 16.2944 21.3795C16.101 21.2269 15.8414 21.1379 15.5681 21.1307C15.2949 21.1235 15.0282 21.1986 14.8224 21.3406Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7.95652C0 3.56226 3.56226 0 7.95652 0V2C4.66683 2 2 4.66683 2 7.95652H0ZM40 7.95652C40 4.66683 37.3332 2 34.0435 2V0C38.4377 0 42 3.56226 42 7.95652H40ZM2 34.0435C2 37.3332 4.66683 40 7.95652 40V42C3.56226 42 0 38.4377 0 34.0435H2ZM34.0435 40C37.3332 40 40 37.3332 40 34.0435H42C42 38.4377 38.4377 42 34.0435 42V40Z"
      />
    </SvgIcon>
  );
};

export default ImageFaceSwap;
