import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const VolumeUp: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9697 6.96967C19.2626 6.67678 19.7374 6.67678 20.0303 6.96967L19.5 7.5C20.0303 6.96967 20.0306 6.96989 20.0308 6.97012L20.0313 6.9706L20.0323 6.97168L20.0349 6.97426L20.0416 6.98113L20.0613 7.00165C20.0771 7.01833 20.0982 7.04101 20.1237 7.06959C20.1747 7.1267 20.2439 7.20756 20.325 7.31121C20.487 7.51816 20.6983 7.8181 20.9084 8.20336C21.3286 8.97364 21.75 10.0966 21.75 11.5C21.75 12.9034 21.3286 14.0264 20.9084 14.7966C20.6983 15.1819 20.487 15.4818 20.325 15.6888C20.2439 15.7924 20.1747 15.8733 20.1237 15.9304C20.0982 15.959 20.0771 15.9817 20.0613 15.9984L20.0416 16.0189L20.0349 16.0257L20.0323 16.0283L20.0313 16.0294L20.0308 16.0299C20.0306 16.0301 20.0303 16.0303 19.5207 15.5207L20.0303 16.0303C19.7374 16.3232 19.2626 16.3232 18.9697 16.0303C18.6776 15.7383 18.6768 15.2654 18.9671 14.9723C18.9679 14.9714 18.9688 14.9705 18.9697 14.9697L19.5 15.5C18.9697 14.9697 18.9695 14.9699 18.9693 14.9701L18.9689 14.9705L18.9682 14.9711L18.9673 14.9721L18.9724 14.9667C18.9786 14.9602 18.9897 14.9482 19.0052 14.9309C19.0362 14.8962 19.0842 14.8404 19.1437 14.7643C19.263 14.6119 19.4267 14.3806 19.5916 14.0784C19.9214 13.4736 20.25 12.5966 20.25 11.5C20.25 10.4034 19.9214 9.52636 19.5916 8.92164C19.4267 8.6194 19.263 8.38809 19.1437 8.23567C19.0842 8.15963 19.0362 8.10377 19.0052 8.06908C18.9897 8.05176 18.9786 8.03978 18.9724 8.03326L18.9671 8.02774C18.6768 7.73464 18.6776 7.2617 18.9697 6.96967Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3123 4.54834C14.2712 4.52668 14.2249 4.51631 14.1783 4.51832C14.1316 4.52034 14.0864 4.53466 14.0474 4.55978L10.9443 6.56139L10.0902 5.294L13.1936 3.29217C13.4665 3.11633 13.7834 3.01582 14.1098 3.00172C14.4363 2.98762 14.7606 3.06024 15.0482 3.21186C15.3359 3.36348 15.5763 3.58845 15.7439 3.86292C15.9114 4.13738 15.9999 4.4511 16 4.7708V19.2291C15.9999 19.5488 15.9114 19.8626 15.7439 20.1371C15.5763 20.4115 15.3359 20.6365 15.0482 20.7881C14.7606 20.9398 14.4363 21.0124 14.1098 20.9983C13.7834 20.9842 13.4669 20.8839 13.194 20.7081L6.77179 16.6625L7.84087 15.4368L14.0474 19.4402C14.0863 19.4652 14.1317 19.4797 14.1783 19.4817C14.2249 19.4837 14.2712 19.4733 14.3123 19.4517C14.3534 19.43 14.3878 19.3979 14.4117 19.3586C14.4356 19.3195 14.4482 19.2747 14.4483 19.2291V4.77105C14.4482 4.72542 14.4356 4.68052 14.4117 4.64135C14.3878 4.60214 14.3534 4.57 14.3123 4.54834ZM7.84071 8.56333C7.54515 8.75387 7.19898 8.85547 6.84502 8.85556H3.84483C3.50188 8.85556 3.17297 8.98884 2.93047 9.22609C2.68796 9.46333 2.55172 9.78511 2.55172 10.1206V13.8794C2.55172 14.2149 2.68796 14.5367 2.93047 14.7739C3.17297 15.0112 3.50188 15.1444 3.84483 15.1444H5.34483V16.6625H3.84483C3.09033 16.6625 2.36674 16.3693 1.83323 15.8474C1.29972 15.3254 1 14.6175 1 13.8794V10.1206C1 9.38249 1.29972 8.67458 1.83323 8.15265C2.36674 7.63071 3.09033 7.33748 3.84483 7.33748L6.98688 7.29574M6.98688 7.29574L7.84071 8.56333L6.98688 7.29574ZM6.98688 7.29574C6.94471 7.32292 7.03738 7.29569 6.98688 7.29574V7.29574Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3123 19.4517C14.2712 19.4733 14.2249 19.4837 14.1783 19.4817C14.1316 19.4797 14.0864 19.4653 14.0474 19.4402L10.9443 17.4386L10.0902 18.706L13.1936 20.7078C13.4665 20.8837 13.7834 20.9842 14.1098 20.9983C14.4363 21.0124 14.7606 20.9398 15.0482 20.7881C15.3359 20.6365 15.5763 20.4115 15.7439 20.1371C15.9114 19.8626 15.9999 19.5489 16 19.2292V4.77092C15.9999 4.45123 15.9114 4.13738 15.7439 3.86292C15.5763 3.58846 15.3359 3.36348 15.0482 3.21186C14.7606 3.06024 14.4363 2.98763 14.1098 3.00172C13.7834 3.01582 13.4669 3.11611 13.194 3.29194L6.98672 7.29584L7.84087 8.56323L14.0474 4.55978C14.0863 4.53476 14.1317 4.52033 14.1783 4.51832C14.2249 4.51631 14.2712 4.52668 14.3123 4.54834C14.3534 4.57 14.3878 4.60214 14.4117 4.64135C14.4356 4.68053 14.4482 4.7253 14.4483 4.77092V19.229C14.4482 19.2746 14.4356 19.3195 14.4117 19.3586C14.3878 19.3979 14.3534 19.43 14.3123 19.4517ZM7.84071 15.4367C7.54515 15.2461 7.19898 15.1445 6.84502 15.1444H3.84483C3.50188 15.1444 3.17297 15.0112 2.93047 14.7739C2.68796 14.5367 2.55172 14.2149 2.55172 13.8794V10.1206C2.55172 9.78511 2.68796 9.46333 2.93047 9.22609C3.17297 8.98884 3.50188 8.85556 3.84483 8.85556H5.34483V7.33748H3.84483C3.09033 7.33748 2.36674 7.63071 1.83323 8.15265C1.29972 8.67459 1 9.38249 1 10.1206V13.8794C1 14.6175 1.29972 15.3254 1.83323 15.8474C2.36674 16.3693 3.09033 16.6625 3.84483 16.6625H6.84463M7.84071 15.4367L6.98688 16.7043L7.84071 15.4367ZM6.98688 16.7043C6.94471 16.6771 6.89513 16.6626 6.84463 16.6625L6.98688 16.7043Z"
      />
    </SvgIcon>
  );
};

export default VolumeUp;
