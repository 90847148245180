import { Theme } from '@mui/material/styles';

export const styles = {
  chatHistoryDrawer: {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  list: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
      height: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
    },
  },
  button: {
    justifyContent: 'flex-start',
    height: '56px',
  },
  item: (theme: Theme) => ({
    '& .MuiButtonBase-root': {
      padding: '12px 20px',
      height: '48px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '16px',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        background: theme.palette.common.surface['surface 2'],
      },
    },
  }),
  activeItem: (theme: Theme) => ({
    background: theme.palette.common.surface['surface 2'],
  }),
  // button: {
  //   border: 'none!important',
  // },
};
