import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const FeatureImageGeneration: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox={'0 0 25 24'}>
      <path d="M16.7298 6L17.405 7.82477L19.2298 8.5L17.405 9.17523L16.7298 11L16.0546 9.17523L14.2298 8.5L16.0546 7.82477L16.7298 6Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3983 4L6.39827 4C5.2937 4 4.39827 4.89543 4.39827 6L4.39827 18C4.39827 19.1046 5.2937 20 6.39827 20H18.3983C19.5028 20 20.3983 19.1046 20.3983 18V6C20.3983 4.89543 19.5028 4 18.3983 4ZM6.39827 3C4.74142 3 3.39827 4.34315 3.39827 6L3.39827 18C3.39827 19.6569 4.74142 21 6.39827 21H18.3983C20.0551 21 21.3983 19.6569 21.3983 18V6C21.3983 4.34315 20.0551 3 18.3983 3L6.39827 3Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3983 23C18.4734 23 23.3983 18.0751 23.3983 12C23.3983 5.92487 18.4734 1 12.3983 1C6.32314 1 1.39827 5.92487 1.39827 12C1.39827 18.0751 6.32314 23 12.3983 23ZM12.3983 24C19.0257 24 24.3983 18.6274 24.3983 12C24.3983 5.37258 19.0257 0 12.3983 0C5.77085 0 0.39827 5.37258 0.39827 12C0.39827 18.6274 5.77085 24 12.3983 24Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9617 11.4386C9.19149 9.73853 5.9794 9.08041 3.87906 8.99963L3.91749 8.00037C6.15048 8.08625 9.5384 8.77813 12.4848 10.5864C15.4479 12.4048 17.9811 15.4111 18.3983 20L17.4003 20.0453C17.0175 15.8342 14.7153 13.1285 11.9617 11.4386Z"
      />
    </SvgIcon>
  );
};

export default FeatureImageGeneration;
