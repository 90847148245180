import { Theme } from '@mui/material/styles';

export const styles = {
  root: (theme: Theme) => ({
    height: '100svh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '60px',
    padding: '20px',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('md')]: {
      gap: '32px',
    },
  }),
  title: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '32px',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('md')]: {
      gap: '12px',
    },
  }),
};
