import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

type StyleRadioProps = {
  isSelected: boolean;
  value: string;
  label: string;
  onChange: any;
  disabled: boolean;
  variant: 'primary' | 'outlined';
};

export const StyleRadio: FC<StyleRadioProps> = ({
  isSelected,
  onChange,
  value,
  label,
  disabled,
  variant,
}) => {
  const handleRadioChange = () => {
    !disabled && onChange(value);
  };

  return (
    <Box
      aria-checked={isSelected}
      tabIndex={isSelected ? 0 : -1}
      sx={[
        styles[variant as keyof typeof styles],
        isSelected && styles.ratioSelected,
      ]}
      onClick={handleRadioChange}>
      <input
        type={'radio'}
        value={value}
        checked={isSelected}
        onChange={handleRadioChange}
        style={{ display: 'none' }}
        aria-label={label}
      />
      <Typography
        variant={'body1'}
        color={isSelected ? 'common.brand' : 'text.primary'}>
        {label.split(':').join(' : ')}
      </Typography>
    </Box>
  );
};

export default StyleRadio;
