import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const SeenCircleOutline: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox={'0 0 16 16'}>
      <g clipPath="url(#clip0_2286_2430)">
        <path d="M7.99992 1.33325C4.31992 1.33325 1.33325 4.31992 1.33325 7.99992C1.33325 11.6799 4.31992 14.6666 7.99992 14.6666C11.6799 14.6666 14.6666 11.6799 14.6666 7.99992C14.6666 4.31992 11.6799 1.33325 7.99992 1.33325ZM7.99992 13.3333C5.05992 13.3333 2.66659 10.9399 2.66659 7.99992C2.66659 5.05992 5.05992 2.66659 7.99992 2.66659C10.9399 2.66659 13.3333 5.05992 13.3333 7.99992C13.3333 10.9399 10.9399 13.3333 7.99992 13.3333Z" />
        <path d="M8 6.8C7.63834 6.8 7.29149 6.92643 7.03576 7.15147C6.78003 7.37652 6.63636 7.68174 6.63636 8C6.63636 8.31826 6.78003 8.62348 7.03576 8.84853C7.29149 9.07357 7.63834 9.2 8 9.2C8.36166 9.2 8.70851 9.07357 8.96424 8.84853C9.21997 8.62348 9.36364 8.31826 9.36364 8C9.36364 7.68174 9.21997 7.37652 8.96424 7.15147C8.70851 6.92643 8.36166 6.8 8 6.8ZM8 10C7.39724 10 6.81916 9.78929 6.39294 9.41421C5.96672 9.03914 5.72727 8.53043 5.72727 8C5.72727 7.46957 5.96672 6.96086 6.39294 6.58579C6.81916 6.21071 7.39724 6 8 6C8.60277 6 9.18084 6.21071 9.60706 6.58579C10.0333 6.96086 10.2727 7.46957 10.2727 8C10.2727 8.53043 10.0333 9.03914 9.60706 9.41421C9.18084 9.78929 8.60277 10 8 10ZM8 5C5.72727 5 3.78636 6.244 3 8C3.78636 9.756 5.72727 11 8 11C10.2727 11 12.2136 9.756 13 8C12.2136 6.244 10.2727 5 8 5Z" />
      </g>
      <defs>
        <clipPath id="clip0_2286_2430">
          <rect width="16" height="16" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default SeenCircleOutline;
