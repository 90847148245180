import React, { FC } from 'react';

import { Box, ListItem, ListItemButton, Typography } from '@mui/material';

import { styles } from './styles';
import { getDateTime } from '@helpers/getDateTime';
import { ISessionItem } from '@bus/aiConversation/typedefs';
import { useParams } from 'react-router-dom';

type SessionItemProps = {
  session: ISessionItem;
  monthId: string;
  yearId: number;
  handleClick: (
    sessionId: number | string,
    month: string,
    year: number,
    event: React.KeyboardEvent | React.MouseEvent,
  ) => void;
};

export const SessionItem: FC<SessionItemProps> = ({
  session,
  monthId,
  yearId,
  handleClick,
}) => {
  const { sessionId } = useParams();

  const handleNavigate = (event: React.KeyboardEvent | React.MouseEvent) => {
    handleClick(session.id, monthId, yearId, event);
  };

  return (
    <ListItem key={session.id} disablePadding sx={styles.item}>
      <ListItemButton
        sx={[sessionId === session.id.toString() && styles.activeItem]}
        onClick={handleNavigate}>
        <Box display={'flex'} alignItems={'center'} gap={'10px'}>
          <Typography
            variant={'body1'}
            maxWidth={'225px'}
            color={'text.primary'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}>
            {!session.is_completed
              ? 'Live'
              : getDateTime(session.start_at, 'dd MMMM')}
          </Typography>
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

export default SessionItem;
