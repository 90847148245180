import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const FeatureStoryteller: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox={'0 0 25 24'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.92061 3.84057C2.72797 5.14295 1.85129 6.70249 1.35854 8.3983L0.398254 8.11927C0.933854 6.276 1.88677 4.58084 3.18311 3.16522L3.92061 3.84057Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3045 11.222C23.3664 10.0173 23.1931 8.81637 22.7983 7.66181L23.7445 7.33819C24.1794 8.60979 24.3718 9.93783 24.3031 11.2734C24.2428 12.4468 23.9823 13.5958 23.5379 14.6884L22.6116 14.3116C23.015 13.3199 23.25 12.2808 23.3045 11.222Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.44401 19.246C7.42307 21.1404 9.84179 22.1741 12.3245 22.2131C14.8069 22.2522 17.2453 21.2952 19.2623 19.4651L19.9342 20.2056C17.7464 22.1909 15.0672 23.2564 12.3087 23.213C9.55048 23.1696 6.89613 22.0204 4.7525 19.9683L5.44401 19.246Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7525 3.96833C17.7735 2.07384 15.3547 1.04022 12.8721 1.00115C11.3273 0.97684 9.80175 1.33797 8.38431 2.0535L7.93367 1.16079C9.48942 0.37545 11.1744 -0.0256905 12.8878 0.00127525C15.6461 0.0446844 18.3004 1.19393 20.444 3.24596L19.7525 3.96833Z"
      />
      <path d="M5.89577 20.6071C5.541 20.6071 5.23747 20.4779 4.98517 20.2194C4.73288 19.9608 4.6063 19.6515 4.60544 19.2913V16.6622H8.18195V12.39C7.3622 12.5652 6.52221 12.5389 5.66199 12.3111C4.80176 12.0823 4.0918 11.6805 3.53209 11.1056V9.25609H1.69798L0.598267 8.10714C1.34052 7.42008 1.56763 5.36286 2.50965 5.02195C3.45168 4.68105 2.50373 2.99912 3.5 3C4.3275 3 5.16428 3.65746 5.94614 3.89671C6.72801 4.13595 7.47328 4.51104 8.18195 5.02195V1.60714L11.0042 0.607143H16.0983L21.0982 2.60714V17.9767C21.0982 18.719 20.8498 19.3426 20.353 19.8473C19.8561 20.3521 19.2434 20.6054 18.515 20.6071H5.89577ZM9.47358 16.6635H17.2233V17.9781C17.2233 18.3496 17.3473 18.6616 17.5953 18.914C17.8433 19.1664 18.1499 19.2922 18.515 19.2913C18.8801 19.2904 19.187 19.1647 19.4359 18.914C19.6848 18.6634 19.8083 18.351 19.8066 17.9767V3.92168L18.0983 2.60714L14.5983 0.607143L9.47358 1.10714V6.02495L16.5775 13.2549L17.5983 15.1071L15.6631 14.1856L11.7339 10.1855L11.0042 10.9295C10.7519 11.1854 10.5043 11.4001 10.2615 11.5736C10.0186 11.7471 9.75602 11.8996 9.47358 12.0311V16.6635ZM1.09827 7.60714L4.82372 7.94023V10.5128C5.2646 10.7897 5.67447 10.9781 6.05335 11.078C6.43223 11.1779 6.80077 11.2275 7.15898 11.2266C7.7471 11.2266 8.28141 11.1245 8.76189 10.9203C9.24324 10.7161 9.69402 10.4002 10.1142 9.97251L10.8195 9.25477L8.56427 6.95959C7.87368 6.25674 7.09957 5.72961 6.24193 5.37819C5.38429 5.02677 4.48101 4.8515 3.53209 4.85238C2.9035 4.85238 2.29299 5.44063 1.69798 5.60714C1.10384 5.77365 1.9431 6.18507 1.47209 6.45148L1.09827 7.60714ZM15.9317 17.9767H5.89577V19.2913H16.3244C16.1771 19.1116 16.0747 18.9092 16.017 18.684C15.9601 18.4596 15.9317 18.2243 15.9317 17.9781" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0035 17.9856V3.10714H21.0035V18.2252L20.9507 18.3308C20.768 18.6963 20.5107 19.1022 20.1939 19.4541C19.8811 19.8017 19.4816 20.1285 19.0083 20.2826C18.5112 20.4444 17.9677 20.4027 17.4406 20.066C16.9359 19.7436 16.4766 19.1712 16.0563 18.3308L16.0035 18.2252V17.6071H5.50352V19.6071H4.50352V16.6071H17.0035V17.9871C17.3636 18.6792 17.7013 19.0459 17.9789 19.2233C18.2477 19.3949 18.4792 19.4032 18.6987 19.3317C18.9421 19.2525 19.201 19.0626 19.4506 18.7852C19.6689 18.5427 19.8584 18.2573 20.0035 17.9856Z"
      />
    </SvgIcon>
  );
};

export default FeatureStoryteller;
