import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';

import { getOptions } from '@bus/options/selectors';
import { getFieldError } from '@core/FinalForm/getFieldError';
import { styles } from '../styles';
import StyleCheckbox from '@components/common/uiKit/StyleCheckbox';
import CheckboxGroup from '@components/common/inputs/CheckboxGroup';

export const RenderAssistantField: React.FC<
  FieldRenderProps<(string | number)[]>
> = ({ input, meta }) => {
  const options = useSelector(getOptions('prompt_assistant'));

  return options ? (
    <Box sx={styles.radioWrapper}>
      <Typography variant={'body2'} color={'text.primary'} mb={'16px'}>
        Prompt Assistant
      </Typography>
      <CheckboxGroup
        {...input}
        selectedValues={input.value}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)?.id}
        data-testid={`generate-image-studio-assistant`}
        values={options}
        control={StyleCheckbox}
        controlVariant={'outlined'}
        selectedKey={'id'}
        isNotSelected
      />
    </Box>
  ) : null;
};
