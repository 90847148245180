import React, { FC } from 'react';

import { Box, List, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import Variant15 from '@components/icons/Variant15';
import { ISessionMonth } from '@bus/aiConversation/typedefs';
import SessionItem from '@components/pages/common/ConversationPage/SessionItem';

type SessionItemMonthProps = {
  yearId: number;
  sessionsMont: ISessionMonth;
  handleClick: (
    sessionId: number | string,
    month: string,
    year: number,
    event: React.KeyboardEvent | React.MouseEvent,
  ) => void;
};

export const SessionItemMonth: FC<SessionItemMonthProps> = ({
  yearId,
  sessionsMont,
  handleClick,
}) => {
  const theme = useTheme();

  return (
    <Box sx={styles.root}>
      <Box sx={styles.titleWrapper} gap={'8px'}>
        {(sessionsMont.sessions.some((session) => !session.is_completed) ||
          sessionsMont.is_next_session) && (
          <Variant15 fontSize={'small'} htmlColor={theme.palette.error.main} />
        )}
        <Typography variant={'body2'} color={'text.primary'}>
          {sessionsMont.id}
        </Typography>
      </Box>

      <List sx={styles.list}>
        {sessionsMont.sessions.map((session) => (
          <SessionItem
            key={session.id}
            session={session}
            handleClick={handleClick}
            monthId={sessionsMont.id}
            yearId={yearId}
          />
        ))}
      </List>
    </Box>
  );
};

export default SessionItemMonth;
