export const styles = {
  chatHistoryDrawer: {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  list: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
      height: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
    },
  },
  button: {
    justifyContent: 'flex-start',
    height: '56px',
  },
};
