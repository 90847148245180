import React, { FC } from 'react';

import { Box } from '@mui/material';

import { styles } from './styles';
import LogoIcon from '@components/icons/Logo';
import { Link } from 'react-router-dom';
import { book } from '@routes/book';
import { useDispatch } from 'react-redux';
import { modalActions } from '@core/Modal/state/actions';

type LogoProps = {};

export const Logo: FC<LogoProps> = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(modalActions.closeModal('MasterMenu'));
  };

  return (
    <Link
      to={book.home}
      style={{ textDecoration: 'none' }}
      onClick={handleClick}>
      <Box sx={styles.logo}>
        <LogoIcon sx={styles.icon} />
      </Box>
    </Link>
  );
};

export default Logo;
