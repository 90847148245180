import React, { FC } from 'react';

import { Box, useTheme } from '@mui/material';

import { styles } from './styles';
import RadioChecked from '@components/icons/RadioChecked';
import RadioDefault from '@components/icons/RadioDefault';
import DeleteOutline from '@components/icons/DeleteOutline';
import { IconButton } from '@components/common/uiKit/IconButton';

type ImageRadioProps = {
  image: string;
  isSelected: boolean;
  value: string;
  label: string;
  onChange: any;
  id: number;
  handleAction?: (event: any, id: number) => void;
};

export const ImageRadio: FC<ImageRadioProps> = ({
  image,
  isSelected,
  onChange,
  value,
  label,
  id,
  handleAction,
}) => {
  const theme = useTheme();
  const handleRadioChange = () => {
    !!image && onChange(value);
  };

  return (
    <Box
      aria-checked={isSelected}
      tabIndex={isSelected ? 0 : -1}
      sx={styles.root}
      onClick={handleRadioChange}>
      <input
        type={'radio'}
        value={value}
        checked={isSelected}
        onChange={handleRadioChange}
        style={{ display: 'none' }}
        aria-label={label}
      />
      {image ? (
        <Box sx={styles.wrapper}>
          <Box sx={styles.image} component={'img'} src={image} />
          {isSelected ? (
            <RadioChecked sx={styles.icon} />
          ) : (
            <RadioDefault sx={styles.icon} />
          )}
          <Box sx={styles.overlay} />
          <IconButton
            color={'default'}
            sx={styles.deleteIcon}
            className={'removeIcon'}
            onClick={(event) => {
              handleAction?.(event, id);
            }}>
            <DeleteOutline
              sx={{ fontSize: '16px' }}
              htmlColor={theme.palette.text.secondary}
            />
          </IconButton>
        </Box>
      ) : (
        <Box sx={styles.empty} />
      )}
    </Box>
  );
};

export default ImageRadio;
