import { Theme } from '@mui/material';

export const styles = {
  button: (theme: Theme) => ({
    height: '42px',
    background: theme.palette.common.surface['surface 2'],
    mt: '24px',
    [theme.breakpoints.down(1920)]: {
      mt: '14px',
    },
    [theme.breakpoints.down('lg')]: {
      mt: '34px',
    },
    [theme.breakpoints.down('md')]: {
      mt: '23px',
      height: '39px',
    },
  }),
  wrapper: (theme: Theme) => ({
    width: '100%',
    '&:before': {
      content: '""',
      display: 'block',
      width: '100%',
      paddingTop: '65.745%',
    },
    mt: '10px',
    display: 'flex',
    transition: 'all 0.3s ease-out',
    flexWrap: 'nowrap',
    position: 'relative',
    borderRadius: '16px',
    border: `1px solid ${theme.palette.common.surface['surface 1']}`,
    cursor: 'pointer',
    overflow: 'hidden',
    background: theme.palette.common.surface['surface 1'],
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& .MuiFormControlLabel-root': {
      margin: 0,
      flexShrink: 0,
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
      gap: '16px',
      flexWrap: 'nowrap',
    },
    '&:hover': {
      background: theme.palette.common.action['hover 2'],
      [theme.breakpoints.up('lg')]: {
        '& .deleteWrapper': {
          opacity: 1,
          visibility: 'visible',
        },
      },
    },
    '&': {
      [theme.breakpoints.down(1920)]: {
        '&:before': {
          paddingTop: '47.291%',
        },
      },
      [theme.breakpoints.down('md')]: {
        '&:before': {
          content: '""',
          display: 'block',
          width: '100%',
          paddingTop: '50%',
        },
      },
    },
  }),
  error: (theme: Theme) => ({
    border: `1px solid ${theme.palette.error.main}`,
  }),
  swapWrapper: (theme: Theme) => ({
    width: '100%',
    mt: '10px',
    display: 'flex',
    transition: 'all 0.3s ease-out',
    flexWrap: 'nowrap',
    position: 'relative',
    borderRadius: '16px',
    border: `1px solid ${theme.palette.common.surface['surface 1']}`,
    cursor: 'pointer',
    overflow: 'hidden',
    background: theme.palette.common.surface['surface 1'],
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& .MuiFormControlLabel-root': {
      margin: 0,
      flexShrink: 0,
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
      gap: '16px',
      flexWrap: 'nowrap',
    },
    '&:before': {
      content: '""',
      display: 'block',
      width: '100%',
      paddingTop: '65.745%',
    },
    '&:hover': {
      background: theme.palette.common.action['hover 2'],
      [theme.breakpoints.up('lg')]: {
        '& .deleteWrapper': {
          opacity: 1,
          visibility: 'visible',
        },
      },
    },
    [theme.breakpoints.down(1920)]: {
      '&:before': {
        paddingTop: '47.291%',
      },
    },
  }),
  uploadPhoto: (theme: Theme) => ({
    width: '120px',
    height: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '16px',
    border: `1px solid ${theme.palette.divider}`,
    position: 'relative',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      background: theme.palette.common.action['hover 2'],
    },
  }),
  radioWrapper: (theme: Theme) => ({
    overflowX: 'scroll',
    overflowY: 'hidden',
    height: '100px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
      gap: '20px',
      flexWrap: 'nowrap',
      '& .MuiFormControlLabel-root': {
        m: 0,
      },
    },
    '&': {
      [theme.breakpoints.down('md')]: {
        height: '80px',
      },
    },
  }),
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  image: {
    width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  deleteWrapper: (theme: Theme) => ({
    position: 'absolute',
    visibility: 'hidden',
    opacity: 0,
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.4)',
    transition: 'all 0.3s ease-out',
    [theme.breakpoints.down('lg')]: {
      visibility: 'visible',
      opacity: 1,
    },
  }),
  deleteButton: {
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  overlayText: (theme: Theme) => ({
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  }),
  text: (theme: Theme) => ({
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  }),
  facesTitle: (theme: Theme, isFill: boolean) => ({
    mb: '16px',
    visibility: isFill ? 'visible' : 'hidden',
    [theme.breakpoints.down(1920)]: {
      mb: '8px',
    },
  }),
  imageTitle: (theme: Theme) => ({
    mb: '24px',
    [theme.breakpoints.down(1920)]: {
      mb: '16px',
    },
    [theme.breakpoints.down('lg')]: {
      mb: '12px',
    },
    [theme.breakpoints.down('md')]: {
      mb: '8px',
    },
  }),
  container: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
  }),
};
