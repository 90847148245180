import React, { FC } from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';

import { styles } from './styles';
import Logo from '@components/icons/Logo';

type SuspenseLoadingProps = {};

export const SuspenseLoading: FC<SuspenseLoadingProps> = () => {
  return (
    <Box sx={styles.suspenseLoading}>
      <Box sx={styles.wrapper}>
        <CircularProgress sx={styles.progress} thickness={1} size={80} />
        <Logo sx={styles.icon} />
      </Box>
      <Typography variant={'body1'} my={'20px'} color={'text.primary'}>
        Loading...
      </Typography>
      <Typography
        variant={'body1'}
        color={
          'text.disabled'
        }>{`The world's first neural network`}</Typography>
      <Typography variant={'body1'} color={'text.disabled'}>
        trained on ancient spiritual wisdom.
      </Typography>
    </Box>
  );
};

export default SuspenseLoading;
