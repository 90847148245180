import React from 'react';

import { Box, Button, Typography, useTheme } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import Success from '@components/icons/Success';

type ConfirmProps = {
  title: string;
  text: string;
  confirmButton?: {
    text?: string;
    onConfirm?: () => void;
  };
};

export const Confirm: React.FC<
  ModalTypes.ModalComponentProps<ConfirmProps>
> = ({ closeFn, confirmButton, text, title }) => {
  const theme = useTheme();
  const closeModal = () => {
    closeFn();
    confirmButton?.onConfirm && confirmButton.onConfirm();
  };

  return (
    <Box sx={styles.successChangeProfilePassword}>
      <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
        <Box display={'flex'} alignItems={'center'} gap={'20px'}>
          <Success fontSize={'medium'} htmlColor={theme.palette.success.main} />
          <Typography variant={'h5'} color={'text.primary'}>
            {title}
          </Typography>
        </Box>
        <Typography variant={'body1'} color={'text.primary'}>
          {text}
        </Typography>
      </Box>
      <Box alignSelf={'flex-end'}>
        <Button
          onClick={closeModal}
          variant={'primary'}
          color={'sixth'}
          fullWidth>
          <Typography variant={'body1'}>
            {confirmButton?.text ?? 'ok'}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default Confirm;
