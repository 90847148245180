import { Theme } from '@mui/material/styles';

export const styles = {
  timeLeftToConversation: (theme: Theme) => ({
    borderRadius: '16px',
    background: theme.palette.common.surface['surface 1'],
    padding: '20px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '24px',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',
    width: '100%',
    height: '62px',
    '&:hover': {
      background: theme.palette.action.hover,
    },
    [theme.breakpoints.down(1920)]: {
      height: '104px',
      flexDirection: 'column',
      flex: '1 1 calc(25% - 8px)',
      maxWidth: 'calc(25% - 8px)',
      alignItems: 'baseline',
      justifyContent: 'flex-start',
      gap: '8px',
    },
    [theme.breakpoints.down('lg')]: {
      height: '120px',
    },
    [theme.breakpoints.down('md')]: {
      height: '44px',
      p: '12px',
      flexDirection: 'row',
      flex: '1 1 100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      maxWidth: '100%',
    },
  }),
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  beta: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    flexWrap: 'wrap',
  },
  text: (theme: Theme) => ({
    [theme.breakpoints.down(1920)]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  }),
  live: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  description: (theme: Theme) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(1920)]: {
      whiteSpace: 'wrap',
    },
    '&': {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  }),
};
