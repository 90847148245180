import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Clock: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox={'0 0 12 12'}>
      <g clipPath="url(#clip0_2918_3163)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 1.375C4.77337 1.375 3.59699 1.86228 2.72963 2.72963C1.86228 3.59699 1.375 4.77337 1.375 6C1.375 7.22663 1.86228 8.40301 2.72963 9.27037C3.59699 10.1377 4.77337 10.625 6 10.625C7.22663 10.625 8.40301 10.1377 9.27037 9.27037C10.1377 8.40301 10.625 7.22663 10.625 6C10.625 4.77337 10.1377 3.59699 9.27037 2.72963C8.40301 1.86228 7.22663 1.375 6 1.375ZM0.625 6C0.625 3.0315 3.0315 0.625 6 0.625C8.9685 0.625 11.375 3.0315 11.375 6C11.375 8.9685 8.9685 11.375 6 11.375C3.0315 11.375 0.625 8.9685 0.625 6ZM6 3.625C6.09946 3.625 6.19484 3.66451 6.26516 3.73483C6.33549 3.80516 6.375 3.90054 6.375 4V5.845L7.515 6.985C7.55184 7.01933 7.58139 7.06073 7.60189 7.10673C7.62239 7.15273 7.63341 7.20239 7.6343 7.25274C7.63518 7.30309 7.62592 7.3531 7.60706 7.3998C7.5882 7.44649 7.56013 7.48891 7.52452 7.52452C7.48891 7.56013 7.44649 7.5882 7.3998 7.60706C7.3531 7.62592 7.30309 7.63518 7.25274 7.6343C7.20239 7.63341 7.15273 7.62239 7.10673 7.60189C7.06073 7.58139 7.01933 7.55184 6.985 7.515L5.735 6.265C5.66465 6.19474 5.62509 6.09942 5.625 6V4C5.625 3.90054 5.66451 3.80516 5.73484 3.73483C5.80516 3.66451 5.90054 3.625 6 3.625Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_2918_3163">
          <rect width="12" height="12" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Clock;
