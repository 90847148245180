import React, { useEffect, useRef } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';

import { Box, TextField, Typography } from '@mui/material';
import { getFieldError } from '@core/FinalForm/getFieldError';
import { useSelector } from 'react-redux';
import { getOptions } from '@bus/options/selectors';

export const RenderPromptField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  const options = useSelector(getOptions('prompt_assistant'));
  const {
    input: { value: assistant },
  } = useField('assistant');
  const prevAssistantRef = useRef(assistant);

  useEffect(() => {
    const idToParameterMap = options?.reduce((acc: any, option) => {
      acc[option.id] = option.parameter;

      return acc;
    }, {});

    if (
      JSON.stringify(prevAssistantRef.current) !== JSON.stringify(assistant)
    ) {
      const changes = assistant?.filter(
        (x: any) => !prevAssistantRef?.current?.includes(x),
      );
      if (changes.length > 0) {
        const lastId = changes[changes.length - 1];
        if (lastId !== 0) {
          const parameterToAdd = idToParameterMap?.[lastId] || lastId;
          if (parameterToAdd !== undefined) {
            input.onChange(`${input.value} ${parameterToAdd} `);
          }
        }
      }
    }

    prevAssistantRef.current = assistant;
  }, [assistant, options, input]);

  return (
    <Box>
      <Typography variant={'body1'} color={'text.disabled'}>
        Prompt
      </Typography>
      <TextField
        multiline
        inputProps={{
          'data-testid': `generate-image-studio-prompt`,
        }}
        fullWidth
        variant={'standard'}
        minRows={1}
        maxRows={5}
        placeholder={'Describe what you want to see here'}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)}
        {...input}
      />
    </Box>
  );
};
