import { Theme } from '@mui/material/styles';

export const styles = {
  gotItTooltip: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    borderRadius: '10px',
    background: theme.palette.background.paper,
    padding: '10px',
  }),
  iconWrapper: (theme: Theme) => ({
    maxWidth: '44px',
    width: '100%',
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    background: theme.palette.action.hover,
    padding: '10px',
  }),
  button: {
    minWidth: '79px',
    padding: '14px 16px',
  },
};
