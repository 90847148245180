import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Typography,
  TypographyTypeMap,
} from '@mui/material';

type CheckboxGroupProps = {
  label?: string;
  values: any[];
  selectedValues: (string | number)[];
  onChange: (value: (string | number)[]) => void;
  ['data-testid']?: string;
  error?: boolean;
  helperText?: string;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  labelVariant?: TypographyTypeMap['props']['variant'];
  showLabel?: boolean;
  disabled?: boolean;
  selectedKey: string;
  control?: React.ElementType;
  controlVariant?: 'primary' | 'outlined';
  isNotSelected?: boolean;
};

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  label,
  values,
  selectedValues,
  onChange,
  error,
  helperText,
  color = 'primary',
  labelVariant = 'body1',
  control,
  controlVariant = 'primary',
  showLabel = true,
  disabled = false,
  selectedKey,
  isNotSelected = false,
  ...props
}) => {
  const handleSelectionChange = (value: string | number, checked: boolean) => {
    let newSelectedValues = [...selectedValues];

    if (checked) {
      if (value === 0) {
        newSelectedValues = [0];
      } else {
        newSelectedValues = newSelectedValues.filter((val) => val !== 0);
        newSelectedValues.push(value);
      }
    } else {
      newSelectedValues = newSelectedValues.filter((val) => val !== value);
      if (newSelectedValues.length === 0) {
        newSelectedValues.push(0);
      }
    }

    onChange(newSelectedValues);
  };

  return (
    <FormControl component={'fieldset'} error={error}>
      {label && <FormLabel component={'legend'}>{label}</FormLabel>}
      <FormGroup {...props}>
        {values.map((value) => (
          <FormControlLabel
            key={value[selectedKey]}
            control={
              control ? (
                React.createElement(control, {
                  checked: selectedValues.includes(value[selectedKey]),
                  label: value.label || value.name,
                  onChange: (event: any) => {
                    handleSelectionChange(
                      value[selectedKey],
                      event.target.checked,
                    );
                  },
                  disabled,
                  variant: controlVariant,
                  isNotSelected,
                })
              ) : (
                <Checkbox
                  checked={selectedValues.includes(value[selectedKey])}
                  onChange={(event) =>
                    handleSelectionChange(
                      value[selectedKey],
                      event.target.checked,
                    )
                  }
                  name={value.label || value.name}
                  color={color}
                  disabled={disabled}
                />
              )
            }
            label={
              showLabel && (
                <Typography
                  variant={labelVariant}
                  color={error ? 'error' : color}>
                  {value.label}
                </Typography>
              )
            }
          />
        ))}
      </FormGroup>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default CheckboxGroup;
