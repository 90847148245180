import React, { FC } from 'react';

import { Box, Button, ModalProps, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import DrawerHeader from '@components/common/DrawerHeader';
import History from '@components/icons/History';
import Plus from '@components/icons/Plus';
import ActivityTimeline from '@components/common/ActivityTimeline';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCanDoAction,
  getCurrentDataset,
  getProfile,
  getShowBuyTokensModal,
  getShowUpdatePlanToUseTokensModal,
} from '@bus/profile/selectors';
import { book } from '@routes/book';
import { useNavigate, useParams } from 'react-router-dom';
import { modalActions } from '@core/Modal/state/actions';
import { projectsActions } from '@bus/projects/actions';
import { useLimits } from '../../../hooks/useLimits';
import { getChatData, getEmptyChat } from '@bus/projects/selectors';

type ChatHistoryDrawerProps = {
  onClose?: ModalProps['onClose'];
};

export const ChatHistoryDrawer: FC<ChatHistoryDrawerProps> = ({ onClose }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(getProfile);
  const currentDataset = useSelector(getCurrentDataset);
  const emptyChat = useSelector(getEmptyChat(currentDataset?.name ?? ''));
  const canDoAction = useSelector(getCanDoAction);
  const showBuyTokensModal = useSelector(getShowBuyTokensModal);
  const showUpdatePlanToUseTokensModal = useSelector(
    getShowUpdatePlanToUseTokensModal,
  );
  const { chatId } = useParams();
  const { currentYearId, id } = useSelector(getChatData(chatId));

  const { handleLimitExceeded } = useLimits(
    showUpdatePlanToUseTokensModal,
    showBuyTokensModal,
  );

  const handleClick = (
    id: number,
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    navigate(`${book.chat}/${currentDataset?.name}/${id}`);
    onClose?.(event, 'backdropClick');
  };

  const handleAction = (chatId: number, name: string) => {
    dispatch(
      modalActions.modal({
        component: 'EditChat',
        title: 'Chat Edit',
        forceClose: true,
        chatId,
        projectName: name,
      }),
    );
  };

  const handleCreateNewChat = (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    onClose?.(event, 'backdropClick');
    if (!profile?.subscription) {
      navigate(book.forUnsubscribedUsers);

      return;
    }
    if (emptyChat) {
      navigate(`${book.chat}/${currentDataset?.name}/${emptyChat.id}`);

      return;
    }

    if (!canDoAction) {
      handleLimitExceeded();

      return;
    }

    new Promise((resolve, reject) => {
      dispatch(
        projectsActions.createNewChat({
          values: {
            project_id: currentDataset?.id,
            projectName: currentDataset?.name,
            name: 'New Chat',
          },
          resolve,
          reject,
          navigate,
        }),
      );
    })
      .then((data: any) => {
        navigate(`${book.chat}/${currentDataset?.name}/${data.id}`);
      })
      .catch();
  };

  return (
    <Box sx={styles.chatHistoryDrawer}>
      <DrawerHeader
        onClose={onClose}
        name={'History'}
        isSearch
        icon={
          <History
            sx={{ fontSize: '16px' }}
            htmlColor={theme.palette.common.surface['surface 47']}
          />
        }
      />
      <Button
        variant={'drawerCreateChat'}
        startIcon={
          <Plus
            sx={{ fontSize: '20px' }}
            htmlColor={theme.palette.common.surface['surface 46']}
          />
        }
        onClick={handleCreateNewChat}>
        <Typography variant={'body1'} color={'common.brand'}>
          New chat
        </Typography>
      </Button>
      <ActivityTimeline
        list={currentDataset?.years ?? []}
        emptyStateTitle={`You haven't started any chats yet.`}
        handleClick={handleClick}
        currentChatId={id}
        currentYear={currentYearId}
        handleAction={handleAction}
      />
    </Box>
  );
};

export default ChatHistoryDrawer;
