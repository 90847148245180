import { Components, Theme } from '@mui/material';

export const MuiButton: Partial<Components<Theme>> = {
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '50px',
        padding: '14px 10px',
        lineHeight: '16px',
        letterSpacing: '0.0125em',
        boxSizing: 'border-box',
        height: '44px',
      },
    },
    defaultProps: {
      variant: 'contained',
      color: 'primary',
      disableRipple: true,
      disableElevation: true,
    },
    variants: [
      {
        props: { variant: 'primary' },
        style: ({ theme }) => ({
          height: '39px',
          border: `1px solid ${theme.palette.common.dividerBrand}`,
          borderRadius: '24px',
          background: 'none',
          color: theme.palette.common.brand,
          padding: '10px 16px',
          textTransform: 'initial',
          '&:hover': {
            background: theme.palette.common.surface['surface 4'],
            color: theme.palette.common.surface['surface 1'],
          },
          '&.Mui-disabled': {
            color: theme.palette.text.disabled,
            border: `1px solid ${theme.palette.common.surface['surface 2']}`,
            background: theme.palette.common.surface['surface 2'],
          },
        }),
      },
      {
        props: { variant: 'primary', color: 'secondary' },
        style: ({ theme }) => ({
          height: '40px',
          border: `none`,
          borderRadius: '24px',
          background: theme.palette.common.surface['surface 2'],
          color: theme.palette.text.disabled,
          padding: '8px 16px',
          textTransform: 'capitalize',
          '&:hover': {
            background: theme.palette.common.surface['surface 4'],
            color: theme.palette.common.surface['surface 1'],
          },
          '&:focus': {
            background: theme.palette.common.surface['surface 4'],
          },
          '&.Mui-disabled': {
            color: theme.palette.text.disabled,
            border: `1px solid ${theme.palette.action.disabled}`,
            background: theme.palette.action.disabled,
          },
          '& .MuiButton-startIcon': {
            margin: '0 4px 0 0',
          },
        }),
      },
      {
        props: { variant: 'primary', color: 'third' },
        style: ({ theme }) => ({
          height: '56px',
          border: `none`,
          borderRadius: '24px',
          background: theme.palette.common.surface['surface 2'],
          color: theme.palette.common.brand,
          padding: '16px 32px',
          textTransform: 'initial',
          '&:hover': {
            background: theme.palette.common.surface['surface 4'],
            color: theme.palette.common.surface['surface 1'],
          },
          '&.Mui-disabled': {
            color: theme.palette.text.disabled,
            border: `1px solid ${theme.palette.action.disabled}`,
            background: theme.palette.action.disabled,
          },
          '& .MuiButton-startIcon': {
            margin: '0 4px 0 0',
          },
          [theme.breakpoints.down('md')]: {
            height: '40px',
          },
        }),
      },
      {
        props: { variant: 'primary', color: 'fourth' },
        style: ({ theme }) => ({
          height: '56px',
          borderRadius: '24px',
          background: theme.palette.common.surface['surface 2'],
          border: `1px solid ${theme.palette.common.dividerBrand}`,
          color: theme.palette.common.brand,
          padding: '16px 32px',
          textTransform: 'initial',
          '&:hover': {
            background: theme.palette.common.surface['surface 4'],
            color: theme.palette.common.surface['surface 1'],
          },
          '&.Mui-disabled': {
            color: theme.palette.text.disabled,
            border: `1px solid ${theme.palette.action.disabled}`,
            background: theme.palette.action.disabled,
          },
          '& .MuiButton-startIcon': {
            margin: '0 4px 0 0',
          },
        }),
      },
      {
        props: { variant: 'primary', color: 'fifth' },
        style: ({ theme }) => ({
          height: '24px',
          background: 'none',
          border: 'none',
          color: theme.palette.common.brand,
          padding: 0,
          textTransform: 'initial',
          minWidth: 'initial',
          '&:hover': {
            background: 'none',
            color: theme.palette.text.secondary,
            '& .MuiButton-startIcon': {
              '& svg': {
                '& path': {
                  fill: theme.palette.common.surface['surface 48'],
                },
              },
            },
            '& .MuiButton-endIcon': {
              '& svg': {
                '& path': {
                  fill: theme.palette.common.surface['surface 48'],
                },
              },
            },
          },
          '&.Mui-disabled': {
            color: theme.palette.common.surface['surface 47'],
            border: 'none',
            background: 'none',
            '& .MuiButton-startIcon': {
              '& svg': {
                '& path': {
                  fill: theme.palette.common.surface['surface 47'],
                },
              },
            },
            '& .MuiButton-endIcon': {
              '& svg': {
                '& path': {
                  fill: theme.palette.common.surface['surface 47'],
                },
              },
            },
          },
          '& .MuiButton-startIcon': {
            margin: '0 8px 0 0',
            '& svg': {
              '& path': {
                transition: 'all 0.3s ease-in-out',
              },
            },
          },
          '& .MuiButton-endIcon': {
            margin: '0 0 0 8px',
            '& svg': {
              '& path': {
                transition: 'all 0.3s ease-in-out',
              },
            },
          },
        }),
      },
      {
        props: { variant: 'primary', color: 'sixth' },
        style: ({ theme }) => ({
          height: '56px',
          borderRadius: '24px',
          background: theme.palette.common.surface['surface 1'],
          border: `1px solid ${theme.palette.common.dividerBrand}`,
          color: theme.palette.common.brand,
          padding: '16px 32px',
          textTransform: 'initial',
          '&:hover': {
            background: theme.palette.common.surface['surface 4'],
            color: theme.palette.common.surface['surface 1'],
          },
          '&.Mui-disabled': {
            color: theme.palette.text.disabled,
            border: `1px solid ${theme.palette.action.disabled}`,
            background: theme.palette.action.disabled,
          },
          '& .MuiButton-startIcon': {
            margin: '0 4px 0 0',
          },
          [theme.breakpoints.down('md')]: {
            height: '40px',
          },
        }),
      },
      {
        props: { variant: 'primary', size: '40' },
        style: ({ theme }) => ({
          height: '40px',
          borderRadius: '24px',
          background: theme.palette.common.surface['surface 1'],
          border: `1px solid ${theme.palette.common.dividerBrand}`,
          color: theme.palette.common.brand,
          padding: '16px 32px',
          textTransform: 'initial',
          '&:hover': {
            background: theme.palette.common.surface['surface 4'],
            color: theme.palette.common.surface['surface 1'],
            '& .MuiButton-startIcon': {
              margin: '0 4px 0 0',
              '& svg': {
                fill: theme.palette.common.surface['surface 1'],
              },
            },
          },
          '&.Mui-disabled': {
            color: theme.palette.text.disabled,
            border: `1px solid ${theme.palette.action.disabled}`,
            background: theme.palette.action.disabled,
          },
          '& .MuiButton-startIcon': {
            margin: '0 4px 0 0',
          },
        }),
      },
      {
        props: { variant: 'secondary' },
        style: ({ theme }) => ({
          borderRadius: '24px',
          padding: '8px 24px',
          background: theme.palette.common.surface['surface 2'],
          height: '40px',
          color: theme.palette.common.brand,
          textTransform: 'initial',
          '&:hover': {
            background: theme.palette.action.hover,
          },
          '&:focus': {
            background: theme.palette.action.selected,
          },
          '&.Mui-disabled': {
            color: theme.palette.text.disabled,
            background: 'none',
          },
          '& .MuiButton-startIcon': {
            marginRight: '8px',
          },
        }),
      },
      {
        props: { variant: 'secondary', color: 'secondary' },
        style: {
          border: `none`,
          background: 'none',
          '&:hover': {
            border: `none`,
            background: 'none',
          },
          '&:focus': {
            border: `none`,
            background: 'none',
          },
          '&.Mui-disabled': {
            border: `none`,
            background: 'none',
          },
        },
      },
      {
        props: { variant: 'tertiary' },
        style: ({ theme }) => ({
          border: `1px solid ${theme.palette.common.surface['surface 4']}`,
          borderRadius: '16px',
          background: theme.palette.common.surface['surface 2'],
          height: '56px',
          padding: '16px 40px',
          textTransform: 'capitalize',
          color: theme.palette.common.brand,
          '&:hover': {
            background: theme.palette.common.action.hover,
          },
          '&:focus': {
            background: theme.palette.common.action.pressed,
          },
          '&.Mui-disabled': {
            background: theme.palette.action.disabled,
          },
          '& .MuiButton-endIcon': {
            marginLeft: '8px',
          },
        }),
      },
      {
        props: { variant: 'drawer' },
        style: ({ theme }) => ({
          border: 'none',
          borderRadius: '16px',
          height: '40px',
          background: theme.palette.common.surface['surface 2'],
          color: theme.palette.text.secondary,
          padding: '8px 16px',
          textTransform: 'capitalize',
          '&:hover': {
            background: theme.palette.common.surface['surface 3'],
          },
          '&.Mui-disabled': {
            color: theme.palette.text.disabled,
            background: theme.palette.action.disabled,
          },
          [theme.breakpoints.down('lg')]: {
            height: '48px',
          },
        }),
      },
      {
        props: { variant: 'drawer', color: 'secondary' },
        style: ({ theme }) => ({
          border: 'none',
          borderRadius: '8px',
          height: '40px',
          background: 'none',
          color: theme.palette.text.secondary,
          padding: '8px',
          textTransform: 'capitalize',
          '&:hover': {
            background: theme.palette.common.surface['surface 2'],
          },
          '&.Mui-disabled': {
            background: 'none',
            '& .MuiButton-startIcon': {
              '& svg': {
                '& path': {
                  fill: theme.palette.text.disabled,
                },
              },
            },
          },
          '& .MuiButton-startIcon': {
            margin: '0 12px 0 0',
            '& svg': {
              '& path': {
                transition: 'all 0.3s ease-out',
              },
            },
          },
          [theme.breakpoints.down('lg')]: {
            height: '40px',
          },
        }),
      },
      {
        props: { variant: 'drawerCreateChat' },
        style: ({ theme }) => ({
          borderBottom: `1px solid ${theme.palette.divider}`,
          height: '64px',
          background: theme.palette.common.surface['surface 44'],
          padding: '20px',
          borderRadius: 0,
          textTransform: 'capitalize',
          justifyContent: 'flex-start',
          '&:hover': {
            background: theme.palette.common.surface['surface 3'],
          },
          '&:focus': {
            background: theme.palette.common.surface['surface 44'],
          },
          '&.Mui-disabled': {
            color: theme.palette.text.disabled,
            background: theme.palette.action.disabled,
          },
          '& .MuiButton-startIcon': {
            margin: '0 8px 0 0',
          },
        }),
      },
      {
        props: { variant: 'drawerFilter' },
        style: ({ theme }) => ({
          height: '40px',
          border: `1px solid ${theme.palette.common.surface['surface 5']}`,
          borderRadius: '24px',
          background: theme.palette.common.surface['surface 5'],
          color: theme.palette.text.secondary,
          padding: '8px 12px',
          textTransform: 'capitalize',
          justifyContent: 'space-between',
          '&:hover': {
            background: theme.palette.common.surface['surface 3'],
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.divider}`,
          },
          '&.Mui-disabled': {
            background: theme.palette.common.surface['surface 3'],
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.divider}`,
          },
        }),
      },
    ],
  },
};
