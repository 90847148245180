import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

type InputLabelProps = {
  label: string;
};

export const InputLabel: FC<InputLabelProps> = ({ label }) => {
  return (
    <Box sx={styles.inputLabel}>
      <Typography variant={'body1'} color={'text.secondary'}>
        {label}
      </Typography>
      <Typography variant={'body1'} color={'error.main'}>
        *
      </Typography>
    </Box>
  );
};

export default InputLabel;
