import React from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Box, TextField, Typography } from '@mui/material';

import { getFieldError } from '@core/FinalForm/getFieldError';
import { getIsPromptFetching } from '@bus/projects/selectors';
import { useSelector } from 'react-redux';

export const RenderPromptField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  const isFetching = useSelector(getIsPromptFetching);

  return (
    <Box mt={'10px'} display={'flex'} flexDirection={'column'}>
      <Typography variant={'body1'} color={'text.disabled'}>
        Prompt
      </Typography>
      <TextField
        multiline
        inputProps={{
          'data-testid': `visual-adjustments-form-prompt`,
        }}
        fullWidth
        required
        variant={'standard'}
        minRows={3}
        maxRows={5}
        disabled={isFetching}
        placeholder={'Loading Prompt...'}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)}
        {...input}
      />
    </Box>
  );
};
