import React, { FC } from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import parse from 'html-react-parser';
import { getDateTime } from '@helpers/getDateTime';
import { IFoundedChatActionPayload } from '@bus/chat/typedefs';
import Search from '@components/icons/Search';

type SearchChatListProps = {
  foundedChats: IFoundedChatActionPayload[];
  handleNavigate: (
    event: React.KeyboardEvent | React.MouseEvent,
    name: string,
    id: number,
  ) => void;
};

export const SearchChatList: FC<SearchChatListProps> = ({
  foundedChats,
  handleNavigate,
}) => {
  const theme = useTheme();

  return (
    <Box sx={styles.searchChatList}>
      {foundedChats.length ? (
        foundedChats.map((chat) => (
          <Box
            sx={styles.item}
            key={chat.id}
            onClick={(event) =>
              handleNavigate(event, chat.project.name, chat.id)
            }>
            <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
              <Typography variant={'body1'} color={'text.primary'}>
                {chat.name}
              </Typography>
              <Typography variant={'caption'} color={'text.secondary'}>
                {parse(chat.last_matching_message ?? '')}
              </Typography>
            </Box>
            <Typography
              variant={'caption'}
              color={'text.secondary'}
              flexShrink={0}>
              {getDateTime(chat.created_at, 'MMMM dd yyyy')}
            </Typography>
          </Box>
        ))
      ) : (
        <Box
          display={'flex'}
          alignItems={'center'}
          gap={'10px'}
          p={'12px 20px'}>
          <Search
            fontSize={'small'}
            htmlColor={theme.palette.common.surface['surface 47']}
          />
          <Typography variant={'body1'} color={'text.primary'}>
            No results
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SearchChatList;
