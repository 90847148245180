import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useRoutes,
} from 'react-router-dom';

import { privateRoutes } from '@pages/private/routes';
import { projectsActions } from '@bus/projects/actions';
import { profileActions } from '@bus/profile/actions';
import NotFoundPage from '@pages/private/NotFoundPage';
import NotFoundLayout from '@layouts/NotFoundLayout';
import { savedMessagesActions } from '@bus/savedMessages/actions';
import { settingsActions } from '@bus/settings/actions';
import { book } from '@routes/book';
import { useLocation } from 'react-router';
import { getLastLink, getLiveSession } from '@bus/aiConversation/selectors';
import { aiConversationActions } from '@bus/aiConversation/actions';
import { modalActions } from '@core/Modal/state/actions';
import { optionsActions } from '@bus/options/actions';
import {
  getIsOnboarding,
  getProfile,
  getProfileFetched,
  getSubscriptionCoupon,
} from '@bus/profile/selectors';
import { getIsAuthenticate } from '@bus/auth/selectors';
import { storyTellerActions } from '@bus/storyTeller/actions';
import { usePrivateTimer } from '../hooks/usePrivateTimer';
import { guidanceActions } from '@bus/guidance/actions';
import AppLayout from '@layouts/AppLayout';
import { createdImagesActions } from '@bus/createdImages/actions';
import { setNavigate } from '@helpers/navigateGlobal';
import { chatActions } from '@bus/chat/actions';

// IMPORTS
const PrivateRoutes = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const link = useSelector(getLastLink);
  const last = useSelector(getLiveSession);
  const { isFinished } = usePrivateTimer(last);

  const profile = useSelector(getProfile);
  const isProfileFetching = useSelector(getProfileFetched);
  const isAuthenticate = useSelector(getIsAuthenticate);
  const isOnboarding = useSelector(getIsOnboarding);
  const coupon = useSelector(getSubscriptionCoupon);

  const navigate = useNavigate();

  useEffect(() => {
    setNavigate(navigate);
  }, [navigate]);

  useEffect(() => {
    dispatch(profileActions.fetchProfile());
    dispatch(projectsActions.fetchProjects());
    dispatch(savedMessagesActions.fetchSavedProjects());
    dispatch(settingsActions.fetchSettings());
    dispatch(optionsActions.midjourneyParameters());
    dispatch(optionsActions.fetchStorytellersStyles());
    dispatch(guidanceActions.fetchGuidance());
    dispatch(createdImagesActions.fetchImageStudioFaces());
    dispatch(chatActions.fetchRecentChats());
    new Promise((resolve, reject) => {
      dispatch(
        storyTellerActions.fetchStory({
          values: {},
          resolve,
          reject,
        }),
      );
    })
      .then()
      .catch();
  }, []);

  useEffect(() => {
    if (isProfileFetching) {
      dispatch(profileActions.fetchTokensSettings());
    }
  }, [isProfileFetching]);

  useEffect(() => {
    if (isFinished) {
      dispatch(aiConversationActions.nextSession());
    }
  }, [isFinished]);

  useEffect(() => {
    if (isOnboarding && profile) {
      dispatch(
        modalActions.modal({
          component: coupon
            ? 'AmbassadorInformationWindow'
            : 'TrialInformationWindow',
          forceClose: false,
          tokens: profile?.tokens,
          buttonLabel: 'Try It',
          variant: 'secondary',
        }),
      );
    }
  }, [isOnboarding, profile]);

  const matchStories = useRoutes([
    {
      path: `${book.storyTeller}/*`,
      element: <Navigate to={book.forUnsubscribedUsers} replace />,
    },
  ]);

  const matchChat = useRoutes([
    {
      path: `${book.chat}/*`,
      element: <Navigate to={book.forUnsubscribedUsers} replace />,
    },
  ]);

  const matchSaved = useRoutes([
    {
      path: `${book.savedMessages}/*`,
      element: <Navigate to={book.forUnsubscribedUsers} replace />,
    },
  ]);

  const matchGallery = useRoutes([
    {
      path: `${book.imageGallery}/*`,
      element: <Navigate to={book.forUnsubscribedUsers} replace />,
    },
  ]);

  const matchMetametrics = useRoutes([
    {
      path: `${book.metametrics}`,
      element: <Navigate to={book.forUnsubscribedUsers} replace />,
    },
  ]);

  const matchMetametricsCreating = useRoutes([
    {
      path: `${book.metametricsCreating}/*`,
      element: <Navigate to={book.forUnsubscribedUsers} replace />,
    },
  ]);

  const matchMetametricsCard = useRoutes([
    {
      path: `${book.metametricsCard}/*`,
      element: <Navigate to={book.forUnsubscribedUsers} replace />,
    },
  ]);

  const matchMaintenance = useRoutes([
    {
      path: book.maintenance,
      element: <Navigate to={book.home} replace />,
    },
  ]);

  if (
    isAuthenticate &&
    isProfileFetching &&
    !profile?.subscription &&
    (matchChat ||
      matchSaved ||
      matchGallery ||
      matchStories ||
      matchMetametrics ||
      matchMetametricsCreating ||
      matchMetametricsCard)
  ) {
    return <Navigate to={book.forUnsubscribedUsers} replace />;
  }

  if (matchMaintenance) {
    return <Navigate to={book.home} replace />;
  }

  return (
    <Routes>
      {privateRoutes.map(({ path, Component, Layout, layoutProps }) => (
        <Route element={<Layout {...layoutProps} />} key={path}>
          <Route path={path} element={Component} />
        </Route>
      ))}
      {link && (
        <Route path={book.conversation} element={<Navigate to={link} />} />
      )}
      {Object.values(book).some((item) => item === location.pathname) ? (
        <Route element={<AppLayout />}>
          <Route path={'/*'} element={<Navigate replace to={book.home} />} />
        </Route>
      ) : (
        <Route element={<NotFoundLayout />}>
          <Route path={'/*'} element={<NotFoundPage />} />
        </Route>
      )}
    </Routes>
  );
};

export default PrivateRoutes;
