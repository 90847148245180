import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Edit: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0448 6.0835C15.5485 6.0835 15.0725 6.28067 14.7215 6.63164L14.3773 6.97586C14.3983 7.06211 14.4269 7.16317 14.4656 7.27507C14.6006 7.66437 14.8562 8.17724 15.3394 8.66016C15.8228 9.14355 16.336 9.39949 16.7253 9.53461C16.8368 9.57332 16.9376 9.60189 17.0236 9.62289L17.3682 9.27831C17.7192 8.92734 17.9163 8.45132 17.9163 7.95498C17.9163 7.45863 17.7192 6.98261 17.3682 6.63164C17.0172 6.28067 16.5412 6.0835 16.0448 6.0835ZM15.847 10.7993L11.0696 15.5763C10.6725 15.9733 10.5166 16.1274 10.3495 16.2577C10.1421 16.4194 9.91778 16.5581 9.68053 16.6716C9.48898 16.7626 9.28145 16.8331 8.74895 17.0104L7.00105 17.5932L6.40564 16.9978L6.98801 15.2512C7.16549 14.7188 7.23592 14.5109 7.32687 14.3194C7.44033 14.0821 7.57909 13.8578 7.74076 13.6503C7.87127 13.483 8.02522 13.3273 8.42233 12.9295L13.2004 8.15249C13.4144 8.63644 13.7509 9.19328 14.2789 9.72097M14.2789 9.72097C14.8066 10.2486 15.3633 10.5853 15.847 10.7993L14.2789 9.72097ZM4.83569 16.9641L5.565 14.7768C5.57094 14.759 5.57682 14.7414 5.5826 14.724C5.7386 14.2558 5.83792 13.9578 5.97246 13.6747L5.97316 13.6732C6.13333 13.3381 6.32928 13.0213 6.55761 12.7283C6.75042 12.4811 6.97245 12.2586 7.32105 11.9095C7.3342 11.8963 7.34754 11.883 7.36106 11.8694L13.0429 6.18898L13.6609 5.57098C14.2931 4.9387 15.1507 4.5835 16.0448 4.5835C16.939 4.5835 17.7966 4.9387 18.4288 5.57098C19.0611 6.20325 19.4163 7.0608 19.4163 7.95498C19.4163 8.84915 19.0611 9.7067 18.4288 10.339L17.8108 10.957L12.1302 16.637L12.0961 16.671C11.7439 17.0234 11.5206 17.2467 11.2717 17.4407C10.9788 17.669 10.6617 17.8652 10.3266 18.0253L10.3251 18.026C10.0398 18.1616 9.74031 18.2613 9.26457 18.4197L6.50229 19.3411C6.2456 19.427 5.9693 19.4399 5.70578 19.378C5.44201 19.316 5.20077 19.1816 5.00917 18.99C4.81757 18.7984 4.68321 18.5572 4.62119 18.2934C4.5592 18.0298 4.57194 17.7541 4.65795 17.4973M4.65795 17.4973L4.83569 16.9641L4.65795 17.4973Z"
      />
    </SvgIcon>
  );
};

export default Edit;
