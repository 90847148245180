import { Theme } from '@mui/material';

export const styles = {
  share: (theme: Theme) => ({
    // position: 'absolute',
    width: '100%',
    zIndex: 10003,
    left: 0,
    bottom: 0,
    background: theme.palette.common.surface['surface 3'],
    padding: '10px 20px',
    height: '66px',
    display: 'flex',
    justifyContent: 'center',
    flexShrink: 0,
    [theme.breakpoints.down('lg')]: {
      height: '115px',
      position: 'static',
      padding: '20px 12px',
    },
    '&': {
      [theme.breakpoints.down('md')]: {
        height: '240px',
        justifyContent: 'flex-start',
      },
    },
  }),
  wrapper: (theme: Theme) => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 'max-content',
    gap: '52px',
    [theme.breakpoints.down(1920)]: {
      gap: '32px',
    },
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      gap: 0,
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      width: '100%',
    },
  }),
  button: (theme: Theme) => ({
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    '&.Mui-disabled': {
      border: 'none',
    },
    '& svg': {
      '& path': {
        fill: theme.palette.common.surface['surface 4'],
      },
    },
    [theme.breakpoints.down('md')]: {
      width: '24px',
      height: '24px',
    },
  }),
  copy: (theme: Theme) => ({
    '& svg': {
      '& path': {
        fill: theme.palette.text.primary,
      },
    },
  }),
  progress: (theme: Theme) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    color: theme.palette.divider,
    m: '0!important',
  }),
  leftWrapper: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    [theme.breakpoints.down('lg')]: {
      px: '8px',
    },
  }),
  rightWrapper: (theme: Theme) => ({
    display: 'flex',
    gap: '44px',
    alignItems: 'center',
    [theme.breakpoints.down(1920)]: {
      gap: '24px',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '20px',
      width: '100%',
      '& button': {
        height: '24px',
        width: '100%',
        maxWidth: '100%',
        justifyContent: 'flex-start',
      },
    },
  }),
};
