import { Theme } from '@mui/material';

export const styles = {
  searchChat: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 32px',
    alignItems: 'center',
    maxWidth: '864px',
    maxHeight: 'calc(527px - 44px)',
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      maxHeight: '100%',
      padding: '16px 20px',
    },
  }),
  input: (theme: Theme) => ({
    '& .MuiInputBase-root': {
      border: `1px solid ${theme.palette.divider}`,
      padding: '20px',
      background: theme.palette.common.surface['surface 2'],
      borderRadius: '16px',
      height: '64px',
      '& .MuiInputBase-input': {
        p: '16.5px 0',
      },
      '&.Mui-focused': {
        border: `1px solid ${theme.palette.divider}`,
      },
      '&:hover': {
        border: `1px solid ${theme.palette.divider}`,
        background: theme.palette.common.surface['surface 3'],
      },
    },
  }),
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
    overflowY: 'auto',
    mt: '30px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
};
