import { Theme } from '@mui/material';

export const styles = {
  infoSnackbar: (theme: Theme) => ({
    width: '100%',
    background: theme.palette.common.surface['surface 4'],
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    justifyContent: 'space-between',
    padding: '12px 24px',
    filter:
      'drop-shadow(0px 0.8px 1.5px rgba(0, 0, 0, 0.1)) drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.2))',
  }),
};
