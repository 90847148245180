import React, { FC } from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import Ratio1 from '@components/icons/Ratio1';
import Ratio2 from '@components/icons/Ratio2';
import Ratio3 from '@components/icons/Ratio3';

type RatioRadioProps = {
  isSelected: boolean;
  value: string;
  label: string;
  onChange: any;
  disabled: boolean;
  variant: 'primary' | 'outlined';
};

export const RatioRadio: FC<RatioRadioProps> = ({
  isSelected,
  onChange,
  value,
  label,
  disabled,
  variant,
}) => {
  const theme = useTheme();
  const setIcon = (width: number, height: number) => {
    if (width === height) {
      return Ratio2;
    }
    if (width > height) {
      return Ratio3;
    }
    if (width < height) {
      return Ratio1;
    }

    return Ratio1;
  };

  const handleRadioChange = () => {
    !disabled && onChange(value);
  };

  return (
    <Box
      className={isSelected ? 'selected' : ''}
      aria-checked={isSelected}
      tabIndex={isSelected ? 0 : -1}
      sx={[
        styles[variant as keyof typeof styles],
        isSelected && styles.ratioSelected,
      ]}
      onClick={handleRadioChange}>
      <input
        type={'radio'}
        value={value}
        checked={isSelected}
        onChange={handleRadioChange}
        style={{ display: 'none' }}
        aria-label={label}
      />
      {React.createElement(
        setIcon(+label.split(':')[0], +label.split(':')[1]),
        {
          sx: { fontSize: '20px' },
          htmlColor: isSelected
            ? theme.palette.common.dividerBrand
            : theme.palette.common.surface['surface 48'],
        },
      )}
      <Typography
        variant={'body1'}
        color={isSelected ? 'common.brand' : 'text.primary'}>
        {label.split(':').join(' : ')}
      </Typography>
    </Box>
  );
};

export default RatioRadio;
