import * as React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Field, FormRenderProps } from 'react-final-form';

import { Box, Button, Typography } from '@mui/material';
import { RenderChatNameField } from '@components/forms/ChatEdit/fields/RenderChatNameField';

// RENDER_FIELDS
import { schema } from './schema';
import { styles } from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import { modalActions } from '@core/Modal/state/actions';
import { useDispatch, useSelector } from 'react-redux';
import { projectsActions } from '@bus/projects/actions';
import { book } from '@routes/book';
import { savedMessagesActions } from '@bus/savedMessages/actions';
import { getProjectId } from '@bus/projects/selectors';

const ChatEdit = ({
  chatId,
  ...props
}: FormRenderProps & { chatId: number }) => {
  const { projectName } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const projectId = useSelector(getProjectId(projectName));

  const handleSubmit = () => {
    const values = { chat_id: chatId };

    return new Promise((resolve, reject) => {
      dispatch(projectsActions.deleteChat({ values, resolve, reject }));
    })
      .then(() => {
        dispatch(modalActions.closeModal('EditChat'));
        dispatch(savedMessagesActions.fetchMessagesSaved({ projectId }));
        dispatch(savedMessagesActions.fetchSavedProjects());
        navigate(book.home);
      })
      .catch((errors) => {
        return errors;
      });
  };

  const handleClick = () => {
    dispatch(
      modalActions.modal({
        component: 'ConfirmOrCancel',
        forceClose: false,
        onCancel: () => handleSubmit(),
        onConfirm: () => {
          dispatch(modalActions.closeModal('ConfirmOrCancel'));
          dispatch(modalActions.closeModal('EditStoryChat'));
        },
        modalPayload: {
          title: 'Confirm Action',
          body: 'Are you sure you want to delete this chat? You will not be able to recover it and its saved messages',
        },
        cancelButton: {
          text: 'Delete',
        },
        confirmButton: {
          text: 'Cancel',
        },
      }),
    );
  };

  return (
    <form noValidate onSubmit={props.handleSubmit}>
      <Box
        maxWidth={'450px'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}>
        <Box p={'20px'} width={'100%'}>
          <Field name={'name'} component={RenderChatNameField} />
        </Box>
        {/*FIELDS*/}
        <Box sx={styles.buttonWrapper}>
          <Button variant={'primary'} fullWidth type={'submit'} role={'submit'}>
            <Typography variant={'body1'}>Save changes</Typography>
          </Button>
          <Button
            variant={'primary'}
            color={'secondary'}
            fullWidth
            onClick={handleClick}>
            <Typography variant={'body1'}>Delete chat</Typography>
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default ChatEdit;

export { schema, ChatEdit };

/*
Paste to the component where you want to use the form

import FinalForm from '@core/FinalForm';
import { schema, ChatEdit } from '@components/forms/ChatEdit';

<FinalForm
  component={ChatEdit}
  schema={schema}
/>
* */
