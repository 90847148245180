import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import { styles } from './styles';
import { useNavigate } from 'react-router-dom';
import Logo from '@components/icons/Logo';
import { book } from '@routes/book';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(book.home);
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.title}>
        <Logo sx={{ fontSize: '100px' }} />
        <Typography variant={'inter'} color={'common.brand'}>
          404
        </Typography>
      </Box>
      <Box maxWidth={'433px'} width={'100%'}>
        <Typography variant={'h5'} color={'common.brand'} mb={'8px'}>
          Oops....
        </Typography>
        <Typography variant={'h5'} color={'common.brand'} mb={'24px'}>
          We can’t seem to find that page.
        </Typography>
        <Typography variant={'body1'} color={'common.brand'}>
          It might not exist or may have been removed.
        </Typography>
        <Typography variant={'body1'} color={'common.brand'} mb={'24px'}>
          Try heading back to our homepage.
        </Typography>
        <Button
          variant={'primary'}
          color={'sixth'}
          onClick={handleGoBack}
          fullWidth>
          <Typography variant={'body1'}>Home</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
