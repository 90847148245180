import React from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import { IconButton } from '@components/common/uiKit/IconButton';
import Close from '@components/icons/Close';
import FinalForm from '@core/FinalForm';
import StorytellerForm, { schema } from '@components/forms/StorytellerForm';
import { OnFormSubmitPayload } from '@core/FinalForm/typedefs';
import { useSelector } from 'react-redux';
import {
  getAllRatio,
  getIsStoryUploadFetching,
} from '@bus/storyTeller/selectors';
import DeleteOutline from '@components/icons/DeleteOutline';
import { IMeta } from 'react-dropzone-uploader';

type StorytellerSettingsProps = {
  text: string;
  cancelButton: {
    text: string;
  };
  confirmButton: {
    text: string;
  };
  handleRemove: () => void;
  handleClick: (payload: OnFormSubmitPayload) => void;
  meta: IMeta;
};

export const StorytellerSettings: React.FC<
  ModalTypes.ModalComponentProps<StorytellerSettingsProps>
> = ({ closeFn, handleClick, handleRemove, meta }) => {
  const theme = useTheme();
  const ratio = useSelector(getAllRatio);
  const isStoryUploading = useSelector(getIsStoryUploadFetching);

  const closeModal = () => {
    if (isStoryUploading) {
      return;
    }
    closeFn();
    handleRemove();
  };

  const handleSubmit = (payload: OnFormSubmitPayload) => {
    handleClick(payload);
  };

  return (
    <Box sx={styles.storytellerSettings}>
      <IconButton onClick={closeModal} sx={styles.closeButton}>
        <Close
          fontSize={'small'}
          htmlColor={theme.palette.common.surface['surface 47']}
        />
      </IconButton>
      <Box sx={styles.wrapper}>
        <Typography variant={'body2'} color={'text.primary'}>
          Visual Adjustment
        </Typography>
        <Typography variant={'body2'} color={'text.primary'}>
          Adjustment
        </Typography>
        <Box sx={styles.file}>
          <Box
            display={'flex'}
            alignItems={'center'}
            width={'100%'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}>
            <Typography
              width={'100%'}
              variant={'body1'}
              color={'common.brand'}
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              whiteSpace={'nowrap'}>
              {meta.name}
            </Typography>
          </Box>
          <IconButton disabled={isStoryUploading} onClick={closeModal}>
            <DeleteOutline
              fontSize={'small'}
              htmlColor={theme.palette.common.surface['surface 47']}
            />
          </IconButton>
        </Box>
        <Box>
          <FinalForm
            initialValues={{
              ratio: ratio?.[0]?.size,
              style: 0,
            }}
            keepDirtyOnReinitialize
            component={StorytellerForm}
            onSubmit={handleSubmit}
            onSubmitSuccess={closeFn}
            schema={schema}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default StorytellerSettings;
