import { Theme } from '@mui/material/styles';

export const styles = {
  root: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    p: '60px',
    width: '100%',
    gap: '48px',
    [theme.breakpoints.down('md')]: {
      p: '48px',
    },
  }),
};
