import { Theme } from '@mui/material/styles';

export const styles = {
  imageWrapStudio: (theme: Theme) => ({
    width: '100%',
    padding: '56px 80px 20px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    [theme.breakpoints.down(1920)]: {
      padding: '32px 80px 20px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '20px',
    },
  }),
  closeButton: (theme: Theme) => ({
    position: 'absolute',
    top: '32px',
    right: '32px',
    [theme.breakpoints.down('md')]: {
      top: '20px',
      right: '20px',
    },
  }),
  wrapper: (theme: Theme) => ({
    maxWidth: '1180px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    [theme.breakpoints.down(1920)]: {
      gap: '20px',
    },
    [theme.breakpoints.down('lg')]: {
      gap: '12px',
    },
    [theme.breakpoints.down('md')]: {
      gap: '16px',
    },
  }),
};
