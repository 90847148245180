import { Theme } from '@mui/material/styles';

export const styles = {
  button: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      background: theme.palette.common.surface['surface 2'],
      p: '12px',
      width: '48px',
      height: '48px',
      borderRadius: '16px',
    },
  }),
};
