import { Theme } from '@mui/material/styles';

export const styles = {
  popularDatasetItem: (theme: Theme) => ({
    borderRadius: '16px',
    background: theme.palette.common.surface['surface 1'],
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',
    width: '100%',
    height: '140px',
    overflow: 'hidden',
    '&:hover': {
      background: theme.palette.action.hover,
    },
    [theme.breakpoints.down('lg')]: {
      height: '100px',
    },
    [theme.breakpoints.down('md')]: {
      height: '84px',
      p: '12px 8px',
      gap: '8px',
    },
  }),
  image: (theme: Theme) => ({
    maxWidth: '256px',
    width: '100%',
    objectFit: 'cover',
    height: '140px',
    flexShrink: 0,
    [theme.breakpoints.down('lg')]: {
      height: '100px',
      maxWidth: '140px',
    },
    [theme.breakpoints.down('md')]: {
      height: '60px',
      maxWidth: '116px',
      borderRadius: '16px',
    },
  }),
  wrapper: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 24px 20px 0',
    width: '100%',
    gap: '8px',
    [theme.breakpoints.down('md')]: {
      p: 0,
      gap: '4px',
    },
  }),
};
