import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

type StyleCheckboxProps = {
  checked: boolean;
  value: string;
  label: string;
  onChange: any;
  disabled: boolean;
  isNotSelected: boolean;
  variant: 'primary' | 'outlined';
};

export const StyleCheckbox: FC<StyleCheckboxProps> = ({
  checked,
  onChange,
  value,
  label,
  disabled,
  isNotSelected,
  variant,
}) => {
  const handleCheckChange = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (!disabled) {
      onChange(event);
    }
  };

  return (
    <Box
      aria-checked={checked}
      tabIndex={checked ? 0 : -1}
      sx={[
        styles[variant as keyof typeof styles],
        !isNotSelected && checked && styles.ratioSelected,
      ]}
      onClick={handleCheckChange}>
      <input
        type={'checkbox'}
        value={value}
        checked={checked}
        onChange={(event) => event.stopPropagation()}
        style={{ display: 'none' }}
        aria-label={label}
      />
      <Typography
        variant={'caption'}
        color={!isNotSelected && checked ? 'common.brand' : 'text.primary'}>
        {label.split(':').join(' : ')}
      </Typography>
    </Box>
  );
};

export default StyleCheckbox;
