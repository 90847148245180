import { Theme } from '@mui/material/styles';

export const styles = {
  aiHeaderAdditionalContent: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    px: '16px',
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      top: '80px',
      width: '100%',
      px: '20px',
      zIndex: 10,
      justifyContent: 'space-between',
      flexDirection: 'row-reverse',
    },
  }),
  live: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      background: theme.palette.common.surface['surface 2'],
      borderRadius: '16px',
      p: '12px 6px',
      height: '48px',
      '& svg': {
        width: '16px',
        height: '16px',
      },
    },
  }),
};
