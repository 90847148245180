import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const FeatureImageGallery: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox={'0 0 25 24'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3983 23C18.4734 23 23.3983 18.0751 23.3983 12C23.3983 5.92487 18.4734 1 12.3983 1C6.32312 1 1.39825 5.92487 1.39825 12C1.39825 18.0751 6.32312 23 12.3983 23ZM12.3983 24C19.0257 24 24.3983 18.6274 24.3983 12C24.3983 5.37258 19.0257 0 12.3983 0C5.77084 0 0.398254 5.37258 0.398254 12C0.398254 18.6274 5.77084 24 12.3983 24Z"
      />
      <path d="M9.21068 8.5069C9.27512 8.33278 9.52139 8.33278 9.58582 8.5069L10.4467 10.8334C10.467 10.8881 10.5101 10.9313 10.5649 10.9516L12.8914 11.8124C13.0655 11.8769 13.0655 12.1231 12.8914 12.1876L10.5649 13.0484C10.5101 13.0687 10.467 13.1119 10.4467 13.1666L9.58582 15.4931C9.52139 15.6672 9.27512 15.6672 9.21068 15.4931L8.34981 13.1666C8.32955 13.1119 8.28639 13.0687 8.23164 13.0484L5.90516 12.1876C5.73103 12.1231 5.73103 11.8769 5.90516 11.8124L8.23164 10.9516C8.28639 10.9313 8.32955 10.8881 8.34981 10.8334L9.21068 8.5069Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3983 5L5.39825 5C4.29368 5 3.39825 5.89543 3.39825 7L3.39825 17C3.39825 18.1046 4.29369 19 5.39825 19H13.3983C14.5028 19 15.3983 18.1046 15.3983 17V7C15.3983 5.89543 14.5028 5 13.3983 5ZM5.39825 4C3.7414 4 2.39825 5.34315 2.39825 7L2.39825 17C2.39825 18.6569 3.7414 20 5.39825 20H13.3983C15.0551 20 16.3983 18.6569 16.3983 17V7C16.3983 5.34315 15.0551 4 13.3983 4L5.39825 4Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3983 5H7.39825V4H15.3983C17.3004 4 18.8983 5.45315 18.8983 7.3125V16.6875C18.8983 18.5469 17.3004 20 15.3983 20H7.39825V19H15.3983C16.8099 19 17.8983 17.9348 17.8983 16.6875V7.3125C17.8983 6.06525 16.8099 5 15.3983 5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3983 5H9.39825V4H17.3983C19.3004 4 20.8983 5.45315 20.8983 7.3125V16.6875C20.8983 18.5469 19.3004 20 17.3983 20H9.39825V19H17.3983C18.8099 19 19.8983 17.9348 19.8983 16.6875V7.3125C19.8983 6.06525 18.8099 5 17.3983 5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3983 5H11.3983V4H19.3983C21.3004 4 22.8983 5.45315 22.8983 7.3125V16.6875C22.8983 18.5469 21.3004 20 19.3983 20H11.3983V19H19.3983C20.8099 19 21.8983 17.9348 21.8983 16.6875V7.3125C21.8983 6.06525 20.8099 5 19.3983 5Z"
      />
    </SvgIcon>
  );
};

export default FeatureImageGallery;
