import { createSelector } from 'reselect';

import { RootState } from '@setup/typedefs';

const createdImagesSelector = (state: RootState) => state.createdImages;

export const getIsCreatedImagesFetching = createSelector(
  [createdImagesSelector],
  ({ isFetching }) => {
    return isFetching;
  },
);

export const getIsImageStudioLoading = createSelector(
  [createdImagesSelector],
  ({ isImageStudioLoading }) => {
    return isImageStudioLoading;
  },
);

export const getIsFetchingSummary = createSelector(
  [createdImagesSelector],
  ({ isFetchingSummary }) => {
    return isFetchingSummary;
  },
);

export const getCreatedImages = createSelector(
  [createdImagesSelector],
  ({ gallery }) => {
    return gallery;
  },
);

export const getFilters = createSelector(
  [createdImagesSelector],
  ({ filters }) => {
    return filters;
  },
);

export const getNextGalleryUrl = createSelector(
  [createdImagesSelector],
  ({ gallery }) => {
    return gallery?.next;
  },
);

export const getSummary = createSelector(
  [createdImagesSelector],
  ({ summary }) => {
    return summary;
  },
);

export const getSocialNetworks = createSelector(
  [createdImagesSelector],
  ({ socialNetworks }) => {
    return socialNetworks;
  },
);

export const getStudioImages = createSelector(
  [createdImagesSelector],
  ({ studioImages }) => {
    return studioImages;
  },
);

export const getWrapImages = createSelector(
  [createdImagesSelector],
  ({ wrapImages }) => {
    return wrapImages;
  },
);

export const getOldestStudioImagesId = createSelector(
  [createdImagesSelector],
  ({ studioImages }) => {
    if (studioImages?.results.length) {
      const index = studioImages.results.length - 1;

      return studioImages.results[index].images[
        studioImages.results[index].images.length - 1
      ].id;
    }

    return null;
  },
);

export const getOldestWrapImagesId = createSelector(
  [createdImagesSelector],
  ({ wrapImages }) => {
    if (wrapImages?.results.length) {
      const index = wrapImages.results.length - 1;

      return wrapImages.results[index].images[
        wrapImages.results[index].images.length - 1
      ].id;
    }

    return null;
  },
);

export const getUploadedFaces = createSelector(
  [createdImagesSelector],
  ({ imageUploadedFaces }) => {
    return imageUploadedFaces;
  },
);

export const getCreatedSwap = createSelector(
  [createdImagesSelector],
  ({ createSwapId }) => {
    return createSwapId;
  },
);

export const getFetchingUpdateImage = createSelector(
  [createdImagesSelector],
  ({ isFetchingUpdateImage }) => {
    return isFetchingUpdateImage;
  },
);
