import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const FeatureFaceSwap: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox={'0 0 25 24'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75384 1.13158C9.54494 0.699248 11.5447 0.938432 13.0681 1.95401C14.2878 2.76716 15.036 3.9432 15.478 5.05624C15.896 6.10907 16.0525 7.13579 16.0677 7.8174L18.1904 12.5935H16.0689V14.8716C16.0689 15.6343 15.6401 16.1887 15.1843 16.5488C14.7316 16.9064 14.2001 17.1182 13.8381 17.2088L13.7784 17.2237H11.7451L9.55329 22.9225L8.61995 22.5635L11.0583 16.2237H13.6516C13.9062 16.1519 14.2679 15.9984 14.5644 15.7641C14.8803 15.5146 15.0689 15.2202 15.0689 14.8716V11.5935H16.6516L15.0689 8.03239V7.92628C15.0689 7.36961 14.9411 6.4138 14.5486 5.42528C14.1571 4.4393 13.5162 3.45457 12.5134 2.78606C11.2586 1.94955 9.55429 1.72571 7.98848 2.10366C6.42582 2.48086 5.07158 3.43859 4.45294 4.8821C3.94657 6.06363 3.90092 7.46729 4.0724 8.77578C4.23689 10.0309 4.5948 11.1502 4.88024 11.8148C5.36602 12.364 5.66624 13.395 5.61577 14.6259C5.56158 15.9474 5.10463 17.5788 3.94639 19.3161L3.11434 18.7614C4.17859 17.1651 4.57049 15.7096 4.61661 14.5849C4.66406 13.4278 4.34769 12.6919 4.10283 12.447L4.03718 12.3814L3.99948 12.2966C3.67239 11.5606 3.26497 10.3104 3.08088 8.90573C2.89738 7.50554 2.92891 5.89956 3.53379 4.48818C4.30421 2.69053 5.95961 1.56467 7.75384 1.13158Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2059 1.13205C15.4409 0.698703 13.47 0.938559 11.9693 1.95623C10.7685 2.77048 10.0331 3.9471 9.599 5.05905C9.18794 6.11206 9.03433 7.1387 9.01967 7.8193L6.93345 12.5938H9.0185V14.872C9.0185 15.6311 9.43818 16.1851 9.88815 16.5467C10.3343 16.9052 10.8584 17.1177 11.2162 17.2087L11.2768 17.2241H13.2701L15.424 22.9204L16.3594 22.5667L13.9611 16.2241H11.4058C11.1573 16.1526 10.8042 15.9999 10.5145 15.7671C10.2058 15.5191 10.0185 15.2242 10.0185 14.872V11.5938H8.4617L10.0185 8.03094V7.92647C10.0185 7.3687 10.1444 6.41191 10.5305 5.42269C10.9159 4.43555 11.5462 3.45135 12.5305 2.78389C13.7611 1.94942 15.4321 1.72625 16.9675 2.10321C18.4991 2.47925 19.8297 3.43468 20.4385 4.87937C20.9369 6.06235 20.9818 7.46766 20.813 8.77707C20.6512 10.0332 20.299 11.1533 20.0182 11.8182C19.5406 12.3676 19.2462 13.397 19.2957 14.6259C19.3489 15.9457 19.7976 17.5761 20.9363 19.3134L21.7726 18.7652C20.7262 17.1686 20.3403 15.7121 20.2949 14.5856C20.2482 13.4264 20.56 12.6891 20.8006 12.4443L20.8652 12.3785L20.9022 12.294C21.2236 11.5584 21.624 10.3087 21.8048 8.90486C21.9851 7.50552 21.9541 5.9011 21.36 4.49109C20.603 2.69454 18.9747 1.56632 17.2059 1.13205Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4565 21.3093C18.9749 20.4711 20.2647 19.2735 21.2131 17.8214L22.0503 18.3682C21.0126 19.9572 19.6012 21.2676 17.9397 22.1847C16.2783 23.1019 14.4173 23.5979 12.5198 23.6292C10.6222 23.6606 8.74588 23.2264 7.05498 22.3647C5.36409 21.503 3.91016 20.24 2.82043 18.6862L3.63915 18.112C4.63503 19.532 5.96376 20.6862 7.50904 21.4737C9.05433 22.2613 10.7691 22.658 12.5032 22.6294C14.2374 22.6007 15.9381 22.1474 17.4565 21.3093Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4745 8.83442C13.4745 9.09548 13.3708 9.34585 13.1862 9.53045C13.0016 9.71505 12.7513 9.81876 12.4902 9.81876C12.2291 9.81876 11.9788 9.71505 11.7942 9.53045C11.6096 9.34585 11.5059 9.09548 11.5059 8.83442L10.6381 8.83442C10.6381 9.19169 10.7413 9.53831 10.9313 9.83442C11.0025 9.94543 11.0859 10.0493 11.1806 10.144C11.5279 10.4914 11.999 10.6865 12.4902 10.6865C12.9814 10.6865 13.4525 10.4914 13.7998 10.144C13.8945 10.0493 13.9779 9.94543 14.0491 9.83442C14.2391 9.53831 14.3423 9.19169 14.3423 8.83442H13.4745Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3211 1.00145C10.587 1.03013 8.8863 1.48338 7.3679 2.32156L6.88463 1.44609C8.54611 0.528931 10.4071 0.0329639 12.3046 0.00158739C14.2022 -0.0297889 16.0785 0.404382 17.7694 1.2661L17.3153 2.15708C15.77 1.36956 14.0553 0.972776 12.3211 1.00145Z"
      />
    </SvgIcon>
  );
};

export default FeatureFaceSwap;
