import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Box, CircularProgress } from '@mui/material';

import { getIsAuthenticate, getIsInitialize } from '@bus/auth/selectors';

import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import SuspenseLoading from '@components/common/SuspenseLoading';
import { useRoutersHook } from '@routes/useRoutersHook';
import { getIsShowProfileTooltip, getScaleImage } from '@bus/profile/selectors';
import { styles } from './styles';
import { profileActions } from '@bus/profile/actions';
import { getTooltipId } from '@bus/chat/selectors';
import { chatActions } from '@bus/chat/actions';
import { connect, disconnect } from '@giantmachines/redux-websocket';
import InfoSnackbar from '@components/common/snackbars/InfoSnackbar';
import { SnackbarProvider } from 'notistack';
import { WSPrefix } from '@setup/typedefs';
import { getAccessToken, removeQuotes } from '@REST/api';
import ScaleImage from '@components/common/ScaleImage';

const Routers = () => {
  const isInitialised = useSelector(getIsInitialize);
  const isAuthenticated = useSelector(getIsAuthenticate);
  const scaleImage = useSelector(getScaleImage);
  const tooltipId = useSelector(getTooltipId);
  const isShowProfileTooltip = useSelector(getIsShowProfileTooltip);
  const token = getAccessToken();
  const dispatch = useDispatch();

  useRoutersHook();

  const handleClose = () => {
    isShowProfileTooltip && dispatch(profileActions.closeProfileTooltip());
    dispatch(chatActions.setTooltipStatus(null));
  };

  useEffect(() => {
    if (isInitialised) {
      if (isAuthenticated && token) {
        disconnect(WSPrefix.ws);
        dispatch(
          connect(
            `${process.env.REACT_APP_WS_API_URL}/${removeQuotes(token)}`,
            WSPrefix.ws,
          ),
        );
      } else {
        disconnect(WSPrefix.ws);
        dispatch(connect(`${process.env.REACT_APP_WS_API_URL}`, WSPrefix.ws));
      }
    }
  }, [isInitialised, isAuthenticated]);

  useEffect(() => {
    dispatch(profileActions.fetchUserAlive());
  }, [isAuthenticated]);

  return isInitialised ? (
    <Box sx={styles.notificationWrapper}>
      <SnackbarProvider
        autoHideDuration={2000}
        classes={{ containerRoot: 'z-alert' }}
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        Components={{
          infoSnackbar: InfoSnackbar,
        }}>
        <BrowserRouter basename={'/'}>
          <>
            {scaleImage && <ScaleImage scaleImage={scaleImage} />}
            {tooltipId && (
              <Box
                sx={[
                  tooltipId.isBackground
                    ? styles.dark(tooltipId.isOpacityBackground)
                    : styles.withoutBg,
                ]}
                onClick={handleClose}
              />
            )}
            <Suspense fallback={<SuspenseLoading />}>
              <Routes>
                {isAuthenticated ? (
                  <Route path={`/*`} element={<PrivateRoutes />} />
                ) : (
                  <Route path={`/*`} element={<PublicRoutes />} />
                )}
              </Routes>
            </Suspense>
          </>
        </BrowserRouter>
      </SnackbarProvider>
    </Box>
  ) : (
    <Box
      width={'100vw'}
      height={'100vh'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}>
      <CircularProgress />
    </Box>
  );
};

export default Routers;
