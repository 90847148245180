/* eslint-disable prettier/prettier */
import React, { lazy } from 'react';

import layouts from '@layouts/index';
import { book } from '@routes/book';

import { AppRoute } from '@setup/typedefs';
const TermsOfUse = lazy(()=> import('@pages/common/TermsOfUse'));
const ConversationPage = lazy(()=>import('@pages/common/ConversationPage'));

const MaintenancePage = lazy(()=>import('@pages/common/MaintenancePage'));const RedirectPage = lazy(()=>import('@pages/common/RedirectPage'));// IMPORTS
export const commonRoutes: AppRoute<any>[] = [
  // inject
  {
    path: book.terms,
    Component: <TermsOfUse />,
    Layout: layouts.CommonLayout,
    layoutProps: {},
  },
  {
    path: `${book.conversation}/:year/:month/:sessionId`,
    Component: <ConversationPage />,
    Layout: layouts.ConversationLayout,
    layoutProps: {},
  },
  {
    path: `${book.maintenance}`,
    Component: <MaintenancePage />,
    Layout: layouts.NotFoundLayout,
    layoutProps: {},
  },
  {
    path: `${book.redirect}/:user_id/:reset_token`,
    Component: <RedirectPage />,
    Layout: layouts.NotFoundLayout,
    layoutProps: {},
  },// INJECT
  // {
  //   path: '/*',
  //   Component: <>404</>,
  //   Layout: layouts.CommonLayout,
  //   layoutProps: {},
  // },
];