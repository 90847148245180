import React from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Box, TextField } from '@mui/material';

import { getFieldError } from '@core/FinalForm/getFieldError';
import InputLabel from '@components/common/InputLabel';

export const RenderChatNameField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  return (
    <Box>
      <InputLabel label={'Title'} />
      <TextField
        inputProps={{
          'data-testid': `chat-edit-chat-name`,
        }}
        required
        fullWidth
        placeholder={'Type your title'}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)}
        {...input}
      />
    </Box>
  );
};
