// @ts-nocheck
import { put } from 'redux-saga/effects';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { IResponseUsers, WSEvent } from '@bus/ws/typedefs';
import { authActions } from '@bus/auth/actions';
import { createdImagesActions } from '@bus/createdImages/actions';
import { profileActions } from '@bus/profile/actions';
import { projectsActions } from '@bus/projects/actions';
import { book } from '@routes/book';
import { optionsActions } from '@bus/options/actions';

export function* handleResponseUsers(payload: IResponseUsers) {
  try {
    if (payload.event === WSEvent.delete_account) {
      yield put(authActions.clearData());
      yield put(createdImagesActions.clearData());
      yield put(profileActions.clearData());
      yield put(projectsActions.clearProjects());
      yield put(optionsActions.clearOptions());
      yield put(authActions.isAuthenticatedFalse());
      sessionStorage.removeItem('redirectTo');
      window.location.href = book.signIn;
      localStorage.setItem('snackbar_message', 'The Account has been deleted');
    }
  } catch (e) {
    yield handleError(e);
  }
}
