import React, { useEffect } from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import FinalForm from '@core/FinalForm';
import {
  schema,
  VisualAdjustmentsForm,
} from '@components/forms/VisualAdjustmentsForm';
import { useDispatch, useSelector } from 'react-redux';
import { projectsActions } from '@bus/projects/actions';
import { getVisualizePrompt } from '@bus/projects/selectors';
import { getOptions } from '@bus/options/selectors';
import { OnFormSubmitPayload } from '@core/FinalForm/typedefs';
import {
  getCanDoVisualizeAction,
  getIsVisualAdjustments,
  getShowVisualizeBuyTokensModal,
  getShowVisualizeUpdatePlanToUseTokensModal,
} from '@bus/profile/selectors';
import { profileActions } from '@bus/profile/actions';
import { useTokensDisclaimer } from '../../../hooks/useTokensDisclaimer';
import { wsActions } from '@bus/ws/actions';
import Close from '@components/icons/Close';
import { IconButton } from '@components/common/uiKit/IconButton';
import { useLimits } from '../../../hooks/useLimits';

type VisualAdjustmentsProps = {
  messageId: number;
  limit: number;
  projectId: number;
  chatId: number;
};

export const VisualAdjustments: React.FC<
  ModalTypes.ModalComponentProps<VisualAdjustmentsProps>
> = ({ closeFn, messageId, chatId, projectId }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const visualizePrompt = useSelector(getVisualizePrompt);
  const ratio = useSelector(getOptions('ratio'));
  const isVisualAdjustments = useSelector(getIsVisualAdjustments);
  const { handle } = useTokensDisclaimer('visualizations');

  const canDoAction = useSelector(getCanDoVisualizeAction);
  const showBuyTokensModal = useSelector(getShowVisualizeBuyTokensModal);
  const showUpdatePlanToUseTokensModal = useSelector(
    getShowVisualizeUpdatePlanToUseTokensModal,
  );

  const { handleLimitExceeded } = useLimits(
    showUpdatePlanToUseTokensModal,
    showBuyTokensModal,
    'text_line_2',
  );
  const closeModal = () => {
    closeFn();
    dispatch(projectsActions.clearVisualizePrompt());
  };

  useEffect(() => {
    dispatch(projectsActions.fetchVisualizePrompt({ messageId }));
  }, []);

  const handleSubmit = (payload: OnFormSubmitPayload) => {
    closeModal();
    if (isVisualAdjustments !== payload.values.magic) {
      dispatch(profileActions.showProfileTooltip());
    }

    if (!canDoAction) {
      handleLimitExceeded();

      return;
    }

    handle();
    dispatch(
      profileActions.updateProfile({
        ...payload,
        values: { visual_adjustments: payload.values.magic },
      }),
    );
    dispatch(
      wsActions.sendMessageRequest({
        values: {
          action: 'request',
          app: 'chat',
          event: 'visualize',
          data: {
            project_id: projectId,
            chat_id: chatId,
            message_id: messageId,
            parameters: [payload.values.ratio, payload.values.style].filter(
              (item) => item !== 0,
            ),
            user_midjourney_prompt: payload.values.prompt,
          },
        },
      }),
    );
  };

  return (
    <Box sx={styles.visualAdjustments}>
      <IconButton onClick={closeModal} sx={styles.closeButton}>
        <Close
          fontSize={'small'}
          htmlColor={theme.palette.common.surface['surface 47']}
        />
      </IconButton>
      <Box sx={styles.wrapper}>
        <Typography variant={'body2'} color={'text.primary'}>
          Visual Adjustment
        </Typography>
        <FinalForm
          initialValues={{
            prompt:
              visualizePrompt && visualizePrompt.messageId === messageId
                ? visualizePrompt.prompt
                : '',
            ratio: ratio[0].id,
            style: 0,
            magic: isVisualAdjustments,
          }}
          keepDirtyOnReinitialize
          component={VisualAdjustmentsForm}
          extraProps={{
            isCurrentPrompt: visualizePrompt?.messageId === messageId,
          }}
          onSubmit={handleSubmit}
          onSubmitSuccess={closeModal}
          schema={schema}
        />
      </Box>
    </Box>
  );
};

export default VisualAdjustments;
