import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

import { styles } from './styles';
import { useCurrentDataset } from '../../hooks/useCurrentDataset';
import { Modals } from '@core/Modal';
import { registeredModals } from '@components/modals/registeredModals';
import SuspenseLoading from '@components/common/SuspenseLoading';
import AppBar from '@components/common/AppBar';

export type MetametricsLayoutProps = {};

const MetametricsLayout: React.FC<MetametricsLayoutProps> = () => {
  useCurrentDataset();

  return (
    <Box sx={styles.root}>
      <AppBar />
      <Modals registeredModals={registeredModals} />
      <Suspense fallback={<SuspenseLoading />}>
        <Box sx={styles.wrapper}>
          <Outlet />
        </Box>
      </Suspense>
    </Box>
  );
};

export default MetametricsLayout;
