import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Regenerate: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.596 6.74595C20.9516 6.53348 21.4121 6.64948 21.6245 7.00505C22.5266 8.51469 23.0023 10.2408 23.0007 11.9994C23.0007 11.9992 23.0007 11.9996 23.0007 11.9994C23.0004 17.5263 18.3904 21.9688 12.7507 21.9688C11.7097 21.9688 10.7029 21.8171 9.75447 21.5358C9.7123 21.5233 9.67107 21.511 9.63176 21.4994C9.42969 21.1211 9.30735 20.889 9.24365 20.7309C9.2234 20.6806 9.21817 20.6591 9.21798 20.6591C9.21785 20.6592 9.21965 20.6674 9.22101 20.6823C9.22563 20.7326 9.22631 20.9003 9.0934 21.0627C9.09234 21.064 9.09127 21.0653 9.09019 21.0665C8.94754 21.2375 8.77384 21.2615 8.73902 21.2646C8.73024 21.2654 8.72536 21.2654 8.72536 21.2655C8.72535 21.2658 8.75266 21.2667 8.82019 21.2796C8.99276 21.3124 9.24424 21.3845 9.63176 21.4994C9.63686 21.5089 9.64201 21.5185 9.64721 21.5283L10.2451 22.646C10.4404 23.0113 10.3027 23.4657 9.93747 23.6611C9.57223 23.8565 9.11776 23.7188 8.9224 23.3535L8.30432 22.1979C8.11948 21.8521 7.95194 21.5387 7.85232 21.2914C7.80084 21.1636 7.74364 20.9977 7.72727 20.8192C7.70985 20.6291 7.73403 20.3525 7.93566 20.1089C7.93513 20.1096 7.9346 20.1102 7.93406 20.1109L8.51373 20.5868L7.93728 20.107C7.93674 20.1076 7.9362 20.1083 7.93566 20.1089C8.14524 19.855 8.42521 19.7865 8.60727 19.7704C8.78427 19.7548 8.95958 19.7792 9.10028 19.806C9.37669 19.8585 9.7399 19.9665 10.1502 20.0886L10.181 20.0977C10.9925 20.3383 11.856 20.4688 12.7507 20.4688C17.6048 20.4688 21.5007 16.6556 21.5007 11.9988C21.502 10.5115 21.0998 9.05115 20.3369 7.77448C20.1245 7.41891 20.2405 6.95842 20.596 6.74595Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.15496 17.0041C3.79939 17.2165 3.3389 17.1005 3.12643 16.745C2.22433 15.2353 1.74871 13.5092 1.75024 11.7506C1.75024 11.7508 1.75024 11.7504 1.75024 11.7506C1.75061 6.2237 6.36054 1.78124 12.0002 1.78124C13.0413 1.78124 14.0481 1.93291 14.9965 2.2142C15.0387 2.22674 15.0799 2.23899 15.1192 2.25065C15.3213 2.62891 15.4436 2.86101 15.5073 3.01912C15.5276 3.06939 15.5328 3.0909 15.533 3.09085C15.5331 3.09083 15.5313 3.08255 15.53 3.06774C15.5253 3.01736 15.5247 2.84968 15.6576 2.68734C15.6586 2.68604 15.6597 2.68475 15.6608 2.68345C15.8034 2.51249 15.9771 2.48846 16.012 2.48539C16.0207 2.48461 16.0256 2.48459 16.0256 2.48446C16.0256 2.48416 15.9983 2.48326 15.9308 2.47043C15.7582 2.43763 15.5067 2.36555 15.1192 2.25065C15.1141 2.2411 15.109 2.23146 15.1038 2.22172L14.5059 1.10398C14.3105 0.738728 14.4483 0.284262 14.8135 0.0888979C15.1787 -0.106466 15.6332 0.0312501 15.8286 0.396498L16.4467 1.55205C16.6315 1.89788 16.799 2.21131 16.8987 2.4586C16.9501 2.58637 17.0073 2.75228 17.0237 2.93082C17.0411 3.12088 17.0169 3.3975 16.8153 3.64108C16.8158 3.64044 16.8164 3.63979 16.8169 3.63914L16.2372 3.16324L16.8137 3.64302C16.8142 3.64238 16.8148 3.64173 16.8153 3.64108C16.6057 3.89497 16.3258 3.96354 16.1437 3.97959C15.9667 3.99519 15.7914 3.97079 15.6507 3.94405C15.3743 3.89151 15.0111 3.78348 14.6008 3.66144L14.57 3.65228C13.7585 3.41166 12.895 3.28124 12.0002 3.28124C7.14616 3.28124 3.25024 7.09443 3.25024 11.7512C3.24895 13.2385 3.65117 14.6989 4.41405 15.9755C4.62653 16.3311 4.51052 16.7916 4.15496 17.0041Z"
      />
      <path d="M14.25 3L15.75 2L16.25 3.5L14.25 3Z" />
      <path d="M7.75 21L9.25 20L9.75 21.5L7.75 21Z" />
    </SvgIcon>
  );
};

export default Regenerate;
