import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';

import { getOptions } from '@bus/options/selectors';
import { RadioGroup } from '@components/common/inputs/RadioGroup';
import { getFieldError } from '@core/FinalForm/getFieldError';
import { styles } from '../styles';
import RatioRadio from '@components/common/uiKit/RatioRadio';

export const RenderRatioField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  const options = useSelector(getOptions('ratio'));

  return (
    <Box sx={styles.radioGroup} display={'flex'} flexDirection={'column'}>
      <Typography variant={'body2'} color={'text.primary'} mb={'16px'}>
        Ratio
      </Typography>
      <RadioGroup
        {...input}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)?.id}
        data-testid={`visual-adjustments-form-ratio`}
        values={options}
        control={RatioRadio}
        controlVariant={'outlined'}
        selectedKey={'id'}
      />
    </Box>
  );
};
