import * as yup from 'yup';

export const schema = yup.object().shape({
  prompt: yup.string().required('Prompt is a required field'),
  style: yup.number(),
  // assistant: yup.number(),
  ratio: yup.number(),
  wrap_face: yup.string(),
  // INJECT
});
