import React, { useRef, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { Box, Typography } from '@mui/material';
import { styles } from '../styles';
import { getFieldError } from '@core/FinalForm/getFieldError';
import { RadioGroup } from '@components/common/inputs/RadioGroup';
import ImageRadio from '@components/common/uiKit/ImageRadio';
import { useDispatch, useSelector } from 'react-redux';
import { getCreatedSwap, getUploadedFaces } from '@bus/createdImages/selectors';
import { createdImagesActions } from '@bus/createdImages/actions';
import { updateSwapImageImage } from '@helpers/updateSwapImageImage';

export const RenderWrapFaceField: React.FC<FieldRenderProps<string>> = ({
  input: { onChange, value, ...input },
  meta,
}) => {
  const createdSwap = useSelector(getCreatedSwap);
  const uploadedFaces = useSelector(getUploadedFaces);
  const dispatch = useDispatch();
  const [lastSelectedValue, setLastSelectedValue] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleChangeRadio = (data: any) => {
    if (createdSwap?.id && data !== lastSelectedValue) {
      onChange(data);
      setLastSelectedValue(data);
      updateSwapImageImage(dispatch, data, createdSwap.id, 'swap_image');
    }
  };

  const handleRemove = (event: any) => {
    event.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    if (createdSwap?.id) {
      updateSwapImageImage(
        dispatch,
        'remove',
        createdSwap.id,
        'swap_image',
      ).then(() => {
        onChange('');
        setLastSelectedValue('');
      });
    }
  };

  const handleDeleteFace = (event: any, id: number) => {
    event.preventDefault();
    event.stopPropagation();
    new Promise((resolve, reject) => {
      dispatch(
        createdImagesActions.deleteImageStudioFaces({
          values: { id },
          resolve,
          reject,
        }),
      );
    })
      .then(() => {
        handleRemove(event);
      })
      .catch();
  };

  return (
    <Box flex={1}>
      <Typography
        variant={'body2'}
        color={'text.primary'}
        sx={(theme) => styles.facesTitle(theme, !!uploadedFaces.length)}>
        Previous
      </Typography>
      <Box sx={styles.radioWrapper}>
        <RadioGroup
          {...input}
          value={value}
          onChange={handleChangeRadio}
          error={!!getFieldError(meta)}
          helperText={getFieldError(meta)?.id}
          data-testid={`generate-image-studio-ratio`}
          values={uploadedFaces}
          control={ImageRadio}
          selectedKey={'image'}
          handleAction={handleDeleteFace}
        />
      </Box>
    </Box>
  );
};
