import React from 'react';

import { Box, Button, Typography, useTheme } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import ErrorIcon from '@components/icons/Error';

type ServerErrorProps = {
  text: string;
  cancelButton: {
    text: string;
  };
  confirmButton: {
    text: string;
  };
};

export const ServerError: React.FC<
  ModalTypes.ModalComponentProps<ServerErrorProps>
> = ({ closeFn }) => {
  const theme = useTheme();
  const closeModal = () => {
    closeFn();
    window.location.reload();
  };

  return (
    <Box sx={styles.serverError}>
      <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
        <Box display={'flex'} alignItems={'center'} gap={'20px'}>
          <ErrorIcon fontSize={'medium'} htmlColor={theme.palette.error.main} />
          <Typography variant={'h5'} color={'text.primary'}>
            Error
          </Typography>
        </Box>
        <Typography variant={'body1'} color={'text.primary'}>
          Oops. Ethernet Server Error
        </Typography>
      </Box>
      <Box alignSelf={'flex-end'}>
        <Button
          onClick={closeModal}
          variant={'primary'}
          color={'sixth'}
          fullWidth>
          <Typography variant={'body1'}>Reload</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default ServerError;
