import React, { FC } from 'react';

import { Button, Typography, useTheme } from '@mui/material';

import Plus from '@components/icons/Plus';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getCanDoAction,
  getCurrentDataset,
  getProfile,
  getShowBuyTokensModal,
  getShowUpdatePlanToUseTokensModal,
} from '@bus/profile/selectors';
import { getEmptyChat } from '@bus/projects/selectors';
import { useLimits } from '../../../hooks/useLimits';
import { book } from '@routes/book';
import { projectsActions } from '@bus/projects/actions';
import { modalActions } from '@core/Modal/state/actions';

type CreateNewChatProps = {};

export const CreateNewChat: FC<CreateNewChatProps> = () => {
  const theme = useTheme();
  const currentDataset = useSelector(getCurrentDataset);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(getProfile);
  const emptyChat = useSelector(getEmptyChat(currentDataset?.name ?? ''));
  const canDoAction = useSelector(getCanDoAction);
  const showBuyTokensModal = useSelector(getShowBuyTokensModal);
  const showUpdatePlanToUseTokensModal = useSelector(
    getShowUpdatePlanToUseTokensModal,
  );
  const { handleLimitExceeded } = useLimits(
    showUpdatePlanToUseTokensModal,
    showBuyTokensModal,
  );

  const handleClick = () => {
    dispatch(modalActions.closeModal('MasterMenu'));
  };

  const handleCreateNewChat = () => {
    handleClick();
    if (!profile?.subscription) {
      navigate(book.forUnsubscribedUsers);

      return;
    }
    if (emptyChat) {
      navigate(`${book.chat}/${currentDataset?.name}/${emptyChat.id}`);

      return;
    }

    if (!canDoAction) {
      handleLimitExceeded();

      return;
    }

    new Promise((resolve, reject) => {
      dispatch(
        projectsActions.createNewChat({
          values: {
            project_id: currentDataset?.id,
            projectName: currentDataset?.name,
            name: 'New Chat',
          },
          resolve,
          reject,
          navigate,
        }),
      );
    })
      .then((data: any) => {
        navigate(`${book.chat}/${currentDataset?.name}/${data.id}`);
      })
      .catch();
  };

  return (
    <Button
      variant={'secondary'}
      color={'secondary'}
      onClick={handleCreateNewChat}
      startIcon={
        <Plus
          sx={{ fontSize: '20px' }}
          htmlColor={theme.palette.common.surface['surface 47']}
        />
      }>
      <Typography variant={'body1'} color={'text.secondary'}>
        New Chat
      </Typography>
    </Button>
  );
};

export default CreateNewChat;
