import { Theme } from '@mui/material/styles';

export const styles = {
  item: (theme: Theme) => ({
    '& .MuiButtonBase-root': {
      padding: '12px 20px',
      height: '48px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '16px',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        background: theme.palette.common.surface['surface 2'],
      },
    },
  }),
  activeItem: (theme: Theme) => ({
    background: theme.palette.common.action['hover 3'],
  }),
};
