import React, { FC } from 'react';

import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ModalProps,
  Typography,
  useTheme,
} from '@mui/material';

import { styles } from './styles';
import DrawerHeader from '@components/common/DrawerHeader';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCanDoStoryAction,
  getProfile,
  getShowBuyTokensStoryModal,
  getShowStoryUpdatePlanToUseTokensModal,
} from '@bus/profile/selectors';
import History from '@components/icons/History';
import { useNavigate, useParams } from 'react-router-dom';
import { getStories } from '@bus/storyTeller/selectors';
import { book } from '@routes/book';
import { modalActions } from '@core/Modal/state/actions';
import { storyTellerActions } from '@bus/storyTeller/actions';
import Plus from '@components/icons/Plus';
import { IconButton } from '@components/common/uiKit/IconButton';
import Edit from '@components/icons/Edit';
import { useLimits } from '../../../hooks/useLimits';

type StoryGuidanceDrawerProps = {
  onClose?: ModalProps['onClose'];
};

export const StoryGuidanceDrawer: FC<StoryGuidanceDrawerProps> = ({
  onClose,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stories = useSelector(getStories);
  const profile = useSelector(getProfile);
  const { storyId } = useParams();
  const canDoAction = useSelector(getCanDoStoryAction);
  const showBuyTokensModal = useSelector(getShowBuyTokensStoryModal);
  const showUpdatePlanToUseTokensModal = useSelector(
    getShowStoryUpdatePlanToUseTokensModal,
  );

  const { handleLimitExceeded } = useLimits(
    showUpdatePlanToUseTokensModal,
    showBuyTokensModal,
    'text_line_3',
  );

  const handleClick = (
    event: React.KeyboardEvent | React.MouseEvent,
    storyId: number,
  ) => {
    navigate(`${book.storyTeller}/${storyId}`);
    onClose?.(event, 'backdropClick');
  };

  const handleAction = (storyId: number, projectName: string) => {
    dispatch(
      modalActions.modal({
        component: 'EditStoryChat',
        title: 'Story Edits',
        forceClose: true,
        storyId,
        projectName,
      }),
    );
  };

  const handleCreateNewStory = () => {
    if (!profile?.subscription) {
      navigate(book.forUnsubscribedUsers);

      return;
    }
    if (!canDoAction) {
      handleLimitExceeded();

      return;
    }

    new Promise((resolve, reject) => {
      dispatch(
        storyTellerActions.createStoryChat({
          values: {
            project: stories?.id,
            name: 'New story',
          },
          resolve,
          reject,
          navigate,
        }),
      );
    })
      .then()
      .catch();
  };

  return (
    <Box sx={styles.chatHistoryDrawer}>
      <DrawerHeader
        onClose={onClose}
        name={'History'}
        icon={
          <History
            sx={{ fontSize: '16px' }}
            htmlColor={theme.palette.common.surface['surface 47']}
          />
        }
      />
      <Button
        variant={'drawerCreateChat'}
        startIcon={
          <Plus
            sx={{ fontSize: '20px' }}
            htmlColor={theme.palette.common.surface['surface 46']}
          />
        }
        onClick={handleCreateNewStory}>
        <Typography variant={'body1'} color={'common.brand'}>
          Generate new illustrated story
        </Typography>
      </Button>
      <List sx={styles.list}>
        {stories?.chats.map((story) => (
          <ListItem key={story.id} disablePadding sx={styles.item}>
            <ListItemButton
              onClick={(event) => handleClick(event, story.id)}
              sx={[storyId === story.id.toString() && styles.activeItem]}>
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Typography
                  variant={'body1'}
                  maxWidth={'225px'}
                  color={'text.primary'}
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                  whiteSpace={'nowrap'}>
                  {story.name}
                </Typography>
              </Box>
              {storyId === story.id.toString() && (
                <IconButton
                  onClick={() => handleAction(story.id, story.name)}
                  sx={styles.button}>
                  <Edit
                    fontSize={'small'}
                    htmlColor={theme.palette.common.surface['surface 46']}
                  />
                </IconButton>
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default StoryGuidanceDrawer;
