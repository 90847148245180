import { Theme } from '@mui/material';

export const styles = {
  scaleImage: {
    width: '100svw',
    height: '100svh',
    position: 'absolute',
  },
  wrapper: (theme: Theme) => ({
    position: 'relative',
    width: '100svw',
    height: '100svh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      py: '52px',
    },
  }),
  imageWrapper: (theme: Theme, aspectRatio: number) => ({
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    aspectRatio,
    maxWidth: 'calc(100svw - 66px)',
    maxHeight: 'calc(100svh - 66px)',
    zIndex: 10003,
    background: theme.palette.primary.contrastText,
    boxShadow:
      '0px 0.4981061816215515px 3.154672384262085px 0px rgba(30, 6, 39, 0.02), 0px 1.1970183849334717px 7.581116199493408px 0px rgba(30, 6, 39, 0.03), 0px 2.2538793087005615px 14.274569511413574px 0px rgba(30, 6, 39, 0.04), 0px 4.020535469055176px 25.46339225769043px 0px rgba(30, 6, 39, 0.05), 0px 7.519969463348389px 47.6264762878418px 0px rgba(30, 6, 39, 0.06), 0px 18px 114px 0px rgba(30, 6, 39, 0.08)',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100svw',
      maxHeight: '100svh',
    },
  }),
  overlay: (theme: Theme) => ({
    display: 'flex',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    transition: 'all 0.3s ease-in-out',
    background: theme.palette.common.surface['surface 45'],
    zIndex: 10002,
  }),
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 10003,
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.4)',
    },
  },
  deleteButton: (theme: Theme) => ({
    position: 'absolute',
    bottom: '16px',
    right: '16px',
    borderRadius: '50%',
    background: theme.palette.common.surface['surface 45'],
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.4)',
    },
    [theme.breakpoints.down('md')]: {
      bottom: '8px',
      right: '8px',
    },
  }),
  image: {
    objectFit: 'cover',
  },
};
