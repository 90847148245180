import React, { FC } from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import { useSelector } from 'react-redux';
import { getAmountOfUsers } from '@bus/profile/selectors';
import { getAllAudios, getIsLive } from '@bus/aiConversation/selectors';
import Visibility from '@components/icons/Visibility';
import AudioPlayer from '@components/pages/common/ConversationPage/AudioPlayer';
import { useParams } from 'react-router-dom';

type AiHeaderAdditionalContentProps = {};

export const AiHeaderAdditionalContent: FC<
  AiHeaderAdditionalContentProps
> = () => {
  const theme = useTheme();
  const amountOfUsers = useSelector(getAmountOfUsers);
  const isLive = useSelector(getIsLive);
  const { year, month, sessionId } = useParams();
  const audios = useSelector(getAllAudios(year, month, sessionId));

  return (
    <Box sx={styles.aiHeaderAdditionalContent}>
      <AudioPlayer audioGroups={audios} />
      {isLive && (
        <Box sx={styles.live}>
          <Box display={'flex'} alignItems={'center'}>
            <Visibility
              fontSize={'small'}
              htmlColor={theme.palette.common.surface['surface 47']}
            />
            <Typography variant={'body1'} ml={'4px'} color={'text.secondary'}>
              {amountOfUsers.toLocaleString('en-US')}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AiHeaderAdditionalContent;
