import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const FromFace: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox={'0 0 42 42'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7.95652C0 3.56226 3.56226 0 7.95652 0V2C4.66683 2 2 4.66683 2 7.95652H0ZM40 7.95652C40 4.66683 37.3332 2 34.0435 2V0C38.4377 0 42 3.56226 42 7.95652H40ZM2 34.0435C2 37.3332 4.66683 40 7.95652 40V42C3.56226 42 0 38.4377 0 34.0435H2ZM34.0435 40C37.3332 40 40 37.3332 40 34.0435H42C42 38.4377 38.4377 42 34.0435 42V40Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.624 28.5837C17.3941 28.2395 16.9285 28.1463 16.5841 28.376C16.2394 28.6057 16.1463 29.0714 16.376 29.416L17.0001 29C16.376 29.416 16.3759 29.4159 16.376 29.416L16.3772 29.4177L16.379 29.4205L16.3849 29.4292L16.4054 29.4592C16.4228 29.4844 16.4477 29.5202 16.4796 29.5648C16.5434 29.6541 16.6354 29.7796 16.7518 29.9292C16.9836 30.2272 17.3168 30.6273 17.7198 31.0303C18.1203 31.4309 18.6062 31.8508 19.1455 32.1744C19.6807 32.4955 20.3157 32.75 21.0001 32.75C21.4143 32.75 21.7501 32.4142 21.7501 32C21.7501 31.5858 21.4143 31.25 21.0001 31.25C20.6845 31.25 20.3195 31.1295 19.9172 30.8881C19.5189 30.6492 19.1298 30.3191 18.7804 29.9697C18.4334 29.6226 18.1415 29.2727 17.9358 29.0083C17.8335 28.8767 17.7536 28.7678 17.7002 28.693C17.6735 28.6556 17.6535 28.6269 17.6407 28.6082L17.6268 28.588L17.624 28.5837C17.624 28.5837 17.6239 28.5837 17.624 28.5837Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.424 28.5196C25.6892 28.2017 26.162 28.1587 26.4801 28.4239C26.7983 28.689 26.8413 29.1619 26.5762 29.4802L26 29C26.5762 29.4802 26.5763 29.48 26.5762 29.4802L26.5748 29.4818L26.5725 29.4846L26.5651 29.4933L26.5396 29.5231C26.5179 29.5483 26.4869 29.5839 26.4471 29.6284C26.3677 29.7175 26.253 29.8425 26.1081 29.9916C25.8191 30.2888 25.4052 30.6863 24.906 31.0857C23.9408 31.8578 22.5185 32.75 21 32.75C20.5858 32.75 20.25 32.4142 20.25 32C20.25 31.5858 20.5858 31.25 21 31.25C21.9815 31.25 23.0592 30.6422 23.969 29.9144C24.4073 29.5637 24.7747 29.2112 25.0326 28.946C25.1611 28.8138 25.2612 28.7045 25.3282 28.6294C25.3617 28.5919 25.3868 28.5631 25.403 28.5443L25.4204 28.524L25.424 28.5196Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.25 10C20.25 9.58579 20.5858 9.25 21 9.25C23.1345 9.25 24.9061 9.82367 26.0992 11.4082C27.2502 12.9369 27.75 15.2685 27.75 18.5C27.75 21.3524 26.6743 23.6467 25.3199 25.2298C24.644 26.0197 23.8925 26.6401 23.1584 27.0672C22.4366 27.4872 21.6796 27.75 21 27.75C20.5858 27.75 20.25 27.4142 20.25 27C20.25 26.5858 20.5858 26.25 21 26.25C21.3204 26.25 21.8134 26.1144 22.4041 25.7707C22.9825 25.4341 23.606 24.9257 24.1801 24.2546C25.3257 22.9158 26.25 20.9601 26.25 18.5C26.25 15.3565 25.7498 13.4381 24.9008 12.3105C24.0939 11.2388 22.8655 10.75 21 10.75C20.5858 10.75 20.25 10.4142 20.25 10Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9008 11.4082C17.0939 9.82367 18.8655 9.25 21 9.25C21.4142 9.25 21.75 9.58579 21.75 10C21.75 10.4142 21.4142 10.75 21 10.75C19.1345 10.75 17.9061 11.2388 17.0992 12.3105C16.2502 13.4381 15.75 15.3565 15.75 18.5C15.75 20.9601 16.6743 22.9158 17.8199 24.2546C18.394 24.9257 19.0175 25.4341 19.5959 25.7707C20.1866 26.1144 20.6796 26.25 21 26.25C21.4142 26.25 21.75 26.5858 21.75 27C21.75 27.4142 21.4142 27.75 21 27.75C20.3204 27.75 19.5634 27.4872 18.8416 27.0672C18.1075 26.6401 17.356 26.0197 16.6801 25.2298C15.3257 23.6467 14.25 21.3524 14.25 18.5C14.25 15.2685 14.7498 12.9369 15.9008 11.4082Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7628 25.2887C25.1557 25.1577 25.5805 25.3701 25.7115 25.763L26.7115 28.763C26.8425 29.156 26.6301 29.5807 26.2371 29.7117C25.8442 29.8427 25.4194 29.6303 25.2884 29.2374L24.2884 26.2374C24.1575 25.8444 24.3698 25.4197 24.7628 25.2887Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2371 25.2887C18.6301 25.4197 18.8425 25.8444 18.7115 26.2374L17.7115 29.2374C17.5805 29.6303 17.1557 29.8427 16.7628 29.7117C16.3698 29.5807 16.1575 29.156 16.2884 28.763L17.2884 25.763C17.4194 25.3701 17.8442 25.1577 18.2371 25.2887Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5678 33.7469C28.7348 34.1863 26.2401 30.9768 25.3442 29.3642L26.6555 28.6357C27.4263 30.0232 29.4649 32.6137 33.432 32.2531C33.8445 32.2156 34.2093 32.5196 34.2468 32.9321C34.2843 33.3446 33.9803 33.7094 33.5678 33.7469Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.94005 34.1091C13.9707 34.5483 16.5675 31.3401 17.5 29.7282L16.1351 29C15.3328 30.3869 13.2108 32.9764 9.08141 32.6159C8.65202 32.5784 8.27229 32.8823 8.23326 33.2946C8.19422 33.707 8.51066 34.0716 8.94005 34.1091Z"
      />
    </SvgIcon>
  );
};

export default FromFace;
