import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Box, useMediaQuery, useTheme } from '@mui/material';

import { styles } from './styles';
import SuspenseLoading from '@components/common/SuspenseLoading';
import AppBar from '@components/common/AppBar';
import { Modals } from '@core/Modal';
import { registeredModals } from '@components/modals/registeredModals';
import { getIsAuthenticate } from '@bus/auth/selectors';
import { useSelector } from 'react-redux';
import { useCurrentDataset } from '../../hooks/useCurrentDataset';
import History from '@components/icons/History';
import ConversationHistoryDrawer from '@components/drawers/ConversationHistoryDrawer';
import AiHeaderAdditionalContent from '@components/common/AiHeaderAdditionalContent';

export type ConversationLayoutProps = {};

const ConversationLayout: React.FC<ConversationLayoutProps> = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isAuth = useSelector(getIsAuthenticate);
  useCurrentDataset();

  return (
    <Box sx={styles.root}>
      <AppBar
        authorized={isAuth}
        buttonProps={{
          icon: (
            <History
              sx={{ fontSize: '20px' }}
              htmlColor={theme.palette.common.surface['surface 47']}
            />
          ),
          onClick: () => {},
          label: 'History',
        }}
        drawerContent={ConversationHistoryDrawer}
        rightAdditionalContent={AiHeaderAdditionalContent}
      />
      {isMd && <AiHeaderAdditionalContent />}
      <Modals registeredModals={registeredModals} />
      <Suspense fallback={<SuspenseLoading />}>
        <Box sx={styles.wrapper}>
          <Outlet />
        </Box>
      </Suspense>
    </Box>
  );
};

export default ConversationLayout;
