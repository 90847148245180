import React, { useEffect, useState } from 'react';

import { Box, ModalProps, Skeleton, TextField } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions } from '@bus/chat/actions';
import { getRecentChats } from '@bus/chat/selectors';
import { useNavigate } from 'react-router-dom';
import { book } from '@routes/book';
import SearchChatList from '@components/common/SearchChatList';
import { v4 as uuidv4 } from 'uuid';
import { IFoundedChatActionPayload } from '@bus/chat/typedefs';

type SearchChatProps = {
  text: string;
  onClose?: ModalProps['onClose'];
};

export const SearchChat: React.FC<
  ModalTypes.ModalComponentProps<SearchChatProps>
> = ({ closeFn, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const [date, setDate] = useState<IFoundedChatActionPayload[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const recentChats = useSelector(getRecentChats);

  useEffect(() => {
    setDate(recentChats);
  }, [recentChats]);

  const closeModal = () => {
    closeFn();
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setValue(query);

    if (query.length < 3) {
      dispatch(chatActions.clearFoundedChat());
      setDate(recentChats);

      return;
    }

    setIsLoading(true);
    try {
      const data = await new Promise<IFoundedChatActionPayload[]>(
        (resolve: any, reject) => {
          dispatch(
            chatActions.searchChat({ values: { query }, resolve, reject }),
          );
        },
      );
      setDate(data);
    } catch {
      setDate([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNavigate = React.useCallback(
    (
      event: React.KeyboardEvent | React.MouseEvent,
      name: string,
      id: number,
    ) => {
      onClose?.(event, 'backdropClick');
      closeModal();
      navigate(`${book.chat}/${name}/${id}`);
      dispatch(chatActions.clearFoundedChat());
    },
    [onClose, navigate, dispatch],
  );

  useEffect(() => {
    return () => {
      dispatch(chatActions.clearFoundedChat());
    };
  }, []);

  return (
    <Box sx={styles.searchChat}>
      <TextField
        sx={styles.input}
        inputProps={{
          'data-testid': 'send-message-message',
        }}
        placeholder={'Search chats'}
        required
        fullWidth
        value={value}
        onChange={handleChange}
      />
      {isLoading && (
        <Box sx={styles.list}>
          {[...Array(6)].map(() => (
            <Box
              key={uuidv4()}
              display={'flex'}
              flexDirection={'column'}
              gap={'10px'}
              p={'12px 20px'}>
              <Skeleton variant={'rounded'} width={'100%'} height={'24px'} />
              <Skeleton variant={'rounded'} width={'100%'} height={'16px'} />
            </Box>
          ))}
        </Box>
      )}
      {!isLoading && date && (
        <SearchChatList foundedChats={date} handleNavigate={handleNavigate} />
      )}
    </Box>
  );
};

export default SearchChat;
