export const styles = {
  galleryDrawer: {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    gap: '8px',
  },
};
