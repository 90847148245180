import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Sound: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2273 8.22725C17.4469 8.00758 17.8031 8.00758 18.0227 8.22725L17.625 8.625C18.0227 8.22725 18.0229 8.22742 18.0231 8.22759L18.0234 8.22795L18.0243 8.22876L18.0262 8.2307L18.0312 8.23585L18.046 8.25124C18.0578 8.26374 18.0736 8.28076 18.0928 8.30219C18.1311 8.34503 18.1829 8.40567 18.2438 8.4834C18.3652 8.63862 18.5237 8.86358 18.6813 9.15252C18.9964 9.73023 19.3125 10.5725 19.3125 11.625C19.3125 12.6775 18.9964 13.5198 18.6813 14.0975C18.5237 14.3864 18.3652 14.6114 18.2438 14.7666C18.1829 14.8443 18.1311 14.905 18.0928 14.9478C18.0736 14.9692 18.0578 14.9863 18.046 14.9988L18.0312 15.0142L18.0262 15.0193L18.0243 15.0212L18.0234 15.022L18.0231 15.0224C18.0229 15.0226 18.0228 15.0227 17.6405 14.6405L18.0227 15.0227C17.8031 15.2424 17.4469 15.2424 17.2273 15.0227C17.0082 14.8037 17.0076 14.449 17.2253 14.2292C17.226 14.2285 17.2266 14.2279 17.2273 14.2273L17.625 14.625C17.2273 14.2273 17.2271 14.2274 17.2269 14.2276L17.2267 14.2278L17.2262 14.2283L17.2255 14.2291L17.2293 14.2251C17.2339 14.2202 17.2423 14.2112 17.2539 14.1982C17.2772 14.1722 17.3132 14.1303 17.3578 14.0732C17.4473 13.9589 17.57 13.7855 17.6937 13.5588C17.9411 13.1052 18.1875 12.4475 18.1875 11.625C18.1875 10.8025 17.9411 10.1448 17.6937 9.69123C17.57 9.46455 17.4473 9.29106 17.3578 9.17675C17.3132 9.11972 17.2772 9.07782 17.2539 9.05181C17.2423 9.03882 17.2339 9.02983 17.2293 9.02495L17.2253 9.0208C17.0076 8.80098 17.0082 8.44628 17.2273 8.22725Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7342 6.41126C13.7034 6.39501 13.6687 6.38723 13.6337 6.38874C13.5987 6.39025 13.5648 6.401 13.5356 6.41984L11.2082 7.92104L10.5676 6.9705L12.8952 5.46912C13.0999 5.33725 13.3375 5.26187 13.5824 5.25129C13.8272 5.24072 14.0704 5.29518 14.2862 5.40889C14.502 5.52261 14.6822 5.69134 14.8079 5.89719C14.9336 6.10304 15 6.33833 15 6.5781V17.4218C15 17.6616 14.9336 17.897 14.8079 18.1028C14.6822 18.3087 14.502 18.4774 14.2862 18.5911C14.0704 18.7048 13.8272 18.7593 13.5824 18.7487C13.3375 18.7381 13.1002 18.6629 12.8955 18.531L8.07884 15.4969L8.88065 14.5776L13.5356 17.5802C13.5648 17.5989 13.5988 17.6098 13.6337 17.6113C13.6687 17.6128 13.7034 17.605 13.7342 17.5887C13.7651 17.5725 13.7908 17.5484 13.8088 17.519C13.8267 17.4896 13.8362 17.456 13.8362 17.4218V6.57829C13.8362 6.54406 13.8267 6.51039 13.8088 6.48101C13.7908 6.45161 13.7651 6.4275 13.7342 6.41126ZM8.88053 9.4225C8.65886 9.5654 8.39924 9.6416 8.13377 9.64167H5.88362C5.62641 9.64167 5.37973 9.74163 5.19785 9.91957C5.01597 10.0975 4.91379 10.3388 4.91379 10.5905V13.4095C4.91379 13.6612 5.01597 13.9025 5.19785 14.0804C5.37973 14.2584 5.62641 14.3583 5.88362 14.3583H7.00862V15.4969H5.88362C5.31775 15.4969 4.77505 15.277 4.37492 14.8855C3.97479 14.4941 3.75 13.9631 3.75 13.4095V10.5905C3.75 10.0369 3.97479 9.50594 4.37492 9.11448C4.77505 8.72303 5.31775 8.50311 5.88362 8.50311L8.24016 8.4718M8.24016 8.4718L8.88053 9.4225L8.24016 8.4718ZM8.24016 8.4718C8.20853 8.49219 8.27803 8.47177 8.24016 8.4718V8.4718Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7342 17.5887C13.7034 17.605 13.6687 17.6128 13.6337 17.6113C13.5987 17.6097 13.5648 17.599 13.5356 17.5802L11.2082 16.079L10.5676 17.0295L12.8952 18.5309C13.0999 18.6628 13.3375 18.7381 13.5824 18.7487C13.8272 18.7593 14.0704 18.7048 14.2862 18.5911C14.502 18.4774 14.6822 18.3087 14.8079 18.1028C14.9336 17.897 15 17.6617 15 17.4219V6.57819C15 6.33842 14.9336 6.10304 14.8079 5.89719C14.6822 5.69134 14.502 5.52261 14.2862 5.4089C14.0704 5.29518 13.8272 5.24072 13.5824 5.25129C13.3375 5.26187 13.1002 5.33708 12.8955 5.46895L8.24004 8.47188L8.88065 9.42242L13.5356 6.41984C13.5648 6.40107 13.5988 6.39025 13.6337 6.38874C13.6687 6.38723 13.7034 6.39501 13.7342 6.41126C13.7651 6.4275 13.7908 6.45161 13.8088 6.48101C13.8267 6.51039 13.8362 6.54397 13.8362 6.57819V17.4217C13.8362 17.4559 13.8267 17.4896 13.8088 17.519C13.7908 17.5484 13.7651 17.5725 13.7342 17.5887ZM8.88053 14.5775C8.65886 14.4346 8.39924 14.3584 8.13377 14.3583H5.88362C5.62641 14.3583 5.37973 14.2584 5.19785 14.0804C5.01597 13.9025 4.91379 13.6612 4.91379 13.4095V10.5905C4.91379 10.3388 5.01597 10.0975 5.19785 9.91957C5.37973 9.74163 5.62641 9.64167 5.88362 9.64167H7.00862V8.50311H5.88362C5.31775 8.50311 4.77505 8.72303 4.37492 9.11448C3.97479 9.50594 3.75 10.0369 3.75 10.5905V13.4095C3.75 13.9631 3.97479 14.4941 4.37492 14.8855C4.77505 15.277 5.31775 15.4969 5.88362 15.4969H8.13348M8.88053 14.5775L8.24016 15.5282L8.88053 14.5775ZM8.24016 15.5282C8.20853 15.5078 8.17135 15.4969 8.13348 15.4969L8.24016 15.5282Z"
      />
    </SvgIcon>
  );
};

export default Sound;
