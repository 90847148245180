// @ts-nocheck
import { handleError } from '@bus/ui/saga/workers/handleError';
import * as effects from 'redux-saga/effects';
import { projectsActions } from '@bus/projects/actions';
import { wsActions } from '@bus/ws/actions';
import { IResponseHumanMessageData } from '@bus/ws/typedefs';

export function* handleMessageHuman(payload: IResponseHumanMessageData) {
  try {
    yield effects.put(wsActions.setSocketsStatus('MESSAGE'));

    const message = {
      audios: [],
      author: 'human',
      chat: {
        created_at: payload.chat_created_at,
        id: payload.chat_id,
        name: payload.chat_name,
        waiting_user_response: payload.chat_waiting_user_response,
        project: {
          icon_dark: '',
          icon_light: '',
          id: payload.project_id,
          name: payload.project_name,
        },
      },
      created_at: payload.message_created_at,
      files: [],
      fork_chat: null,
      id: payload.message_id,
      images: [],
      message_rate: null,
      project: null,
      saved_at: null,
      text: payload.message_text,
    };

    yield effects.put(projectsActions.addHumanMessage(message));
  } catch (e) {
    yield handleError(e);
  }
}
