import { Theme } from '@mui/material';

export const styles = {
  button: (theme: Theme) => ({
    height: '42px',
    background: theme.palette.common.surface['surface 2'],
    mt: '24px',
    [theme.breakpoints.down(1920)]: {
      mt: '14px',
    },
    [theme.breakpoints.down('lg')]: {
      mt: '34px',
    },
    [theme.breakpoints.down('md')]: {
      mt: '23px',
      height: '39px',
    },
  }),
  radioWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
      gap: '10px',
      overflowX: 'auto',
      flexWrap: 'nowrap',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '& .MuiFormControlLabel-root': {
        margin: 0,
        flexShrink: 0,
      },
    },
  },
  wrapper: {
    mt: '16px',
    display: 'flex',
    gap: '16px',
    transition: 'all 0.3s ease-out',
    overflow: 'hidden',
    flexWrap: 'nowrap',
    height: 0,
    opacity: 0,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& .MuiFormControlLabel-root': {
      margin: 0,
      flexShrink: 0,
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
      gap: '16px',
      flexWrap: 'nowrap',
    },
  },
  open: {
    height: '120px',
    opacity: 1,
    overflow: 'scroll',
  },
  switcher: (theme: Theme) => ({
    m: '-9px',
    '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
      {
        background: theme.palette.text.primary,
      },
    '& .MuiSwitch-track': {
      background: theme.palette.text.disabled,
    },
    '& .MuiButtonBase-root': {
      '& .MuiSwitch-thumb': {
        background: theme.palette.text.primary,
      },
    },
  }),
  uploadPhoto: (theme: Theme) => ({
    width: '120px',
    height: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '16px',
    border: `1px solid ${theme.palette.divider}`,
    position: 'relative',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',
    '& > div': {
      position: 'absolute',
      width: '21px',
      height: '21px',
      borderColor: theme.palette.text.primary,
    },
    '& > div:nth-of-type(1)': {
      left: '20px',
      top: '20px',
      borderTop: `2px solid`,
      borderLeft: `2px solid`,
    },
    '& > div:nth-of-type(2)': {
      right: '20px',
      top: '20px',
      borderTop: `2px solid`,
      borderRight: `2px solid`,
    },
    '& > div:nth-of-type(3)': {
      left: '20px',
      bottom: '20px',
      borderBottom: `2px solid`,
      borderLeft: `2px solid`,
    },
    '& > div:nth-of-type(4)': {
      right: '20px',
      bottom: '20px',
      borderBottom: `2px solid`,
      borderRight: `2px solid`,
    },
    '& > svg': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      opacity: 1,
      transition: 'all 0.3s ease-in-out',
    },
    '& > span': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      opacity: 0,
      transition: 'all 0.3s ease-in-out',
    },
    '&:hover': {
      background: theme.palette.common.action['hover 2'],
      '& > svg': {
        opacity: 0,
      },
      '& > span': {
        opacity: 1,
      },
    },
  }),
};
