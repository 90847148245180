import React, { FC } from 'react';

import { Box, useTheme } from '@mui/material';

import { styles } from './styles';
import { IconButton } from '@components/common/uiKit/IconButton';
import DeleteOutline from '@components/icons/DeleteOutline';
import Share from '@components/pages/private/ImageGalleryPage/Share';
import { useDispatch, useSelector } from 'react-redux';
import useAspectRatio from '@routes/useAspectRatio';
import { IScaleImage } from '@bus/profile/typedefs';
import { profileActions } from '@bus/profile/actions';
import { modalActions } from '@core/Modal/state/actions';
import { getSummary } from '@bus/createdImages/selectors';
import Close from '@components/icons/Close';

type ScaleImageProps = {
  scaleImage: IScaleImage;
};

export const ScaleImage: FC<ScaleImageProps> = ({ scaleImage }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [aspectRatio] = useAspectRatio(scaleImage.image);
  const summary = useSelector(getSummary);

  const closeScale = () => {
    dispatch(profileActions.setScaleImage(null));
  };

  const handleRemoveImage = () => {
    if (scaleImage?.handleDelete) {
      dispatch(
        modalActions.modal({
          component: 'ConfirmOrCancel',
          forceClose: false,
          onCancel: () => {
            scaleImage.handleDelete();
          },
          onConfirm: () => {
            dispatch(modalActions.closeModal('ConfirmOrCancel'));
          },
          modalPayload: {
            title: 'Confirm Action',
            body: 'Are you sure you want to delete this image? You will not be recover it',
          },
          cancelButton: {
            text: 'Delete',
          },
          confirmButton: {
            text: 'Cancel',
          },
        }),
      );
    }
  };

  return (
    <Box sx={styles.scaleImage}>
      <Box sx={styles.wrapper}>
        <Box sx={(theme) => styles.imageWrapper(theme, aspectRatio as number)}>
          <Box component={'img'} src={scaleImage.image} sx={styles.image} />
          {scaleImage.isDelete && (
            <IconButton
              sx={styles.deleteButton}
              onClick={handleRemoveImage}
              color={'primary'}>
              <DeleteOutline
                sx={{ fontSize: '16px' }}
                htmlColor={theme.palette.text.secondary}
              />
            </IconButton>
          )}
        </Box>
        {scaleImage.isShare && (
          <Share
            image={scaleImage.image}
            shortImage={scaleImage.short_image ?? scaleImage.image}
            summary={scaleImage.isSwap ? '' : summary}
            type={scaleImage.type}
            id={scaleImage.id}
          />
        )}
        <IconButton
          sx={styles.closeButton}
          onClick={closeScale}
          color={'primary'}>
          <Close
            fontSize={'small'}
            htmlColor={theme.palette.common.surface['surface 47']}
          />
        </IconButton>
      </Box>
      <Box sx={styles.overlay} onClick={closeScale} />
    </Box>
  );
};

export default ScaleImage;
