import React from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getOptions } from '@bus/options/selectors';
import { useNavigate } from 'react-router-dom';
import { useTokensDisclaimer } from '../../../hooks/useTokensDisclaimer';
import { OnFormSubmitPayload } from '@core/FinalForm/typedefs';
import { book } from '@routes/book';
import { wsActions } from '@bus/ws/actions';
import Close from '@components/icons/Close';
import FinalForm from '@core/FinalForm';
import {
  GenerateImageStudio,
  schema,
} from '@components/forms/GenerateImageStudio';
import { IconButton } from '@components/common/uiKit/IconButton';

type ImageGenerationStudioProps = {
  text: string;
  cancelButton: {
    text: string;
  };
  confirmButton: {
    text: string;
  };
};

export const ImageGenerationStudio: React.FC<
  ModalTypes.ModalComponentProps<ImageGenerationStudioProps>
> = ({ closeFn }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const ratio = useSelector(getOptions('ratio'));
  const navigate = useNavigate();
  const { handle } = useTokensDisclaimer('visualizations');

  const closeModal = () => {
    closeFn();
  };

  const handleSubmit = (payload: OnFormSubmitPayload) => {
    navigate(`${book.imageGallery}/self-generated`);
    handle();
    dispatch(
      wsActions.sendImageRequest({
        ...payload,
        values: {
          action: 'request',
          app: 'image_studio',
          event: 'visualize',
          data: {
            prompt: payload.values.prompt,
            parameters: [payload.values.ratio, ...payload.values.style].filter(
              (item) => item > 0,
            ),
          },
        },
      }),
    );
    closeModal();
  };

  return (
    <Box sx={styles.imageGenerationStudio}>
      <IconButton onClick={closeModal} sx={styles.closeButton}>
        <Close
          fontSize={'small'}
          htmlColor={theme.palette.common.surface['surface 47']}
        />
      </IconButton>
      <Box sx={styles.wrapper}>
        <Typography variant={'body2'} color={'text.primary'}>
          Visual Adjustment
        </Typography>
        <Box>
          <FinalForm
            initialValues={{
              ratio: ratio?.[0]?.id,
              style: [0],
              assistant: [],
            }}
            onSubmit={handleSubmit}
            component={GenerateImageStudio}
            schema={schema}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ImageGenerationStudio;
