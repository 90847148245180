import { Theme } from '@mui/material';

export const styles = {
  input: (theme: Theme) => ({
    '& .MuiInputBase-root': {
      border: 'none',
      padding: 0,
      background: theme.palette.common.surface['surface 5'],
      '& textarea': {
        '&::-webkit-input-placeholder': {
          color: theme.palette.text.disabled,
        },
        '&:-moz-placeholder': {
          /* Firefox 18- */
          color: theme.palette.text.disabled,
        },
        '&::-moz-placeholder': {
          /* Firefox 19+ */
          color: theme.palette.text.disabled,
        },
        '&:-ms-input-placeholder': {
          color: theme.palette.text.disabled,
        },
      },
      '&.Mui-focused': {
        border: 'none',
      },
    },
  }),
  button: (theme: Theme) => ({
    height: '42px',
    background: theme.palette.common.surface['surface 2'],
    mt: '24px',
    [theme.breakpoints.down(1920)]: {
      mt: '14px',
    },
    [theme.breakpoints.down('lg')]: {
      mt: '34px',
    },
    [theme.breakpoints.down('md')]: {
      mt: '23px',
      height: '39px',
    },
  }),
  radioGroup: {
    pb: '10px',
    mb: '10px',
    '& .MuiFormControl-root': {
      width: '100%',
      '& .MuiFormGroup-root': {
        flexDirection: 'row',
        gap: '10px',
        '& .MuiFormControlLabel-root': {
          width: 'initial',
          m: 0,
        },
      },
    },
  },
  radioGroupWithBorder: (theme: Theme) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
  }),
  loader: (theme: Theme) => ({
    color: theme.palette.common.surface['surface 5'],
  }),
};
