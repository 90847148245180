import React, { ForwardRefRenderFunction, forwardRef, useEffect } from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import { SnackbarContent, CustomContentProps, useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { chatActions } from '@bus/chat/actions';
import Info from '@components/icons/Info';

interface InfoSnackbarProps extends CustomContentProps {}

const InfoSnackbar: ForwardRefRenderFunction<
  HTMLDivElement,
  InfoSnackbarProps
> = ({ message, id, action, autoHideDuration }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      closeSnackbar(id);
      dispatch(chatActions.setLoadingImageId(null));
    }, autoHideDuration ?? 3000);
  }, [id]);

  return (
    <SnackbarContent ref={ref} style={{ maxWidth: '100%', width: '100%' }}>
      <Box sx={styles.infoSnackbar}>
        <Box display={'flex'} alignItems={'center'} gap={'10px'}>
          <Info
            fontSize={'small'}
            htmlColor={theme.palette.common.surface['surface 1']}
          />
          <Typography variant={'body1'} color={'common.surface.surface 3'}>
            {message}
          </Typography>
        </Box>
        {typeof action === 'function' ? action(id) : action}
      </Box>
    </SnackbarContent>
  );
};

export default forwardRef<HTMLDivElement, InfoSnackbarProps>(InfoSnackbar);
