export const styles = {
  drawerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: '20px',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '11px',
  },
};
