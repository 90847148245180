import * as React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Field, FormRenderProps } from 'react-final-form';

import { Box, Button, Typography } from '@mui/material';

import { RenderPromptField } from '@components/forms/GenerateImageStudio/fields/RenderPromptField';
import { RenderStyleField } from '@components/forms/GenerateImageStudio/fields/RenderStyleField';
import { RenderAssistantField } from '@components/forms/GenerateImageStudio/fields/RenderAssistantField';
import { RenderRatioField } from '@components/forms/GenerateImageStudio/fields/RenderRatioField';
// RENDER_FIELDS
import { schema } from './schema';
import { styles } from './styles';

const GenerateImageStudio = (props: FormRenderProps) => {
  return (
    <form
      noValidate
      onSubmit={(event) => {
        props.handleSubmit(event)?.then(() => {
          props.form.restart();
        });
      }}>
      <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
        <Field name={`prompt`} component={RenderPromptField} />
        <Field name={`assistant`} component={RenderAssistantField} />
        <Field name={`style`} component={RenderStyleField} />
        <Field name={`ratio`} component={RenderRatioField} />
        {/*FIELDS*/}
        <Button
          fullWidth
          type={'submit'}
          role={'submit'}
          variant={'primary'}
          sx={styles.button}>
          <Typography variant={'body1'}>Generate image</Typography>
        </Button>
      </Box>
    </form>
  );
};

export default GenerateImageStudio;

export { schema, GenerateImageStudio };
