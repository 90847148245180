import * as React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Field, FormRenderProps } from 'react-final-form';

import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { RenderRatioField } from '@components/forms/StorytellerForm/fields/RenderRatioField';
import { RenderStyleField } from '@components/forms/StorytellerForm/fields/RenderStyleField';

// RENDER_FIELDS
import { schema } from './schema';
import { styles } from './styles';
import { useSelector } from 'react-redux';
import { getIsStoryUploadFetching } from '@bus/storyTeller/selectors';

const StorytellerForm = (props: FormRenderProps) => {
  const isStoryUploading = useSelector(getIsStoryUploadFetching);

  return (
    <form noValidate onSubmit={props.handleSubmit}>
      <Box width={'100%'} display={'flex'} flexDirection={'column'}>
        <Field name={`style`} component={RenderStyleField} />
        <Field name={`ratio`} component={RenderRatioField} />
        {/*FIELDS*/}
        <Button
          fullWidth
          type={'submit'}
          role={'submit'}
          variant={'primary'}
          disabled={isStoryUploading}
          sx={styles.button}
          endIcon={
            isStoryUploading && (
              <CircularProgress thickness={2} size={16} sx={styles.loader} />
            )
          }>
          <Typography variant={'body1'}>Generate Story</Typography>
        </Button>
      </Box>
    </form>
  );
};

export default StorytellerForm;

export { schema, StorytellerForm };

/*
Paste to the component where you want to use the form

import { EvneFinalForm } from '@packages/evne-form';
import { schema, StorytellerForm } from '@components/forms/StorytellerForm';

<EvneFinalForm
  component={StorytellerForm}
  schema={schema}
/>
* */
