import * as React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Field, FormRenderProps } from 'react-final-form';

import { Box, Button, Typography } from '@mui/material';

import { RenderFromPhotoField } from '@components/forms/GenerateImageWrapStudio/fields/RenderFromPhotoField';
import { RenderWrapFaceField } from '@components/forms/GenerateImageWrapStudio/fields/RenderWrapFaceField';
import { RenderFaceField } from '@components/forms/GenerateImageWrapStudio/fields/RenderFaceField';
// RENDER_FIELDS
import { schema } from './schema';
import { styles } from './styles';
import { Grid } from '@components/common/Grid';
import { getFetchingUpdateImage } from '@bus/createdImages/selectors';
import { useSelector } from 'react-redux';

const GenerateImageWrapStudio = (props: FormRenderProps) => {
  const isFetchingUpdateImage = useSelector(getFetchingUpdateImage);

  return (
    <form
      noValidate
      onSubmit={(event) => {
        props.handleSubmit(event)?.then(() => {
          props.form.restart();
        });
      }}>
      <Box sx={styles.container}>
        <Grid container columnSpacing={'20px'} rowSpacing={'10px'} mb={'20px'}>
          <Grid item md={6} xs={12}>
            <Field name={`from_photo`} component={RenderFromPhotoField} />
          </Grid>
          <Grid item md={6} xs={12}>
            <RenderFaceField />
          </Grid>
        </Grid>
        <Field name={`wrap_face`} component={RenderWrapFaceField} />
        <Button
          fullWidth
          type={'submit'}
          role={'submit'}
          variant={'primary'}
          sx={styles.button}
          disabled={isFetchingUpdateImage}>
          <Typography variant={'body1'}>Face Swap</Typography>
        </Button>
      </Box>
    </form>
  );
};

export default GenerateImageWrapStudio;

export { schema, GenerateImageWrapStudio };
