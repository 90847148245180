import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const DatasetGpt: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0437 22.3647C17.951 22.3647 22.7398 17.5759 22.7398 11.6685C22.7398 5.76122 17.951 0.972379 12.0437 0.972379C6.13634 0.972379 1.3475 5.76122 1.3475 11.6685C1.3475 17.5759 6.13634 22.3647 12.0437 22.3647ZM12.0437 23.3371C18.488 23.3371 23.7122 18.1129 23.7122 11.6685C23.7122 5.22419 18.488 0 12.0437 0C5.59931 0 0.375122 5.22419 0.375122 11.6685C0.375122 18.1129 5.59931 23.3371 12.0437 23.3371Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0436 7.88955L10.3431 8.72311L8.77092 9.77904L8.64253 11.6685L8.77092 13.558L10.3431 14.614L12.0436 15.4475L13.7441 14.614L15.3163 13.558L15.4447 11.6685L15.3163 9.77904L13.7442 8.72311L12.0436 7.88955ZM14.2315 7.87906L12.0436 6.80664L9.85576 7.87906L7.83309 9.23759L7.66791 11.6685L7.83309 14.0995L9.85576 15.458L12.0436 16.5304L14.2315 15.458L16.2541 14.0995L16.4193 11.6685L16.2541 9.23759L14.2315 7.87906Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.66792 11.6685L7.66793 5.78245C7.66793 4.26437 8.05561 3.12685 8.69647 2.29902C9.33562 1.47341 10.1927 0.99957 11.0558 0.746022C11.9156 0.493416 12.7969 0.454193 13.5218 0.506127C14.2324 0.557039 14.8492 0.700018 15.1718 0.855401L16.9055 1.94473C14.9608 1.45854 14.1529 1.52622 13.4523 1.47602C12.8097 1.42998 12.0501 1.46738 11.3298 1.67897C10.6128 1.88962 9.95054 2.26755 9.46537 2.89426C8.98192 3.51876 8.64031 4.43411 8.64031 5.78245L8.64031 9.72376L7.66792 11.6685Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4586 8.73133L19.2508 10.9208C20.6887 11.7509 21.5845 12.6775 22.0743 13.6389C22.5663 14.6046 22.6285 15.5659 22.4665 16.4233C22.3058 17.2738 21.9258 18.0204 21.5315 18.5859C21.1463 19.1384 20.7119 19.5683 20.3991 19.7498L19.9109 18.9089C20.05 18.8281 20.3876 18.5265 20.7339 18.0298C21.0711 17.546 21.382 16.9256 21.511 16.2428C21.6387 15.5671 21.5893 14.8289 21.2079 14.0803C20.8243 13.3275 20.0847 12.525 18.7646 11.7629L14.9724 9.57343L15.4586 8.73133Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7712 6.98753L15.2525 4.97761C16.5909 4.20489 17.7882 3.9642 18.8399 4.10148C19.8894 4.23847 20.7396 4.74472 21.399 5.36952C22.0558 5.99183 22.535 6.74172 22.8547 7.40149C23.1682 8.04831 23.3529 8.66003 23.3784 9.02087L22.4084 9.08923C22.3955 8.90597 22.2693 8.42314 21.9797 7.82556C21.6964 7.24094 21.2803 6.59658 20.7302 6.07538C20.1828 5.55667 19.5143 5.17014 18.714 5.06568C17.916 4.9615 16.9322 5.13067 15.7387 5.81971L12.2574 7.82964L11.7712 6.98753Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4194 11.6685V17.7047C16.4194 19.2734 16.0338 20.4474 15.3961 21.3025C14.759 22.1568 13.9024 22.6496 13.0363 22.9137C12.1742 23.1766 11.2907 23.2174 10.5642 23.1634C9.85225 23.1105 9.23337 22.9617 8.90906 22.7995L8.29999 21.8371C8.46186 21.918 9.98085 22.145 10.6363 22.1937C11.2772 22.2414 12.0346 22.2026 12.7526 21.9837C13.4666 21.7659 14.1294 21.3745 14.6166 20.7212C15.1033 20.0686 15.447 19.1116 15.447 17.7047V13.6133L16.4194 11.6685Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.23091 14.347L4.62356 12.2643C3.24911 11.4708 2.39938 10.5684 1.94452 9.62239C1.48835 8.67363 1.45034 7.72443 1.6237 6.8781C1.79581 6.03786 2.1762 5.29529 2.56648 4.73048C2.94801 4.17834 3.37377 3.74669 3.67698 3.56192L4.18299 4.39226C4.0453 4.47618 3.71181 4.78347 3.36645 5.28327C3.02983 5.77041 2.71568 6.39278 2.5763 7.07323C2.43816 7.7476 2.47171 8.47484 2.82087 9.20104C3.17132 9.92993 3.86067 10.701 5.10975 11.4222L8.7171 13.5049L8.23091 14.347Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8952 16.0981L9.16266 18.2531C7.73892 19.0751 6.48192 19.3538 5.38736 19.2472C4.29176 19.1405 3.41011 18.6527 2.72969 18.0324C2.05312 17.4156 1.56871 16.6627 1.25047 15.9974C0.938708 15.3456 0.76136 14.7244 0.745478 14.3541L1.71696 14.3125C1.72481 14.4955 1.84172 14.98 2.12766 15.5778C2.40713 16.162 2.82385 16.8024 3.38479 17.3138C3.94187 17.8216 4.63409 18.1969 5.48162 18.2794C6.33019 18.3621 7.38561 18.1562 8.67647 17.411L12.409 15.2559L12.8952 16.0981Z"
      />
    </SvgIcon>
  );
};

export default DatasetGpt;
