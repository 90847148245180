// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// eslint-disable-next-line prettier/prettier
import {
  GuidanceState,
  FillGuidanceActionPayload,
  PushMoreGuidanceActionPayload,
} from './typedefs';

const initialState: GuidanceState = {
  isFetching: false,
  guidance: null,
};

const guidanceSlice = createSlice({
  name: 'guidance',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    fillGuidance(state, action: PayloadAction<FillGuidanceActionPayload>) {
      state.guidance = action.payload;
    },
    // INJECT
  },
});

export default guidanceSlice;
