import React from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getCreatedSwap, getUploadedFaces } from '@bus/createdImages/selectors';
import { useNavigate } from 'react-router-dom';
import { OnFormSubmitPayload } from '@core/FinalForm/typedefs';
import { book } from '@routes/book';
import { wsActions } from '@bus/ws/actions';
import Close from '@components/icons/Close';
import FinalForm from '@core/FinalForm';
import {
  GenerateImageWrapStudio,
  schema,
} from '@components/forms/GenerateImageWrapStudio';
import { IconButton } from '@components/common/uiKit/IconButton';

type ImageWrapStudioProps = {
  text: string;
  cancelButton: {
    text: string;
  };
  confirmButton: {
    text: string;
  };
};

export const ImageWrapStudio: React.FC<
  ModalTypes.ModalComponentProps<ImageWrapStudioProps>
> = ({ closeFn }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const createdSwap = useSelector(getCreatedSwap);
  const navigate = useNavigate();
  const uploadedFaces = useSelector(getUploadedFaces);
  const closeModal = () => {
    closeFn();
  };

  const handleSubmit = (payload: OnFormSubmitPayload) => {
    navigate(`${book.imageGallery}/face-swap`);
    dispatch(
      wsActions.sendImageRequest({
        ...payload,
        values: {
          action: 'request',
          app: 'image_studio',
          event: 'face_swap',
          data: {
            face_swap_id: createdSwap?.id,
          },
        },
      }),
    );
    closeModal();
  };

  return (
    <Box sx={styles.imageWrapStudio}>
      <IconButton onClick={closeModal} sx={styles.closeButton}>
        <Close
          fontSize={'small'}
          htmlColor={theme.palette.common.surface['surface 47']}
        />
      </IconButton>
      <Box sx={styles.wrapper}>
        <Typography variant={'body2'} color={'text.primary'}>
          Face Swap
        </Typography>
        <Box>
          <FinalForm
            initialValues={{
              from_photo: createdSwap?.target_image ?? '',
              wrap_face: uploadedFaces[0]?.image ?? '',
            }}
            onSubmit={handleSubmit}
            component={GenerateImageWrapStudio}
            schema={schema}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ImageWrapStudio;
