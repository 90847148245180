import { Theme } from '@mui/material/styles';

export const styles = {
  masterMenu: (theme: Theme) => ({
    display: 'flex',
    gap: '22px',
    background: theme.palette.common.surface['surface 5'],
    padding: '20px 20px 32px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&': {
      [theme.breakpoints.down(1920)]: {
        flexDirection: 'column',
      },
    },
  }),
  datasetTitle: (theme: Theme) => ({
    mb: '20px',
    [theme.breakpoints.down('md')]: {
      mb: '16px',
    },
  }),
  dataSetWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  projectsWrapper: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    width: '100%',
    [theme.breakpoints.down(1920)]: {
      flexDirection: 'column',
      gap: '12px',
    },
  }),
  popularProjectsWrapper: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    width: '100%',
    height: '100%',
    flex: '1 1 calc(50% - 12px)',
    maxWidth: 'calc(50% - 12px)',
    [theme.breakpoints.down(1920)]: {
      flexDirection: 'row',
      flex: '1 1 100%',
      maxWidth: '100%',
      gap: '12px',
    },
    '&': {
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
  }),
  notPopularProjectsWrapper: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    width: '100%',
    height: '100%',
    flex: '1 1 calc(50% - 12px)',
    maxWidth: 'calc(50% - 12px)',
    [theme.breakpoints.down(1920)]: {
      flexDirection: 'row',
      flex: '1 1 100%',
      maxWidth: '100%',
      gap: '12px',
    },
    '&': {
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
  }),
  imageGeneration: (theme: Theme) => ({
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }),
  imageGenerationMobile: (theme: Theme) => ({
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      height: '44px',
      flex: '1 1 calc(50% - 16px / 2)',
      maxWidth: 'calc(50% - 16px / 2)',
      '& > div': {
        flex: '1 1 100%',
        maxWidth: '100%',
      },
    },
  }),
};
