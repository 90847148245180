import React from 'react';

import { Box, Button, Typography, useTheme } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import InfoCircleLinear from '@components/icons/InfoCircleLinear';

type ConfirmOrCancelProps = {
  cancelButton: {
    text: string;
  };
  confirmButton: {
    text: string;
  };
};

export const ConfirmOrCancel: React.FC<
  ModalTypes.ModalComponentProps<ConfirmOrCancelProps>
> = ({
  closeFn,
  onCancel,
  onConfirm,
  cancelButton,
  confirmButton,
  modalPayload,
}) => {
  const theme = useTheme();
  const cancelModal = () => {
    onCancel();
    closeFn();
  };

  const confirmModal = () => {
    onConfirm();
    closeFn();
  };

  return (
    <Box sx={styles.root}>
      <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
        <Box display={'flex'} alignItems={'center'} gap={'20px'}>
          <InfoCircleLinear
            fontSize={'medium'}
            htmlColor={theme.palette.common.surface['surface 46']}
          />
          <Typography variant={'h5'} color={'text.primary'}>
            {modalPayload?.title
              ? modalPayload.title
              : 'Some information is not saved!'}
          </Typography>
        </Box>
        <Typography variant={'body1'} color={'text.primary'}>
          {modalPayload?.body
            ? modalPayload.body
            : 'You must save your changes before leaving the page.'}
        </Typography>
      </Box>
      <Box display={'flex'} gap={'8px'} width={'100%'}>
        {cancelButton && (
          <Button
            onClick={cancelModal}
            variant={'primary'}
            color={'third'}
            fullWidth>
            <Typography variant={'body1'}>
              {cancelButton?.text || 'Cancel'}
            </Typography>
          </Button>
        )}
        <Button
          onClick={confirmModal}
          variant={'primary'}
          color={'sixth'}
          fullWidth>
          <Typography variant={'body1'}>
            {confirmButton?.text || 'Ok'}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
