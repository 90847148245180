import { Theme } from '@mui/material';

export const styles = {
  searchChatList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
    overflowY: 'auto',
    mt: '30px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  item: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 20px',
    borderRadius: '16px',
    width: '100%',
    transition: 'all 0.3s ease-in-out',
    cursor: 'pointer',
    gap: '16px',
    '& > .MuiTypography-root': {
      transition: 'all 0.3s ease-in-out',
      opacity: 0,
    },
    '&:hover': {
      background: theme.palette.common.surface['surface 50'],
      '& > .MuiTypography-root': {
        opacity: 1,
      },
    },
  }),
};
