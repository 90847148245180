import React, { FC } from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { styles } from './styles';

type DatasetItemProps = {
  name: string;
  description: string;
  icon: any;
  action: () => void;
};

export const DatasetItem: FC<DatasetItemProps> = ({
  name,
  description,
  icon,
  action,
}) => {
  const theme = useTheme();

  const handleCardClick = () => {
    action();
  };

  return (
    <Box
      sx={styles.datasetItem}
      onClick={handleCardClick}
      data-intercom-target={name}>
      <Box display={'flex'} alignItems={'center'} gap={'8px'} flexShrink={0}>
        {React.createElement(icon, {
          sx: { fontSize: '20px' },
          htmlColor: theme.palette.common.surface['surface 46'],
        })}
        <Typography variant={'captionBold'} color={'common.brand'}>
          {name}
        </Typography>
      </Box>
      <Typography
        variant={'caption'}
        sx={styles.description}
        color={'text.secondary'}>
        {description}
      </Typography>
    </Box>
  );
};

export default DatasetItem;
