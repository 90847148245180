import React, { FC, useEffect, useState } from 'react';

import { Box, Button, Typography, useTheme } from '@mui/material';

import { styles } from './styles';
import ChatBubbleOutline from '@components/icons/ChatBubbleOutline';
import { ISession, ISessionMonth } from '@bus/aiConversation/typedefs';
import { useParams } from 'react-router-dom';
import MonthSession from '@components/pages/common/ConversationPage/MonthSession';

type AIHistoryProps = {
  list: ISession[];
  handleClick: (
    sessionId: number | string,
    month: string,
    year: number,
    event: React.KeyboardEvent | React.MouseEvent,
  ) => void;
};

export const AIHistory: FC<AIHistoryProps> = ({ list, handleClick }) => {
  const theme = useTheme();
  const { year } = useParams();
  const [selectedMonths, setSelectedMonths] = useState<ISessionMonth[]>([]);
  const [yearId, setYearId] = useState(0);

  useEffect(() => {
    if (list.length) {
      const currentYear = list.find((item) => item.id.toString() === year);
      setYearId(currentYear?.id ?? 0);
      setSelectedMonths(currentYear?.months ?? []);
    }
  }, [list.length]);

  const handleSelectYear = (
    event: React.MouseEvent<HTMLButtonElement>,
    months: ISessionMonth[],
    id: number,
  ) => {
    event.stopPropagation();
    setSelectedMonths(months);
    setYearId(id);
  };

  return (
    <Box sx={styles.root}>
      {!!list.length && (
        <Box display={'flex'} alignItems={'center'} gap={'20px'}>
          {list.map((item) => (
            <Button
              key={item.id}
              sx={[styles.button, yearId === item.id && styles.active]}
              variant={'secondary'}
              color={'secondary'}
              onClick={(e) => handleSelectYear(e, item.months, item.id)}>
              <Typography variant={'body1'} color={'text.primary'}>
                {item.id}
              </Typography>
            </Button>
          ))}
        </Box>
      )}

      {list.length ? (
        <Box sx={styles.list}>
          {selectedMonths.map((item) => (
            <MonthSession
              key={item.id}
              yearId={yearId}
              sessionsMont={item}
              handleClick={handleClick}
            />
          ))}
        </Box>
      ) : (
        <Box pb={'16px'} display={'flex'} alignItems={'center'} pl={'16px'}>
          <ChatBubbleOutline
            fontSize={'small'}
            htmlColor={theme.palette.text.primary}
          />
          <Typography
            variant={'body1'}
            color={'text.secondary'}
            ml={'8px'}
            textAlign={'center'}>
            Empty
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default AIHistory;
