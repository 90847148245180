import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Download: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49999 8.99782C8.50227 9.41202 8.16835 9.74966 7.75414 9.75194C6.66076 9.75797 5.88565 9.78648 5.29746 9.8946C4.73064 9.99878 4.40261 10.166 4.15933 10.4093C3.88235 10.6863 3.70186 11.0749 3.60318 11.8086C3.5016 12.5638 3.5 13.5647 3.5 15V16C3.5 17.4357 3.5016 18.4366 3.60318 19.1918C3.70184 19.9254 3.88232 20.3143 4.15963 20.5919C4.43591 20.868 4.82434 21.0483 5.5581 21.1469C6.31356 21.2484 7.31482 21.25 8.75 21.25H16.75C18.1852 21.25 19.1864 21.2484 19.9419 21.1469C20.6759 21.0483 21.0644 20.8679 21.3407 20.5916C21.6176 20.3147 21.7981 19.926 21.8968 19.1922C21.9984 18.4368 22 17.4357 22 16V15C22 13.5647 21.9984 12.5638 21.8968 11.8086C21.7981 11.0749 21.6177 10.6863 21.3407 10.4093C21.0974 10.166 20.7694 9.99878 20.2025 9.8946C19.6144 9.78648 18.8392 9.75797 17.7459 9.75194C17.3317 9.74966 16.9977 9.41202 17 8.99782C17.0023 8.58361 17.3399 8.24968 17.7541 8.25196C18.8358 8.25793 19.7372 8.28392 20.4737 8.41931C21.2316 8.55862 21.8766 8.82391 22.4013 9.34862C23.0034 9.95064 23.2624 10.7085 23.3834 11.6086C23.5 12.4755 23.5 13.5777 23.5 14.9451V16.0549C23.5 17.4227 23.5 18.5251 23.3834 19.3922C23.2624 20.2924 23.0034 21.0503 22.4013 21.6523C21.7996 22.254 21.0416 22.5126 20.1416 22.6335C19.2746 22.75 18.1722 22.75 16.8048 22.75H8.69519C7.32776 22.75 6.22538 22.75 5.3584 22.6335C4.45843 22.5126 3.70038 22.254 3.09867 21.6523C2.49698 21.0499 2.23766 20.2921 2.11657 19.3918C1.99996 18.5249 1.99998 17.4227 2 16.0549V14.9451C1.99998 13.5777 1.99996 12.4755 2.11657 11.6086C2.23764 10.7085 2.49665 9.95064 3.09867 9.34862C3.62339 8.82391 4.26836 8.55862 5.02629 8.41931C5.76285 8.28392 6.66424 8.25793 7.74586 8.25196C8.16007 8.24968 8.4977 8.58361 8.49999 8.99782Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 1.25C13.1642 1.25 13.5 1.58579 13.5 2V12.9726L15.1806 11.0119C15.4501 10.6974 15.9236 10.661 16.2381 10.9306C16.5526 11.2001 16.589 11.6736 16.3195 11.9881L13.3195 15.4881C13.177 15.6543 12.969 15.75 12.75 15.75C12.5311 15.75 12.3231 15.6543 12.1806 15.4881L9.18057 11.9881C8.911 11.6736 8.94743 11.2001 9.26192 10.9306C9.57641 10.661 10.0499 10.6974 10.3195 11.0119L12 12.9726V2C12 1.58579 12.3358 1.25 12.75 1.25Z"
      />
    </SvgIcon>
  );
};

export default Download;
