import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const Logo: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox={'0 0 48 48'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.6624 28.6819C10.4494 30.3874 15.1562 33.0421 20.3931 36.9393C26.1746 41.2418 31.3668 41.7539 35.7046 40.6943C40.4044 39.3044 41.6203 37.6383 44.0718 35.4592L44.6983 36.1641L44.4007 36.6426C42.1113 38.7289 40.345 40.6926 35.9655 41.7624C31.3015 42.9016 25.7743 42.3144 19.7367 37.8213C14.56 33.9689 9.92159 31.3554 6.21094 29.6844C3.78528 28.592 1.77175 27.9093 0.271671 27.5422L0.123535 26.3789C1.76942 26.7433 3.99742 27.4817 6.6624 28.6819Z"
        fill="#ECC199"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.64598 34.7857C9.22612 36.5666 13.7048 39.0474 18.9684 42.1285C24.5283 45.3831 30.2109 45.9394 35 45.2957L32.0939 46.6253C27.8158 46.7207 23.0748 45.8062 18.4129 43.0773C13.1585 40.0016 8.7042 37.5349 5.15633 35.7701C4.15756 35.2733 3.2352 34.8344 2.39044 34.4543L1.73291 32.9673C2.89338 33.4574 4.20126 34.0671 5.64598 34.7857Z"
        fill="#ECC199"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.409 47.3358C14.563 44.8241 10.9648 43.0139 7.84265 41.7373C11.5 45.5 18.409 47.3358 18.409 47.3358Z"
        fill="#ECC199"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7484 28.6912C28.8972 30.3874 24.7493 32.902 22.2935 34.5392L21.6836 33.6244C24.1753 31.9633 28.4105 29.3929 33.3855 27.6534C37.8348 26.0977 42.9204 25.191 47.8947 26.2356L47.7542 27.3298C43.0222 26.3135 38.1221 27.1619 33.7484 28.6912Z"
        fill="#ECC199"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.5987 32.1069C36.8826 30.5263 28.6576 36.153 23.7892 39.4835L23.1685 38.576L23.2557 38.5164C28.1147 35.1919 36.7247 29.3013 46.954 31.0515L46.5987 32.1069Z"
        fill="#ECC199"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.621 36.2838C38.5724 36.3677 33.0267 38.9694 28.6094 41.6719L28.0356 40.734C32.6519 37.9098 38.6409 35.0911 45.2383 35.1839L44.621 36.2838Z"
        fill="#ECC199"
      />
      <path
        d="M16.2168 26.9358C15.9969 27.8153 14.476 30.9671 13.743 32.433L24.7374 40.1291C24.6458 39.3961 24.2976 37.4354 23.638 35.4564C22.8134 32.9827 22.5385 29.9592 23.638 27.7604C24.7374 25.5615 29.9597 25.5615 34.3575 25.0118C38.7552 24.4621 41.5038 17.3157 41.7787 16.4911C41.9986 15.8315 41.5038 16.033 41.229 16.2163C40.8625 16.5827 39.8547 17.3157 38.7552 17.3157C37.3809 17.3157 37.3809 17.0408 35.7318 17.3157C34.0826 17.5906 34.3575 17.5906 32.7083 17.8654C31.0592 18.1403 29.9597 17.5906 27.7609 17.5906C25.562 17.5906 25.2871 18.9649 23.0882 21.4386C21.3291 23.4176 21.0726 21.8967 21.1642 20.8889C21.0726 20.7973 20.9443 20.1193 21.1642 18.1403C21.4391 15.6665 18.4156 15.9414 16.2168 14.2923C14.0179 12.6431 13.743 12.6431 12.6436 12.6431C11.5441 12.6431 10.4447 12.3682 10.1698 10.9939C9.94996 9.8945 9.52851 10.3526 9.34527 10.7191C7.42125 14.5671 8.24583 16.4911 8.52069 17.8654C8.79555 19.2397 14.0179 23.0878 15.3922 23.9123C16.7665 24.7369 16.4916 25.8364 16.2168 26.9358Z"
        fill="url(#paint0_radial_3185_22143)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.2127 26.5921C1.13466 25.8217 1.09456 25.0405 1.09456 24.2492C1.09456 11.5992 11.3495 1.34425 23.9995 1.34425C36.6495 1.34425 46.9045 11.5992 46.9045 24.2492C46.9045 25.0405 46.8644 25.8217 46.7864 26.5921L47.8802 26.7029C47.9619 25.8959 48.0039 25.0777 48.0039 24.2492C48.0039 10.992 37.2567 0.244814 23.9995 0.244814C10.7423 0.244814 -0.00488281 10.992 -0.00488281 24.2492C-0.00488281 25.0777 0.0371075 25.8959 0.118855 26.7029L1.5 27L1.2127 26.5921Z"
        fill="#ECC199"
      />
      <defs>
        <radialGradient
          id="paint0_radial_3185_22143"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.6436 28.0352) rotate(47.7702) scale(24.1282 27.284)">
          <stop stopColor="#846D58" />
          <stop offset="0.607324" stopColor="#CFAA88" />
          <stop offset="1" stopColor="#FEFDFD" />
        </radialGradient>
      </defs>
    </SvgIcon>
  );
};

export default Logo;
