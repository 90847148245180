import { getValidToken, removeQuotes } from '@REST/api';
import { handleErrors } from '@helpers/handleErrors';
import { createdImagesActions } from '@bus/createdImages/actions';

export const updateSwapImageImage = async (
  dispatch: any,
  files: File | string,
  id: number,
  name: string,
) => {
  if (files && id) {
    const tokenAccess = await getValidToken();
    const formData = new FormData();
    if (files instanceof File) {
      formData.append(name, files, files.name);
    }
    if (files === 'remove') {
      formData.append(`${name}_url`, '');
    }
    if (typeof files === 'string' && files !== 'remove') {
      formData.append(`${name}_url`, files);
    }
    try {
      dispatch(createdImagesActions.startFetchingUpdateImage());
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/image-studio/face-swaps/${id}/`,
        {
          method: 'PATCH',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + removeQuotes(tokenAccess!),
          },
        },
      );

      if (response.ok) {
        const data = await response.json();

        dispatch(createdImagesActions.changeSwap(data));

        return data;
      }
    } catch (error) {
      handleErrors(error);
    } finally {
      dispatch(createdImagesActions.stopFetchingUpdateImage());
    }
  }
};
