import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { projectsActions } from '../../actions';
import { FetchProjectsActionPayload } from '../../typedefs';
import { createdImagesActions } from '@bus/createdImages/actions';
import { datasetIcons } from '@helpers/datasetIcons';
import { savedMessagesActions } from '@bus/savedMessages/actions';

export function* fetchProjects() {
  try {
    yield effects.put(projectsActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: 'projects/' },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: FetchProjectsActionPayload = yield effects.call([
      response,
      'json',
    ]);

    const newData = data.map((project) => {
      if (datasetIcons[project.id]) {
        return {
          id: project.id,
          name: project.name,
          icon_dark: project.icon_dark,
          icon_light: project.icon_light,
          icon: datasetIcons[project.id],
        };
      }

      return {
        id: project.id,
        name: project.name,
        icon_dark: project.icon_dark,
        icon_light: project.icon_light,
        icon: null,
      };
    });

    const newProjects = data.map((project) => {
      if (datasetIcons[project.id]) {
        return {
          ...project,
          icon: datasetIcons[project.id],
        };
      }

      return {
        ...project,
        icon: null,
      };
    });

    const savedFilters = data.map((project) => ({
      id: project.id,
      name: project.name,
    }));

    yield effects.put(projectsActions.fillProjects(newProjects));
    yield effects.put(createdImagesActions.fillFilters(newData));
    yield effects.put(savedMessagesActions.fillSavedFilters(savedFilters));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(projectsActions.stopFetching());
  }
}
